import React from "react";
import ComposerHeader from "./ComposerHeader";
import ComposerForm from "./ComposerForm";

export default function Composer() {
  return (
    <div
      className="bg-white rounded-lg shadow-lg position-fixed bottom-0 p-0"
      style={{
        right: "10px",
        width: "700px",
      }}
    >
      <ComposerHeader />
      <div className="p-1">
        <ComposerForm />
      </div>
    </div>
  );
}
