import React from "react";
import { Form, Button } from "react-bootstrap";

function ConfirmationForm({ agree, setAgree, handleConfirm }) {
  return (
    <div className="mt-4">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleConfirm();
        }}
      >
        <Form.Group controlId="formBasicCheckbox">
          <div className="d-flex align-items-center gap-1">
            <input
              type="checkbox"
              id="agree"
              name="agree"
              checked={agree}
              onChange={(e) => setAgree(e.target.checked)}
              className="form-check-input p-0"
            />
            <label htmlFor="agree" className="form-check-label mb-0">
              Ik ga akkoord met de algemene voorwaarden
            </label>
          </div>
        </Form.Group>
        <div className="mt-3 d-flex justify-content-end">
          <Button
            type="submit"
            className={`font-weight-semibold ${
              agree ? "btn-success" : "btn-secondary"
            }`}
            disabled={!agree}
          >
            Bevestigen
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default ConfirmationForm;
