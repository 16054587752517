import React, { useContext } from "react";
import { StoreContext } from "../../../../../../../../context/StoreContext.jsx";

export default function Orders({ checkedEntities, handleCheckboxChange }) {
  const { orders } = useContext(StoreContext);

  return (
    <div
      className="tab-pane fade show active"
      id="orders"
      role="tabpanel"
      aria-labelledby="orders-tab"
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <p className="card-text">
                <ul className="list-group">
                  {orders.map((order) => (
                    <li
                      style={{
                        marginBottom: "5px",
                      }}
                      className="border p-1 rounded d-flex gap-1 align-items-center"
                    >
                      <input
                        type="checkbox"
                        id={order.id}
                        className="form-check-input"
                        checked={checkedEntities.orders.includes(order.id)}
                        onChange={() => handleCheckboxChange("orders", order)}
                      />
                      <label htmlFor={order.id} className="m-0">
                        {order?.billing?.first_name +
                          " " +
                          order?.billing?.last_name}
                      </label>
                    </li>
                  ))}
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
