import React from "react";

export default function Summary({ order }) {
  return (
    <div className="bg-white p-4">
      <div className="d-flex justify-content-between ">
        <span className="font-weight-bold">Items Subtotal:</span>
        <span>
          {order.currency_symbol}
          {parseFloat(
            order.line_items.reduce(
              (acc, item) => acc + parseFloat(item.total),
              0
            )
          ).toFixed(2)}
        </span>
      </div>
      <div className="d-flex justify-content-between py-2">
        <span className="font-weight-bold">BTW-21:</span>
        <span>
          {order.currency_symbol}
          {parseFloat(order.total_tax).toFixed(2)}
        </span>
      </div>
      <div className="d-flex justify-content-between py-2">
        <span className="font-weight-bold">Order Total:</span>
        <span>
          {order.currency_symbol}
          {parseFloat(order.total).toFixed(2)}
        </span>
      </div>
      <div className="d-flex justify-content-between py-2">
        <span className="font-weight-bold">Paid:</span>
        <span>
          {order.currency_symbol}
          {parseFloat(order.total).toFixed(2)}
        </span>
      </div>
      <p className="text-muted mt-4">
        {new Date(order.date_paid).toLocaleDateString()} via{" "}
        {order.payment_method_title}
      </p>
      <p className="text-muted mt-4">This order is no longer editable.</p>
      <button className="mt-4 btn btn-primary">Refund</button>
    </div>
  );
}
