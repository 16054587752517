// components/NameSection.js
"use client";
import { useFormContext } from "react-hook-form";

export default function NameSection() {
  const methods = useFormContext();
  if (!methods) {
    return null; // or return some fallback UI
  }
  const { register } = methods;

  return (
    <div className="pt-2 pb-4">
      <h5 className="fw-bold">Name</h5>
      <div className="d-flex gap-1 mb-2">
        <input
          {...register("firstname")}
          placeholder="First name"
          className="form-control"
        />
        <input
          {...register("lastname")}
          placeholder="Last name"
          className="form-control"
        />
      </div>
      <div className="mt-2">
        <input
          {...register("email")}
          placeholder="E-mail address"
          className="form-control"
        />
      </div>
      <div className="mt-2">
        <input
          {...register("phone")}
          placeholder="Phone number"
          className="form-control"
        />
      </div>
    </div>
  );
}
