import React from "react";
import Details from "./Details";
import Actions from "./Actions";

export default function SidePane() {
  return (
    <div>
      <Details />
      <div className="mt-3">
        <Actions />
      </div>
    </div>
  );
}
