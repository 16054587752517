import React from "react";
import Answers from "../components/dashboard/utils/singleview/quotations/preview/Answers.jsx";
import Hero from "../components/dashboard/utils/singleview/quotations/preview/Hero.jsx";
import Result from "../components/dashboard/utils/singleview/quotations/preview/Result.jsx";
import { Link, useNavigate } from "react-router-dom";

export default function QuotationPreview() {
  const navigate = useNavigate();

  return (
    <div>
      <button
        onClick={() => navigate(-1)}
        className="btn btn-outline-primary mb-1"
      >
        Terug
      </button>
      <Hero />
      <Answers />
      <div className="mt-3">
        <Result />
      </div>
    </div>
  );
}
