import React, { useContext, useState } from "react";
import { CrmContext } from "../../../../../../../../context/CrmContext.jsx";

export default function Services({ checkedEntities, handleCheckboxChange }) {
  const { serviceTickets } = useContext(CrmContext);
  const [search, setSearch] = useState("");

  return (
    <div
      className="tab-pane fade show active"
      id="service"
      role="tabpanel"
      aria-labelledby="service-tab"
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <p className="card-text">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Services"
                  onChange={(e) => setSearch(e.target.value)}
                  style={{
                    marginBottom: "5px",
                  }}
                />

                <ul className="list-group">
                  {serviceTickets?.serviceTickets
                    ?.filter((service) => {
                      return service.subject
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    })
                    .map((service) => (
                      <li className="border p-1 rounded d-flex gap-1 align-items-center">
                        <input
                          type="checkbox"
                          id={service.id}
                          className="form-check-input"
                          checked={checkedEntities.services.includes(
                            service.id
                          )}
                          onChange={() =>
                            handleCheckboxChange("services", service)
                          }
                        />
                        <label htmlFor={service.id} className="m-0">
                          {service.subject}
                        </label>
                      </li>
                    ))}
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
