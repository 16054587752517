import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function General({ product }) {
  const [productData, setProductData] = useState({
    name: product.name,
    description: product.description,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value,
    });
  };

  const handleEditorChange = (data) => {
    setProductData({
      ...productData,
      description: data,
    });
  };

  return (
    <div>
      <input
        name="name"
        onChange={handleInputChange}
        className="form-control"
        type="text"
        value={productData.name}
      />
      <div className="mt-1">
        <CKEditor
          editor={ClassicEditor}
          data={productData.description}
          onReady={(editor) => {
            // console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            handleEditorChange(data);
          }}
          config={{
            toolbar: [
              "undo",
              "redo",
              "|",
              "bold",
              "italic",
              "blockQuote",
              "link",
            ],
          }}
        />
      </div>
      <style>{`
        .ck-editor__editable_inline {
          min-height: 250px;
          max-height: 250px;
        }
      `}</style>
    </div>
  );
}
