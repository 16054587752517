import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { StoreContext } from "../../../../../context/StoreContext";
import { getDataKeyFromPathname } from "../../../../../helpers/DeterminePath.jsx";

export default function AddEntity() {
  const location = useLocation();
  const { addEntity } = useContext(StoreContext);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const description = e.target.description.value;
    const slug = e.target.slug.value;

    const dataKey = getDataKeyFromPathname(location.pathname);

    const payload = {
      name,
      description,
      slug: dataKey === "attributes/terms" ? slug : undefined,
    };

    addEntity(location.pathname, payload);

    e.target.name.value = "";
    e.target.description.value = "";
    e.target.slug.value = "";
  };

  const getEntityType = () => {
    const dataKey = getDataKeyFromPathname(location.pathname);
    if (dataKey === "categories") return "category";
    if (dataKey === "tags") return "tag";
    if (dataKey === "attributes") return "attribute";
    if (dataKey === "attributes/terms") return "term";
    return "";
  };

  return (
    <div>
      <form className="mt-1" onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" className="form-control" />
        </div>
        <div className="form-group mt-2">
          <label htmlFor="description">Slug</label>
          <input type="text" name="slug" id="slug" className="form-control" />
        </div>
        <div className="form-group mt-2">
          <label htmlFor="description">Description</label>
          <textarea
            type="text"
            name="description"
            id="description"
            rows="3"
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-primary mt-2">
          Add {getEntityType()}
        </button>
      </form>
    </div>
  );
}
