"use client";
import React, { useState } from "react";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import moment from "moment";
import { CiCalendar } from "react-icons/ci";
import { handleChangeAndUpdateAnswer } from "../../../../../../helpers/Questionnaires.jsx";
import Calendar from "react-calendar"; // Assuming you are using react-calendar, adjust the import if different

export default function Datetime({ index, item }) {
  const { control, register } = useFormContext();
  const [date, setDate] = useState("");

  return (
    <div>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={
          <Popover id={`popover-positioned-bottom`} className="p-3">
            <Controller
              control={control}
              name={`answers.${index}.calendar`}
              render={({ field }) => (
                <Calendar
                  onChange={(date) => {
                    field.onChange(date);
                    handleChangeAndUpdateAnswer(
                      date,
                      item.originalId,
                      index,
                      "datetime",
                      item.field_type
                    );
                  }}
                  value={field.value || new Date(item.date)}
                  className="rounded border"
                />
              )}
            />
          </Popover>
        }
      >
        <Button
          variant="outline-secondary"
          className="d-flex justify-content-between align-items-center w-100"
        >
          {moment(item.date).format("MMM Do YY") ||
            moment(date).format("MMM Do YY") ||
            "Select a date"}
          <CiCalendar />
        </Button>
      </OverlayTrigger>
      <input
        {...register(`answers.${index}.price`)}
        placeholder="Price"
        name="price"
        type="number"
        onChange={(e) => {
          const newPrice = e.target.value;
          handleChangeAndUpdateAnswer(
            { price: parseInt(newPrice) },
            item.originalId,
            item,
            index,
            item.field_type,
            "price"
          );
        }}
        defaultValue={item.price || ""}
        className="form-control mt-2"
      />
    </div>
  );
}
