import React, { useState, useContext, useCallback } from "react";
import { StoreContext } from "../../../../../../context/StoreContext.jsx";
import VariationForm from "./VariationForm.jsx";

export default function Variations({ product }) {
  const [variationsDetail, setVariationsDetail] = useState(
    product.variations_detail
  );
  const [variations, setVariations] = useState(product.variations);

  const { updateProductVariations } = useContext(StoreContext);

  const generateVariationTemplate = () => {
    const newId = Date.now(); // Use current timestamp as a unique ID for new variations
    return {
      id: newId,
      name: "",
      slug: "",
      date_created: {
        date: new Date().toISOString(),
        timezone_type: 3,
        timezone: "Europe/Amsterdam",
      },
      date_modified: {
        date: new Date().toISOString(),
        timezone_type: 3,
        timezone: "Europe/Amsterdam",
      },
      status: "publish",
      featured: false,
      catalog_visibility: "visible",
      description: "",
      short_description: "",
      sku: "",
      price: "",
      regular_price: "",
      sale_price: "",
      date_on_sale_from: null,
      date_on_sale_to: null,
      total_sales: "0",
      tax_status: "taxable",
      tax_class: "",
      manage_stock: false,
      stock_quantity: null,
      stock_status: "instock",
      backorders: "no",
      low_stock_amount: "",
      sold_individually: false,
      weight: "",
      length: "",
      width: "",
      height: "",
      upsell_ids: [],
      cross_sell_ids: [],
      parent_id: product.id,
      reviews_allowed: false,
      purchase_note: "",
      attributes: { pa_kledingmaten: "" },
      default_attributes: [],
      menu_order: 2,
      post_password: "",
      virtual: false,
      downloadable: false,
      category_ids: [],
      tag_ids: [],
      shipping_class_id: 0,
      downloads: [],
      image_id: 0,
      gallery_image_ids: [],
      download_limit: "-1",
      download_expiry: "-1",
      rating_counts: [],
      average_rating: 0,
      review_count: 0,
      attribute_summary: "",
      meta_data: [{ id: 0, key: "seopress_global_ids", value: "none" }],
      product_global_identifiers: "",
      image: "",
    };
  };

  const handleAddVariation = () => {
    const newVariation = generateVariationTemplate();
    setVariationsDetail([...variationsDetail, newVariation]);
    setVariations([...variations, newVariation.id]); // Add the new ID to the variations array
  };

  const handleVariationChange = useCallback((updatedVariation) => {
    setVariationsDetail((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === updatedVariation.id ? updatedVariation : variation
      )
    );
  }, []);

  const handleSaveChanges = async () => {
    const createVariations = variationsDetail.filter(
      (variation) => !product.variations.includes(variation.id)
    );
    const updateVariations = variationsDetail.filter((variation) =>
      product.variations.includes(variation.id)
    );
    console.log("updateVariations", updateVariations);
    const deleteVariations = product.variations.filter(
      (id) => !variations.includes(id)
    );

    const data = {
      create: createVariations.map((variation) => ({
        regular_price: variation.regular_price,
        sale_price: variation.sale_price,
        attributes: [
          {
            id: 6, // Assuming 'id' is for attributes and it should be changed accordingly.
            option: variation.attributes.pa_kledingmaten,
          },
        ],
        // Include other required properties for creation
        sku: variation.sku,
        manage_stock: variation.manage_stock,
        stock_quantity: variation.stock_quantity,
        stock_status: variation.stock_status,
        weight: variation.weight,
        dimensions: {
          length: variation.length,
          width: variation.width,
          height: variation.height,
        },
        shipping_class: variation.shipping_class,
        virtual: variation.virtual,
        downloadable: variation.downloadable,
        downloads: variation.downloads,
        download_limit: variation.download_limit,
        download_expiry: variation.download_expiry,
        description: variation.description,
        image: {
          id: variation.image_id,
          src: variation.image,
        },
      })),
      update: updateVariations.map((variation) => ({
        id: variation.id,
        regular_price: variation.regular_price,
        sale_price: variation.sale_price,
        // Include other required properties for updating
        sku: variation.sku,
        manage_stock: variation.manage_stock,
        stock_quantity: variation.stock_quantity,
        stock_status: variation.stock_status,
        weight: variation.weight,
        dimensions: {
          length: variation.length,
          width: variation.width,
          height: variation.height,
        },
        shipping_class: variation.shipping_class,
        virtual: variation.virtual,
        downloadable: variation.downloadable,
        downloads: variation.downloads,
        download_limit: variation.download_limit,
        download_expiry: variation.download_expiry,
        description: variation.description,
        image: {
          id: variation.image_id,
          src: variation.image,
        },
      })),
      delete: deleteVariations,
    };

    try {
      await updateProductVariations(product.id, data);
      console.log("Variations updated successfully");
    } catch (error) {
      console.error("Error updating variations:", error);
    }
  };

  return (
    <div className="row">
      <div className="d-flex gap-1">
        <button className="btn btn-outline-primary">Generate Variations</button>
        <button
          onClick={handleAddVariation}
          className="btn btn-outline-primary"
        >
          Add manually
        </button>
      </div>
      {variationsDetail.map((variation) => (
        <React.Fragment key={variation.id}>
          <div className="row justify-content-between align-items-center mt-2">
            <div className="col-6">
              <select
                value={variation.attributes.pa_kledingmaten || ""}
                onChange={(e) =>
                  setVariationsDetail((prevVariations) =>
                    prevVariations.map((v) =>
                      v.id === variation.id
                        ? {
                            ...v,
                            attributes: {
                              ...v.attributes,
                              pa_kledingmaten: e.target.value,
                            },
                          }
                        : v
                    )
                  )
                }
                className="form-select"
                aria-label="Select an attribute"
              >
                {product.attributes.map((attribute) => (
                  <React.Fragment key={attribute.id}>
                    {attribute.options.map((option, optIndex) => (
                      <option value={option} key={optIndex}>
                        {option}
                      </option>
                    ))}
                  </React.Fragment>
                ))}
              </select>
            </div>
            <div className="col-6">
              <div className="d-flex gap-2 justify-content-end">
                <span
                  style={{ cursor: "pointer" }}
                  className="text-danger"
                  onClick={() => {
                    setVariationsDetail(
                      variationsDetail.filter((v) => v.id !== variation.id)
                    );
                    setVariations(
                      variations.filter((id) => id !== variation.id)
                    );
                  }}
                >
                  Remove
                </span>
                <span
                  className="d-flex justify-content-end"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse-${variation.id}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${variation.id}`}
                >
                  Edit
                </span>
              </div>
            </div>
          </div>
          <div className="collapse mt-2" id={`collapse-${variation.id}`}>
            <VariationForm
              variation={variation}
              onVariationChange={handleVariationChange}
            />
          </div>
        </React.Fragment>
      ))}
      <div>
        <button
          className="btn btn-outline-primary mt-2"
          onClick={handleSaveChanges}
        >
          Save changes
        </button>
      </div>
    </div>
  );
}
