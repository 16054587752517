import React, { useContext } from "react";
import moment from "moment";
import { CrmContext } from "../../../../../../context/CrmContext";

export default function Orders() {
  const { contact } = useContext(CrmContext);

  // const orders = [
  //   {
  //     id: 1,
  //     orderNumber: "ORD-001",
  //     orderDate: "2021-08-01",
  //     orderStatus: "Pending",
  //   },
  //   {
  //     id: 2,
  //     orderNumber: "ORD-002",
  //     orderDate: "2021-08-02",
  //     orderStatus: "Delivered",
  //   },
  //   {
  //     id: 3,
  //     orderNumber: "ORD-003",
  //     orderDate: "2021-08-03",
  //     orderStatus: "Pending",
  //   },
  //   {
  //     id: 4,
  //     orderNumber: "ORD-004",
  //     orderDate: "2021-08-04",
  //     orderStatus: "Delivered ",
  //   },
  // ];

  return (
    <div className="row">
      <div className="col-12 bg-white p-2">
        <h3 className="fs-5">Orders</h3>
        <table className="table mt-1">
          <thead>
            <tr>
              <th scope="col">Order Number</th>
              <th scope="col">Order Date</th>
              <th scope="col">Order Status</th>
            </tr>
          </thead>
          <tbody>
            {contact?.orders.map((order) => (
              <tr key={order.id}>
                <td>{order?.orderInformation?.id}</td>
                <td>
                  {moment(order?.orderInformation?.date_created).format(
                    "YYYY-MM-DD"
                  )}
                </td>
                <td>{order?.orderInformation?.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
