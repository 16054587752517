import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import moment from "moment";

export default function MetaData({ media }) {
  if (!media) {
    return <div>Select an image to see its details</div>;
  }

  console.log("media", media);

  return (
    <div
      className="metadata-container"
      style={{
        height: "60vh",
        overflowY: "auto",
      }}
    >
      <div className="mb-3">
        <img
          src={media.source_url || media.url}
          alt={media.alt_text}
          className="img-thumbnail w-100"
        />
      </div>
      <div className="mb-3">
        <h6>{media.filename || media.name}</h6>
        <p>{media.date || moment(media.updatedAt).format("MMMM Do YYYY")}</p>
        <p>{media.filesizeInBytes || media.size / 1000} KB</p>
        <p>{media.dimensions || `${media.width}x${media.height}`}</p>
        <Button variant="link" className="p-0">
          Edit Image
        </Button>
        <Button variant="link" className="p-0 text-danger">
          Delete permanently
        </Button>
      </div>
      <Form>
        <Form.Group controlId="formAltText" className="mb-3">
          <Form.Label>Alt Text</Form.Label>
          <Form.Control
            type="text"
            defaultValue={media.alt_text || media.alternativeText}
          />
          <Form.Text className="text-muted">
            Learn how to describe the purpose of the image. Leave empty if the
            image is purely decorative.
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formTitle" className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            defaultValue={media?.title?.rendered || media.name}
          />
        </Form.Group>
        <Form.Group controlId="formCaption" className="mb-3">
          <Form.Label>Caption</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={media?.caption?.rendered || media.caption || ""}
          />
        </Form.Group>
        <Form.Group controlId="formDescription" className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={media?.description?.rendered || media.description}
          />
        </Form.Group>
        <Form.Group controlId="formFileURL" className="mb-3">
          <Form.Label>File URL</Form.Label>
          <Form.Control type="text" defaultValue={media.source_url} readOnly />
          <Button variant="link" className="p-0">
            Copy URL to clipboard
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
