import React from "react";
import Customer from "../components/dashboard/utils/singleview/quotations/customer/Customer.jsx";
import Product from "../components/dashboard/utils/singleview/quotations/product/Product.jsx";
import Questionnaire from "../components/dashboard/utils/singleview/quotations/questionnaires/Questionnaires.jsx";
import { Link, useParams, Outlet, useLocation } from "react-router-dom";

export default function QuotationSingle() {
  const { id } = useParams();
  const location = useLocation();

  const isPreview = location.pathname.includes("preview");

  return (
    <div>
      <div className="d-flex justify-content-between">
        {!isPreview && (
          <>
            <h2>Quotation</h2>
            <Link
              to={`/quotations/${id}/preview`}
              className="btn btn-primary d-flex align-items-center"
            >
              Preview
            </Link>
          </>
        )}
      </div>
      {isPreview ? (
        <Outlet />
      ) : (
        <>
          <Customer />
          <Product />
          <div className="mt-3">
            <Questionnaire />
          </div>
        </>
      )}
    </div>
  );
}
