import React from "react";
import { useFormContext } from "react-hook-form";
import { handleChangeAndUpdateAnswer } from "../../../../../../helpers/Questionnaires.jsx";
import { Form } from "react-bootstrap";

export default function Boolean({ index, item }) {
  const { register } = useFormContext();

  return (
    <div>
      <div className="d-flex align-items-center">
        <Form.Check
          type="switch"
          id={`switch-${index}`}
          label="Yes / No"
          {...register(`answers.${index}.switch`)}
          defaultChecked={item.boolean}
          onChange={(e) =>
            handleChangeAndUpdateAnswer(
              { boolean: e.target.checked },
              item.originalId,
              item,
              index,
              item.field_type
            )
          }
        />
      </div>
      <Form.Control
        {...register(`answers.${index}.price`)}
        placeholder="Price"
        name="price"
        type="number"
        onChange={(e) => {
          const newPrice = e.target.value;

          handleChangeAndUpdateAnswer(
            { price: parseInt(newPrice) },
            item.originalId,
            item,
            index,
            item.field_type,
            "price"
          );
        }}
        defaultValue={item.price || ""}
        className="mt-2"
      />
    </div>
  );
}
