import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Repeater = ({
  field,
  groupIndex,
  fieldIndex,
  parentFields,
  rowIndex,
  subFieldKey,
  handleFieldChange,
  handleAddRow,
  handleRemoveRow,
  handleWysiwygChange,
  renderFieldValue,
}) => {
  const repeaterValue = Array.isArray(field.value) ? field.value : [];

  return (
    <div>
      {repeaterValue.map((item, rowIndex) => (
        <div key={rowIndex} className="repeater-row mt-2 p-2 border rounded">
          <div className="d-flex justify-content-between">
            <p>{`Row ${rowIndex + 1}`}</p>
            <button
              className="btn btn-outline-danger"
              onClick={() =>
                handleRemoveRow(
                  groupIndex,
                  fieldIndex,
                  rowIndex,
                  parentFields || repeaterValue
                )
              }
            >
              Remove Row
            </button>
          </div>
          {Object.entries(item).map(([key, val], idx) => (
            <div className="mt-1" key={key}>
              <strong>{key}:</strong>{" "}
              {val && typeof val === "object" ? (
                val.type === "repeater" ? (
                  <Repeater
                    field={val}
                    groupIndex={groupIndex}
                    fieldIndex={fieldIndex}
                    parentFields={repeaterValue}
                    rowIndex={rowIndex}
                    subFieldKey={key}
                    handleFieldChange={handleFieldChange}
                    handleAddRow={handleAddRow}
                    handleRemoveRow={handleRemoveRow}
                    handleWysiwygChange={handleWysiwygChange}
                    renderFieldValue={renderFieldValue}
                  />
                ) : (
                  renderFieldValue(
                    val,
                    groupIndex,
                    fieldIndex,
                    repeaterValue,
                    rowIndex,
                    key,
                    idx,
                    null
                  )
                )
              ) : (
                String(val)
              )}
            </div>
          ))}
        </div>
      ))}
      <button
        className="btn btn-outline-primary mt-1"
        onClick={() =>
          handleAddRow(
            groupIndex,
            fieldIndex,
            parentFields ? parentFields[fieldIndex].value : repeaterValue
          )
        }
      >
        Add Row
      </button>
    </div>
  );
};

export default Repeater;
