import React from "react";
import Options from "./fields/Options";
import Attributes from "./fields/Attributes";
import Variations from "./fields/Variations";

export default function TabContent({ tab, fields, product }) {
  return (
    <div className="tab-content">
      {fields.map((field, index) => (
        <div key={index} className="tab-pane fade show active">
          <div className="form-group mb-2">
            <label>{field.title}</label>
            <input
              type="text"
              className="form-control"
              placeholder={`Change the ${field.title}`}
              defaultValue={product[field.title] || ""}
            />
          </div>
        </div>
      ))}
      {tab.title === "Variations" && <Variations product={product} />}
      {tab.title === "Attributes" && (
        <div>
          <Attributes tab={tab} product={product} />
        </div>
      )}
    </div>
  );
}
