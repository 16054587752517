import React, { useState, useContext } from "react";
import CreateEntry from "./CreateEntry.jsx";
import { StoreContext } from "../../../../../context/StoreContext.jsx";
import { useLocation } from "react-router-dom";

export default function Crud() {
  const [modalShow, setModalShow] = useState(false);
  const { removeProduct, removeQuestionnaire, entryIds } =
    useContext(StoreContext);

  return (
    <div className="d-flex gap-1">
      <button
        onClick={() => setModalShow(true)}
        className="btn btn-outline-warning"
        type="button"
      >
        Create
      </button>
      <CreateEntry show={modalShow} onHide={() => setModalShow(false)} />
      <button className="btn btn-outline-info" type="button">
        Edit
      </button>
      <button
        onClick={
          location.pathname.includes("questionnaires")
            ? () => removeQuestionnaire(entryIds)
            : () => removeProduct(entryIds)
        }
        className="btn btn-outline-danger"
        type="button"
      >
        Delete
      </button>
    </div>
  );
}
