import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Dropdown, Form } from "react-bootstrap";
import { handleChangeAndUpdateAnswer } from "../../../../../../helpers/Questionnaires.jsx";

export default function Select({ index, item }) {
  const { register } = useFormContext();
  const [selectedOption, setSelectedOption] = useState({
    title: "Select an option",
    price: "",
  });

  useEffect(() => {
    if (!item || !item.variables) {
      console.log("Waiting for item and variables to be loaded...");
      return;
    }

    // Determine the initially selected option from item.variables
    const initialOption = Object.values(item.variables).find(
      (option) => option.selected
    ) || {
      title: "Select an option",
      price: "",
    };
    setSelectedOption(initialOption);
  }, [item]);

  const handleSelect = async (optionTitle, optionIndex, price) => {
    setSelectedOption({ title: optionTitle, price });

    // Update the selected field in item.variables
    const updatedVariables = Object.entries(item.variables).reduce(
      (acc, [key, variable]) => {
        acc[key] = {
          ...variable,
          selected: variable.title === optionTitle,
        };
        return acc;
      },
      {}
    );

    try {
      await handleChangeAndUpdateAnswer(
        { title: optionTitle, selected: true, price },
        item.originalId,
        index,
        "variables",
        item.field_type,
        optionTitle,
        item
      );
      item.variables = updatedVariables; // Update item.variables with new selection state
    } catch (error) {
      console.error("Failed to update selection:", error);
    }
  };

  return (
    <div className="w-100 mt-2">
      <Dropdown>
        <Dropdown.Toggle variant="outline-secondary" className="w-100">
          {selectedOption?.title}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.entries(item.variables).map(([key, option], optionIndex) => (
            <Dropdown.Item
              onClick={() =>
                handleSelect(option.title, optionIndex, option.price)
              }
              key={key}
            >
              {option.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Form.Control
        {...register(`answers.${index}.price`)}
        placeholder="Price"
        name="price"
        type="number"
        onChange={(e) => {
          const newPrice = parseInt(e.target.value, 10);
          handleChangeAndUpdateAnswer(
            {
              title: selectedOption.title,
              selected: true,
              price: newPrice,
            },
            item.originalId,
            index,
            "variables",
            item.field_type,
            "price",
            item
          );
        }}
        defaultValue={selectedOption.price}
        className="mt-2"
      />
    </div>
  );
}
