import React, { useState } from "react";

const CollapsibleField = ({ field, selectedFields, handleCheckboxChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const isFieldSelected = (fieldId) =>
    !!selectedFields.find((selectedField) => selectedField.id === fieldId);

  return (
    <div className="collapsible-field">
      <div className="d-flex align-items-center gap-1 mb-1">
        <input
          type="checkbox"
          className="form-check-input"
          id={field.id}
          checked={isFieldSelected(field.id)}
          onChange={(e) => handleCheckboxChange(field, e.target.checked)}
        />
        <label
          htmlFor={field.id}
          onClick={toggleOpen}
          style={{ cursor: "pointer" }}
        >
          {field.attributes.title}
        </label>
        {field.attributes.fields && (
          <button onClick={toggleOpen} style={{ cursor: "pointer" }}>
            {isOpen ? "-" : "+"}
          </button>
        )}
      </div>
      {isOpen && field.attributes.fields && (
        <div className="nested-fields" style={{ paddingLeft: "20px" }}>
          {field.attributes.fields.map((nestedField) => (
            <CollapsibleField
              key={nestedField.id}
              field={nestedField}
              selectedFields={selectedFields}
              handleCheckboxChange={handleCheckboxChange}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CollapsibleField;
