import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { CrmContext } from "../../../../../../../context/CrmContext.jsx";
import { StoreContext } from "../../../../../../../context/StoreContext.jsx";
import Companies from "./entities/Companies.jsx";
import Contacts from "./entities/Contacts.jsx";
import Deals from "./entities/Deals.jsx";
import Services from "./entities/Services.jsx";
import Orders from "./entities/Orders.jsx";

import "./correspondence.scss";

export default function LinkEntities(props) {
  const [activeTab, setActiveTab] = useState("contacts");
  const { contacts, companies, deals, serviceTickets } = useContext(CrmContext);
  const { orders } = useContext(StoreContext);

  // State management for each entity type
  const [checkedEntities, setCheckedEntities] = useState({
    contacts: [],
    companies: [],
    orders: [],
    deals: [],
    services: [],
  });

  // Initialize checked entities based on the existing email relationships
  useEffect(() => {
    setCheckedEntities({
      contacts: contacts
        .filter((contact) =>
          contact?.emails?.some((email) => email.id === props.email.id)
        )
        .map((contact) => contact.id),
      companies: companies
        .filter((company) =>
          company?.emails?.some((email) => email.id === props.email.id)
        )
        .map((company) => company.id),
      orders: orders
        .filter((order) =>
          order?.emails?.some((email) => email.id === props.email.id)
        )
        .map((order) => order.id),
      deals: deals
        .filter((deal) =>
          deal?.emails?.some((email) => email.id === props.email.id)
        )
        .map((deal) => deal.id),
      services: serviceTickets?.serviceTickets
        .filter((service) =>
          service?.emails?.some((email) => email.id === props.email.id)
        )
        .map((service) => service.id),
    });
  }, [contacts, companies, orders, deals, serviceTickets, props.email.id]);

  const handleCheckboxChange = async (type, entity) => {
    const isChecked = checkedEntities[type].includes(entity.id);

    // Update the state based on the checkbox status
    setCheckedEntities((prevState) => ({
      ...prevState,
      [type]: isChecked
        ? prevState[type].filter((id) => id !== entity.id)
        : [...prevState[type], entity.id],
    }));

    // Update the email relationships in Strapi
    await linkEntitiesToEmail(type, entity);
  };

  const linkEntitiesToEmail = async (type, entity) => {
    await fetch(
      `${import.meta.env.VITE_STRAPI_URL_DEV}/customercenter/emails/${
        props.email.id
      }`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN_DEV}`,
        },
        body: JSON.stringify({
          entity: entity,
          type: type,
        }),
      }
    );
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="link-entities"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Koppelingen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="nav justify-content-center">
          <li className="nav-item">
            <Link
              className={`nav-link ${activeTab === "contacts" ? "active" : ""}`}
              to="#correspondence&contacts"
              role="tab"
              aria-controls="contacts"
              onClick={() => setActiveTab("contacts")}
            >
              Contacts
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                activeTab === "companies" ? "active" : ""
              }`}
              to="#correspondence&companies"
              role="tab"
              aria-controls="companies"
              onClick={() => setActiveTab("companies")}
            >
              Companies
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${activeTab === "orders" ? "active" : ""}`}
              to="#correspondence&orders"
              role="tab"
              aria-controls="orders"
              onClick={() => setActiveTab("orders")}
            >
              Orders
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${activeTab === "deals" ? "active" : ""}`}
              to="#correspondence&deals"
              role="tab"
              aria-controls="deals"
              onClick={() => setActiveTab("deals")}
            >
              Deals
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${activeTab === "service" ? "active" : ""}`}
              to="#correspondence&service"
              role="tab"
              aria-controls="service"
              onClick={() => setActiveTab("service")}
            >
              Services
            </Link>
          </li>
        </ul>
        <div className="tab-content">
          {activeTab === "contacts" && (
            <Contacts
              checkedEntities={checkedEntities}
              handleCheckboxChange={handleCheckboxChange}
            />
          )}
          {activeTab === "companies" && (
            <Companies
              checkedEntities={checkedEntities}
              handleCheckboxChange={handleCheckboxChange}
            />
          )}
          {activeTab === "orders" && (
            <Orders
              checkedEntities={checkedEntities}
              handleCheckboxChange={handleCheckboxChange}
            />
          )}
          {activeTab === "deals" && (
            <Deals
              checkedEntities={checkedEntities}
              handleCheckboxChange={handleCheckboxChange}
            />
          )}
          {activeTab === "service" && (
            <Services
              checkedEntities={checkedEntities}
              handleCheckboxChange={handleCheckboxChange}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
