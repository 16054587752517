import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { Buffer } from "buffer";
import General from "/components/dashboard/utils/singleview/products/General.jsx";
import Sidebar from "/components/dashboard/utils/singleview/products/Sidebar.jsx";
import TabView from "/components/dashboard/utils/singleview/products/TabView.jsx";
import CustomFields from "/components/dashboard/utils/singleview/products/CustomFields.jsx";
import TypeSelection from "/components/dashboard/utils/singleview/products/TypeSelection.jsx";
import LoadingIndicator from "/components/utils/LoadingIndicator.jsx";

export default function ProductSingle() {
  const params = useParams();
  const location = useLocation();

  // WooCommerce API credentials
  const username = import.meta.env.VITE_WOOCOMMERCE_USERNAME;
  const password = import.meta.env.VITE_WOOCOMMERCE_SECRET;
  const authBase64 = Buffer.from(`${username}:${password}`).toString("base64");

  // Strapi API credentials
  const authBase64WP = Buffer.from(`${username}:${password}`).toString(
    "base64"
  );

  const queryClient = useQueryClient();

  const productQuery = useQuery(
    ["product", params.id],
    () =>
      fetch(`/api/wp-json/wc/v3/products/${params.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${authBase64}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      }),
    {
      enabled: !location.pathname.includes("terms"),
    }
  );

  const productCategoriesQuery = useQuery(
    "productcategories",
    () =>
      fetch(`/api/wp-json/wc/v3/products/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${authBase64}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      }),
    {
      enabled: !location.pathname.includes("terms"),
    }
  );

  const productTabsQuery = useQuery(
    "producttabs",
    () =>
      fetch(
        `${
          import.meta.env.VITE_STRAPI_URL
        }/woostoremanager/get-product-tabs?populate=*`,
        {
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      ).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      }),
    {
      enabled: !location.pathname.includes("terms"),
    }
  );

  if (productQuery.isLoading || productTabsQuery.isLoading)
    return <LoadingIndicator />;
  if (productQuery.isError)
    return <div>Error: {productQuery.error.message}</div>;
  if (productTabsQuery.isError)
    return <div>Error: {productTabsQuery.error.message}</div>;
  if (productCategoriesQuery.isError)
    return <div>Error: {productCategoriesQuery.error.message}</div>;
  // console.log(productQuery.data);
  // console.log("tabs", productTabsQuery.data);

  return (
    <div>
      <div className="row">
        <div className="col-8">
          <General product={productQuery.data} />
          <div className="mt-2">
            <div className="mb-1">
              <TypeSelection product={productQuery.data} />
            </div>
            <TabView product={productQuery.data} tabs={productTabsQuery.data} />
            <CustomFields product={productQuery.data} />
          </div>
        </div>
        <div className="col-4">
          <Sidebar
            categories={productCategoriesQuery.data}
            product={productQuery.data}
          />
        </div>
      </div>
    </div>
  );
}
