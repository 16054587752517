"use client";

import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Modal, Button, Form, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { StoreContext } from "../../../../../../context/StoreContext";
import { QuotationContext } from "../../../../../../context/QuotationContext";

const productSelectionSchema = z.object({
  selectedProduct: z.string(),
});

export default function Product() {
  const { products } = useContext(StoreContext);
  const { UpdateWithProduct, quotation } = useContext(QuotationContext);
  console.log("products", products);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(products || []);
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(productSelectionSchema),
  });

  useEffect(() => {
    if (!search) {
      setFilteredProducts(products);
      return;
    }
    const lowercasedSearch = search.toLowerCase();
    const result = products.filter((product) =>
      product?.productInformation?.name
        ?.toLowerCase()
        .includes(lowercasedSearch)
    );
    setFilteredProducts(result);
  }, [search, products]);

  const onSubmit = (data) => {
    UpdateWithProduct(data, params.id);
    setOpen(false);
  };

  function truncateText(text, maxLength) {
    if (!text) return ""; // Return empty string if text is falsy
    if (text.length <= maxLength) return text; // Return the text if it's shorter than maxLength
    return `${text.substring(0, maxLength)}...`; // Truncate and append an ellipsis
  }

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="h4">Select a product</h2>
        <Button variant="outline-primary" onClick={() => setOpen(true)}>
          Select a product
        </Button>
      </div>
      <Modal show={open} onHide={() => setOpen(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select a product</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="searchProduct" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Search for a product"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Group>
            {filteredProducts?.map((product, index) => (
              <Form.Group
                key={index}
                className="d-flex align-items-center mb-2"
              >
                {product?.images && (
                  <img
                    src={product?.images[0]?.src}
                    alt="Product image"
                    width={50}
                    height={50}
                    className="me-2"
                  />
                )}
                <label
                  htmlFor={product?.productInformation?.name}
                  className="m-0"
                >
                  <div className="d-flex align-items-center gap-1">
                    <input
                      type="radio"
                      className="form-check-input"
                      label={product?.productInformation?.name}
                      value={product?.id}
                      id={product?.productInformation?.name}
                      {...register("selectedProduct")}
                    />
                    <p>{product?.name}</p>
                  </div>
                </label>
              </Form.Group>
            ))}
            <Button type="submit" className="mt-2">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="bg-white p-1  mt-2">
        {quotation?.woostoremanager_products?.map((product) => (
          <div
            key={product.productInformation.id}
            className="d-flex gap-4 align-items-center"
          >
            {product?.productInformation?.images && (
              <div className="w-25 h-auto">
                <Image
                  src={product?.productInformation?.images[0]?.src}
                  alt="Product image"
                  fluid
                />
              </div>
            )}
            <div className="flex-grow-1">
              <h4>{product.productInformation.name}</h4>
              {parse(truncateText(product.productInformation.description, 200))}
              <Link to={`/products/${product.productInformation.id}`}>
                <Button className="mt-1" variant="primary">
                  View product
                </Button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
