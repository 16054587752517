import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import EditView from "./EditView.jsx";

export default function Views() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="me-2">
        Edit view
      </Button>
      <EditView show={show} handleClose={handleClose} placement="end" />
    </>
  );
}
