import React, { useContext } from "react";
import LogoLight from "../../../../../../assets/logo/logo-floowd.svg";
import moment from "moment";
import "moment/locale/nl"; // Ensure Dutch locale is imported
import { QuotationContext } from "../../../../../../context/QuotationContext.jsx";

export default function Hero() {
  // Set moment to use Dutch locale
  moment.locale("nl");
  const currentDate = moment().format("LL");
  const { quotation } = useContext(QuotationContext);
  console.log("quotation", quotation);

  return (
    <div
      className="hero-section position-relative bg-white border-bottom p-2 rounded h-100 "
      style={{
        backgroundImage: `url(${quotation?.featured_image?.url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="image mb-2">
        <img src={LogoLight} alt="Floowd" width={100} />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="text-lg fw-semibold bg-white bg-opacity-75 pt-1 pb-2">
          {currentDate}
        </div>
        Offerte {quotation?.user?.first_name} {quotation?.user?.last_name}
      </div>
      <div className="container position-relative z-2">
        <div className="d-flex h-100">
          <div className="text-start">
            <p className="mb-2">
              <span className="fw-semibold">Name:</span>{" "}
              {quotation?.user?.first_name} {quotation?.user?.last_name}
            </p>
            <p className="mb-2">
              <span className="fw-semibold">Email:</span>{" "}
              {quotation?.user?.email}
            </p>
            <p>
              <span className="fw-semibold">Phone:</span>{" "}
              {quotation?.user?.phone}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
