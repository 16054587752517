import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import SidePane from "../components/dashboard/utils/singleview/contacts/SidePane/SidePane.jsx";
import OverviewPane from "../components/dashboard/utils/singleview/contacts/OverviewPane/OverviewPane.jsx";
import ContactForm from "../components/dashboard/utils/singleview/contacts/SidePane/ContactForm.jsx";
import CompanyDetails from "../components/dashboard/utils/singleview/contacts/SidePane/CompanyDetails.jsx";
import Correspondence from "../components/dashboard/utils/singleview/contacts/OverviewPane/Correspondence/Correspondence.jsx";
import Deals from "../components/dashboard/utils/singleview/contacts/OverviewPane/Deals.jsx";

export default function ContactSingle() {
  const location = useLocation();
  const { id } = useParams();

  // Function to parse the hash and return the primary tab
  const getTabFromHash = () => {
    if (location.hash.includes("#overview")) return "overview";
    if (location.hash.includes("#deals")) return "deals";
    if (location.hash.includes("#activities")) return "activities";
    if (location.hash.includes("#correspondence")) return "correspondence";
    return "overview"; // Default tab
  };

  const activeTab = getTabFromHash();

  return (
    <div className="row">
      <div className="col-4">
        <div className="col-12 bg-white p-2">
          <SidePane />
        </div>
        <div className="col-12 p-2 bg-white mt-1">
          <ContactForm />
        </div>
        <div className="col-12 p-2 bg-white mt-1">
          <CompanyDetails />
        </div>
      </div>
      <div className="col-8">
        <ul className="nav nav-tabs" id="customerTab" role="tablist">
          <li className="nav-item" role="presentation">
            <Link
              className={`nav-link ${activeTab === "overview" ? "active" : ""}`}
              id="overview-tab"
              to="#overview"
              role="tab"
              aria-controls="overview"
              aria-selected={activeTab === "overview"}
            >
              Overview
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className={`nav-link ${activeTab === "deals" ? "active" : ""}`}
              id="deals-tab"
              to="#deals"
              role="tab"
              aria-controls="deals"
              aria-selected={activeTab === "deals"}
            >
              Deals
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className={`nav-link ${
                activeTab === "activities" ? "active" : ""
              }`}
              id="activities-tab"
              to="#activities"
              role="tab"
              aria-controls="activities"
              aria-selected={activeTab === "activities"}
            >
              Activities
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className={`nav-link ${
                activeTab === "correspondence" ? "active" : ""
              }`}
              id="correspondence-tab"
              to="#correspondence"
              role="tab"
              aria-controls="correspondence"
              aria-selected={activeTab === "correspondence"}
            >
              Correspondence
            </Link>
          </li>
        </ul>
        <div className="tab-content" id="customerTabContent">
          {activeTab === "deals" && (
            <div
              className="tab-pane fade show active"
              id="deals"
              role="tabpanel"
              aria-labelledby="deals-tab"
            >
              <Deals />
            </div>
          )}
          {activeTab === "activities" && (
            <div
              className="tab-pane fade show active"
              id="activities"
              role="tabpanel"
              aria-labelledby="activities-tab"
            >
              {/* Content for the "Activiteiten" tab goes here */}
            </div>
          )}
          {activeTab === "correspondence" && (
            <div
              className="tab-pane fade show active"
              id="correspondence"
              role="tabpanel"
              aria-labelledby="correspondence-tab"
            >
              <Correspondence />
            </div>
          )}
          {activeTab === "overview" && (
            <div
              className="tab-pane fade show active"
              id="overview"
              role="tabpanel"
              aria-labelledby="overview-tab"
            >
              <OverviewPane />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
