import React from "react";

export default function LineItems({ order }) {
  return (
    <div className="container p-2 bg-white">
      <div className="bg-light p-3">
        <div className="d-flex justify-content-between border-bottom border-secondary py-2">
          <span className="font-weight-bold">Item</span>
          <span className="font-weight-bold">Cost</span>
          <span className="font-weight-bold">Qty</span>
          <span className="font-weight-bold">Total</span>
          <span className="font-weight-bold">BTW-21</span>
        </div>
        {order.line_items.map((item, index) => (
          <div key={index} className="d-flex justify-content-between py-2">
            <div className="d-flex align-items-center">
              <img
                src={item.image.src}
                alt={item.name}
                className="img-thumbnail mr-4"
                style={{ width: "64px", height: "64px" }}
              />
              <div>
                <a href="#" className="text-primary">
                  {item.name}
                </a>
                <p>Variation ID: {item.variation_id}</p>
                <p>
                  Afmeting:{" "}
                  {item.meta_data.find((meta) => meta.key === "afmeting").value}
                </p>
              </div>
            </div>
            <span>
              {order.currency_symbol}
              {parseFloat(item.price).toFixed(2)}
            </span>
            <span>{item.quantity}</span>
            <span>
              {order.currency_symbol}
              {parseFloat(item.total).toFixed(2)}
            </span>
            <span>
              {order.currency_symbol}
              {parseFloat(item.total_tax).toFixed(2)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
