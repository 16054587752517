import React, { useContext } from "react";
import { StoreContext } from "../../../../../context/StoreContext";
import { FaMoneyBill } from "react-icons/fa";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import { FaBox } from "react-icons/fa6";
import { BiAtom } from "react-icons/bi";

export default function Performance() {
  const { reports } = useContext(StoreContext);

  console.log("reports", reports);

  return (
    <div>
      <h3>Performance</h3>
      <div className="row">
        <div className="col bg-white p-2 m-1">
          <FaMoneyBill
            size={35}
            className="p-1 rounded-circle mb-1 bg-qwerty text-secondary"
          />
          <p> Total Sales: </p>
          <p> € {reports[0]?.total_sales} </p>
        </div>
        <div className="col bg-white p-2 m-1">
          <FaHandHoldingUsd
            size={35}
            className="p-1 rounded-circle mb-1 bg-qwerty text-secondary"
          />
          <p> Net Sales: </p>
          <p> € {reports[0]?.net_sales} </p>
        </div>
        <div className="col bg-white p-2 m-1">
          <FaList
            size={35}
            className="p-1 rounded-circle mb-1 bg-qwerty text-secondary"
          />
          <p> Orders: </p>
          <p> {reports[0]?.total_orders} </p>
        </div>
        <div className="col bg-white p-2 m-1">
          <FaBox
            size={35}
            className="p-1 rounded-circle mb-1 bg-qwerty text-secondary"
          />
          <p> Products sold: </p>
          <p> {reports[0]?.total_items} </p>
        </div>
        <div className="col bg-white p-2 m-1">
          <BiAtom
            size={35}
            className="p-1 rounded-circle mb-1 bg-qwerty text-secondary"
          />
          <p>Variations sold: </p>
          <p> {reports[0]?.total_items} </p>
        </div>
      </div>
    </div>
  );
}
