import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../../../../../context/StoreContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Repeater from "./Repeater";

export default function CustomFields() {
  const { acfFields, handleAcfField } = useContext(StoreContext);
  const [repeaterFields, setRepeaterFields] = useState(acfFields);

  const handleFieldChange = (
    e,
    groupIndex,
    fieldIndex,
    rowIndex = null,
    subFieldKey = null,
    subRowIndex = null,
    subSubFieldKey = null
  ) => {
    const { value } = e.target;
    const newRepeaterFields = [...repeaterFields];

    // Debug logs
    console.log("=== handleFieldChange Called ===");
    console.log("Group Index:", groupIndex);
    console.log("Field Index:", fieldIndex);
    console.log("Row Index:", rowIndex);
    console.log("Sub Field Key:", subFieldKey);
    console.log("Sub Row Index:", subRowIndex);
    console.log("Sub Sub Field Key:", subSubFieldKey);
    console.log("Value:", value);

    const updateNestedField = (field, keys, value) => {
      const lastKey = keys.pop();
      const lastObj = keys.reduce(
        (obj, key) => (obj && obj[key] ? obj[key] : null),
        field
      );
      if (lastObj && lastObj[lastKey]) {
        lastObj[lastKey].value = value;
      } else {
        console.error("Field path not found", keys, lastKey, lastObj);
      }
    };

    if (subRowIndex !== null && subSubFieldKey !== null) {
      console.log("Processing nested subrepeater field change...");
      const keys = [
        groupIndex,
        "fields",
        fieldIndex,
        "value",
        rowIndex,
        subFieldKey,
        "value",
        subRowIndex,
        subSubFieldKey,
      ];
      updateNestedField(newRepeaterFields, keys, value);
    } else if (rowIndex !== null && subFieldKey !== null) {
      console.log("Processing subrepeater field change...");
      const keys = [
        groupIndex,
        "fields",
        fieldIndex,
        "value",
        rowIndex,
        subFieldKey,
      ];
      updateNestedField(newRepeaterFields, keys, value);
    } else {
      console.log("Processing repeater field change...");
      const keys = [groupIndex, "fields", fieldIndex];
      updateNestedField(newRepeaterFields, keys, value);
    }

    // Update state and handle ACF field change
    setRepeaterFields(newRepeaterFields);
    handleAcfField(newRepeaterFields);

    // Debug logs after update
    console.log(
      "Updated Repeater Fields:",
      JSON.stringify(newRepeaterFields, null, 2)
    );
  };

  const handleWysiwygChange = (
    data,
    groupIndex,
    fieldIndex,
    rowIndex,
    subFieldKey = null,
    subRowIndex = null,
    subSubFieldKey = null
  ) => {
    const newRepeaterFields = [...repeaterFields];
    if (subRowIndex !== null && subSubFieldKey !== null) {
      if (
        newRepeaterFields[groupIndex]?.fields[fieldIndex]?.value[rowIndex]?.[
          subFieldKey
        ]?.value[subRowIndex]
      ) {
        newRepeaterFields[groupIndex].fields[fieldIndex].value[rowIndex][
          subFieldKey
        ].value[subRowIndex][subSubFieldKey].value = data;
      }
    } else if (rowIndex !== null && subFieldKey !== null) {
      if (newRepeaterFields[groupIndex]?.fields[fieldIndex]?.value[rowIndex]) {
        newRepeaterFields[groupIndex].fields[fieldIndex].value[rowIndex][
          subFieldKey
        ].value = data;
      }
    } else {
      if (newRepeaterFields[groupIndex]?.fields[fieldIndex]) {
        newRepeaterFields[groupIndex].fields[fieldIndex].value = data;
      }
    }
    setRepeaterFields(newRepeaterFields);
    handleAcfField(newRepeaterFields);
  };

  useEffect(() => {
    handleAcfField(repeaterFields);
  }, [repeaterFields]);

  const handleAddRow = (
    groupIndex,
    fieldIndex,
    parentFields = null,
    rowIndex = null,
    subFieldKey = null
  ) => {
    const newRepeaterFields = [...repeaterFields];
    let fields =
      parentFields || newRepeaterFields[groupIndex].fields[fieldIndex].value;

    let newRow = {};
    if (Array.isArray(fields) && fields.length > 0) {
      newRow = Object.keys(fields[0]).reduce((acc, key) => {
        acc[key] = {
          type: fields[0][key].type,
          value: fields[0][key].type === "subrepeater" ? [] : "",
        };
        return acc;
      }, {});
    }

    if (subFieldKey !== null && rowIndex !== null) {
      fields[rowIndex][subFieldKey].value.push(newRow);
    } else {
      fields.push(newRow);
    }

    setRepeaterFields(newRepeaterFields);
  };

  const handleRemoveRow = (
    groupIndex,
    fieldIndex,
    rowIndex,
    parentFields = null,
    subFieldKey = null,
    subRowIndex = null
  ) => {
    const newRepeaterFields = [...repeaterFields];
    let fields =
      parentFields || newRepeaterFields[groupIndex].fields[fieldIndex].value;

    if (subFieldKey !== null && subRowIndex !== null) {
      fields[rowIndex][subFieldKey].value.splice(subRowIndex, 1);
    } else {
      fields.splice(rowIndex, 1);
    }

    setRepeaterFields(newRepeaterFields);
  };

  const getInnermostValue = (obj) => {
    while (
      typeof obj === "object" &&
      obj !== null &&
      obj.hasOwnProperty("value")
    ) {
      obj = obj.value;
    }
    return obj;
  };

  const renderFieldValue = (
    field,
    groupIndex,
    fieldIndex,
    parentFields = null,
    rowIndex = null,
    subFieldKey = null,
    subRowIndex = null,
    subSubFieldKey = null
  ) => {
    const { type, value, name } = field;

    if (type === "true_false") {
      return (
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            name={name}
            role="switch"
            id={`switch-${field.name}`}
            value={value}
            defaultChecked={value}
            onChange={(e) =>
              handleFieldChange(
                e,
                groupIndex,
                fieldIndex,
                rowIndex,
                subFieldKey,
                subRowIndex,
                subSubFieldKey
              )
            }
          />
        </div>
      );
    } else if (type === "repeater" || type === "subrepeater") {
      const repeaterValue = Array.isArray(value) ? value : [];
      return (
        <Repeater
          field={field}
          groupIndex={groupIndex}
          fieldIndex={fieldIndex}
          parentFields={parentFields}
          rowIndex={rowIndex}
          subFieldKey={subFieldKey}
          handleFieldChange={handleFieldChange}
          handleAddRow={handleAddRow}
          handleRemoveRow={handleRemoveRow}
          handleWysiwygChange={handleWysiwygChange}
          renderFieldValue={renderFieldValue}
        />
      );
    } else if (type === "text" || type === "url") {
      const displayValue = getInnermostValue(value);
      return (
        <input
          className="form-control"
          type="text"
          name={name}
          defaultValue={displayValue}
          onChange={(e) =>
            handleFieldChange(
              e,
              groupIndex,
              fieldIndex,
              rowIndex,
              subFieldKey,
              subRowIndex,
              subSubFieldKey
            )
          }
        />
      );
    } else if (type === "wysiwyg") {
      const displayValue =
        typeof value === "object" && value !== null
          ? value?.value?.value
          : value;
      return (
        <CKEditor
          editor={ClassicEditor}
          data={displayValue}
          name={name}
          onReady={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            const newRepeaterFields = [...repeaterFields];
            if (subRowIndex !== null && subSubFieldKey !== null) {
              if (
                newRepeaterFields[groupIndex]?.fields[fieldIndex]?.value[
                  rowIndex
                ]?.[subFieldKey]?.value[subRowIndex]
              ) {
                newRepeaterFields[groupIndex].fields[fieldIndex].value[
                  rowIndex
                ][subFieldKey].value[subRowIndex][subSubFieldKey].value = data;
              }
            } else if (rowIndex !== null && subFieldKey !== null) {
              if (
                newRepeaterFields[groupIndex]?.fields[fieldIndex]?.value[
                  rowIndex
                ]
              ) {
                newRepeaterFields[groupIndex].fields[fieldIndex].value[
                  rowIndex
                ][subFieldKey].value = data;
              }
            } else {
              if (newRepeaterFields[groupIndex]?.fields[fieldIndex]) {
                newRepeaterFields[groupIndex].fields[fieldIndex].value = data;
              }
            }
            setRepeaterFields(newRepeaterFields);
          }}
          config={{
            toolbar: [
              "undo",
              "redo",
              "|",
              "bold",
              "italic",
              "blockQuote",
              "link",
            ],
          }}
        />
      );
    } else if (type === "file") {
      return (
        <div>
          <input
            className="form-control"
            target="_blank"
            type="file"
            name={field.type}
            rel="noopener noreferrer"
          />
        </div>
      );
    } else if (value && typeof value === "object") {
      return (
        <div>
          {Object.entries(value).map(([key, val]) => (
            <div key={key}>
              <strong>{key}:</strong>{" "}
              {val && typeof val === "object" ? JSON.stringify(val) : val}
            </div>
          ))}
        </div>
      );
    } else {
      return <p>{value}</p>;
    }
  };

  return (
    <div>
      {repeaterFields.map((acfField, groupIndex) => (
        <div key={acfField.group_name}>
          <h5>{acfField.group_name}</h5>
          {acfField.fields.map((field, fieldIndex) => (
            <div
              style={{
                overflow: "scroll",
              }}
              className="bg-white mb-1 p-2"
              key={field.name}
            >
              <h5>{field.label}</h5>
              {renderFieldValue(field, groupIndex, fieldIndex)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
