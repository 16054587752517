import React, { useContext, useEffect } from "react";
import { StoreContext } from "../../../../../context/StoreContext.jsx";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthContext";
import { Link } from "react-router-dom";
import { FaRegTrashCan } from "react-icons/fa6";
import { getDataKeyFromPathname } from "../../../../../helpers/DeterminePath.jsx";

const flattenObject = (obj, parentKey = "", res = {}) => {
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;
    const propName = parentKey ? `${parentKey}.${key}` : key;
    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

export default function Table() {
  const { data, handleEntryId, selectedFields, removeEntity } =
    useContext(StoreContext);

  const { user } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  // const dataKey = getDataKeyFromPathname(location.pathname);
  // console.log("data", data);

  // const dataMap = {
  //   products,
  //   orders,
  //   customers: user,
  //   categories,
  //   tags,
  //   attributes,
  //   terms,
  // };

  // const removeActionMap = {
  //   attributes: removeAttribute,
  //   terms: removeTerm,
  // };

  // const data = dataMap[dataKey] || [];
  // const removeAction = removeActionMap[dataKey] || (() => {});

  console.log("data", data);

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th></th>
            {selectedFields?.map((field) => (
              <th key={field.id}>{field.attributes.title}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 && (
            <tr>
              <td colSpan={selectedFields.length + 2} className="text-center">
                No found
              </td>
            </tr>
          )}
          {data?.map((item, index) => {
            console.log("item", item);
            const flattenedItem = flattenObject(item);
            return (
              <tr key={index}>
                <td className="align-middle">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    onChange={() => handleEntryId(item.id)}
                  />
                </td>
                {selectedFields?.map((field) => {
                  const fieldValue = flattenedItem[field.attributes.title];
                  return (
                    <td key={field.id} className="align-middle">
                      {field.attributes.title === "images.src" ? (
                        <img
                          src={flattenedItem["images.src"]}
                          alt="Avatar"
                          className="rounded-circle me-2"
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        fieldValue
                      )}
                    </td>
                  );
                })}
                <td className="align-middle">
                  <Link
                    to={`${location.pathname}/${
                      location.pathname.includes("attributes")
                        ? "terms/" + item.id
                        : item.id
                    }`}
                  >
                    <FaRegEdit
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                    />
                  </Link>
                  <FaRegTrashCan
                    onClick={() => removeEntity(location.pathname, item.id)}
                    style={{ cursor: "pointer" }}
                    className="ms-2"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
