const getDataKeyFromPathname = (pathname) => {
  if (typeof pathname !== "string") {
    throw new TypeError("The pathname must be a string.");
  }

  const pathParts = pathname.split("/").filter((part) => part);
  if (pathParts.length === 1 && pathParts[0] === "products") {
    return "products";
  }
  if (pathParts.length > 2) {
    return pathParts.slice(2).join("/"); // Use the parts after 'products/submenu' as the key
  }
  return null;
};

export { getDataKeyFromPathname };
