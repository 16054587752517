import React from "react";
import { IoClose } from "react-icons/io5";
import { useLocation, Link } from "react-router-dom";
export default function ComposerHeader() {
  const location = useLocation();
  const id = location.pathname.split("/")[4];

  // get id from /contacts/1

  return (
    <div className="p-1 bg-gray-100 d-flex justify-content-between align-items-center">
      Nieuw bericht
      <Link to={`/crm/floowd/contacts/${id}#correspondence`}>
        <IoClose size={24} />
      </Link>
    </div>
  );
}
