"use client";

import React, { useState, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useParams } from "react-router-dom";
import { FaUserPlus, FaUsers, FaArrowLeft } from "react-icons/fa";
import AddressSection from "./address/Address";
import NameSection from "./name/Name";
import SelectionSection from "./selection/Selection";
import { QuotationContext } from "../../../../../../context/QuotationContext";
import { Button, Modal, Form, Dropdown } from "react-bootstrap";
import {
  CreateCustomer,
  DeleteCustomer,
} from "../../../../../../helpers/Customers";
import { IoWarningOutline } from "react-icons/io5";

const customerSelectionSchema = z.object({
  firstname: z.string().min(1, "First name is required").optional(),
  lastname: z.string().min(1, "Last name is required").optional(),
  email: z.string().email("Enter a valid email").optional(),
  phone: z.string().min(1, "Phone number is required").optional(),
  country: z.string().min(1, "Country is required").optional(),
  billingaddress: z.string().min(1, "Address is required").optional(),
  billingstreetnumber: z
    .string()
    .min(1, "Street number is required")
    .optional(),
  billingzipcode: z.string().min(1, "Zipcode is required").optional(),
  billingcity: z.string().min(1, "City is required").optional(),
  billingphone: z.string().min(1, "Phone number is required").optional(),
  selectedCustomer: z.string().optional(),
});

export default function CustomerSection() {
  const [open, setOpen] = useState(false);
  const [createNewCustomer, setCreateNewCustomer] = useState(false);
  const [selectExistingCustomer, setSelectExistingCustomer] = useState(false);
  const [error, setError] = useState("");
  console.log("error", error);

  const params = useParams();
  const { customers, quotation, AddExistingCustomerToQuotation } =
    useContext(QuotationContext);
  console.log("quotation", quotation);

  const methods = useForm({
    resolver: zodResolver(customerSelectionSchema),
  });

  const onSubmit = async (data) => {
    console.log("Form Data:", data);
    try {
      if (createNewCustomer) {
        const customerResponse = await CreateCustomer(data, params.id);
        if (customerResponse.error) {
          await setError({
            ...customerResponse,
          });
        } else {
          setOpen(false);
        }
      } else {
        AddExistingCustomerToQuotation(data.selectedCustomer, params.id);
      }
    } catch (error) {
      console.error("Failed to create or add customer:", error);
    }
  };

  const handleDelete = () => {
    DeleteCustomer(quotation.user.id, quotation.id);
  };

  const resetSelection = () => {
    setCreateNewCustomer(false);
    setSelectExistingCustomer(false);
  };

  return (
    <div className="pt-2 pb-4">
      <div className="d-flex justify-content-between align-items-center">
        <h3>Customers</h3>
        {quotation.user ? (
          <div>
            <Button variant="danger" onClick={handleDelete}>
              Delete Customer
            </Button>
          </div>
        ) : (
          <div>
            <Button
              variant="outline-primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              Add a new customer
            </Button>
          </div>
        )}
      </div>
      <div className="bg-white mt-2">
        {quotation.user ? (
          <div className=" p-2">
            <div className="d-flex gap-2">
              <input
                defaultValue={quotation?.user?.first_name}
                placeholder="First name"
                className="form-control"
              />
              <input
                defaultValue={quotation?.user?.last_name}
                placeholder="Last name"
                className="form-control"
              />
            </div>
            <div className="mt-2">
              <input
                defaultValue={quotation?.user?.email}
                placeholder="E-mail address"
                className="form-control"
              />
            </div>
            <div className="mt-2">
              <input
                defaultValue={quotation?.user?.phone}
                placeholder="Phone number"
                className="form-control"
              />
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-column gap-1 p-3">
            <h3>Start adding a customer</h3>
            <Button variant="outline-primary" onClick={() => setOpen(true)}>
              Add a new customer
            </Button>
          </div>
        )}
      </div>
      {quotation.customer && (
        <div className="p-3 mt-2 bg-white">
          <Dropdown>
            <Dropdown.Toggle variant="outline-primary" className="w-100">
              {quotation.customer.country || "Select a country"}
            </Dropdown.Toggle>
            <Dropdown.Menu>{/* Dropdown items */}</Dropdown.Menu>
          </Dropdown>
          <div className="mt-2">
            <input
              defaultValue={quotation.customer.company}
              placeholder="Company"
              className="form-control"
            />
          </div>
          <div className="mt-2">
            <input
              defaultValue={quotation.customer.address1}
              placeholder="Address"
              className="form-control"
            />
          </div>
          <div className="d-flex gap-2 mt-2">
            <input
              defaultValue={quotation.customer.zipcode}
              placeholder="Zipcode"
              className="form-control"
            />
            <input
              defaultValue={quotation.customer.city}
              placeholder="City"
              className="form-control"
            />
          </div>
          <div className="mt-2">
            <input
              defaultValue={quotation.customer.phone}
              placeholder="Phone number"
              className="form-control"
            />
          </div>
        </div>
      )}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={open}
        onHide={() => {
          setOpen(false);
          resetSelection();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Add a customer</Modal.Title>
        </Modal.Header>
        <Modal.Body className="ps-2 pt-2 pb-4 pe-2">
          {!createNewCustomer && !selectExistingCustomer ? (
            <>
              <div className="d-flex gap-4">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className="bg-light p-4 rounded-lg flex-grow-1 d-flex flex-column align-items-center"
                  onClick={() => setCreateNewCustomer(true)}
                >
                  <FaUserPlus size={32} />
                  <p className="mt-2">Add New Customer</p>
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className="bg-light p-4 flex-grow-1 d-flex flex-column align-items-center"
                  onClick={() => setSelectExistingCustomer(true)}
                >
                  <FaUsers size={32} />
                  <p className="mt-2">Select Existing Customer</p>
                </div>
              </div>
            </>
          ) : (
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Button
                  type="button"
                  variant="outline-primary"
                  className="mb-2"
                  onClick={resetSelection}
                >
                  <FaArrowLeft className="mr-2" /> Back
                </Button>
                {createNewCustomer && (
                  <>
                    {error && (
                      <>
                        <div className="d-flex gap-2 border-danger border p-1 align-items-center">
                          <IoWarningOutline size={32} className="text-danger" />
                          {error?.error?.message ? (
                            <p>
                              {error?.error?.details?.message} <br />
                              Do you want to add this customer anyway?
                            </p>
                          ) : (
                            <p>
                              {" "}
                              Something went wrong. Do you want to try again?
                            </p>
                          )}
                        </div>
                        <button
                          className="btn btn-info text-white mt-1"
                          onClick={() => setError("")}
                        >
                          Add existing customer
                        </button>
                      </>
                    )}
                    <NameSection />
                    <AddressSection />
                    <Button type="submit" className="mt-2">
                      Add new customer
                    </Button>
                  </>
                )}
                {selectExistingCustomer && (
                  <>
                    <SelectionSection customers={customers} />
                    <Button type="submit">Add existing customer</Button>
                  </>
                )}
              </Form>
            </FormProvider>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
