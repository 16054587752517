import React, { useContext } from "react";
import { CrmContext } from "../../../../../../context/CrmContext";

export default function ServiceForm() {
  const { contact } = useContext(CrmContext);

  return (
    <div className="row">
      <h3 className="fs-4">About this service</h3>
      <form className="row">
        <div className="col-12">
          <label htmlFor="description" className="form-label">
            Ticket description
          </label>
          <textarea
            className="form-control"
            id="description"
            defaultValue={contact?.description}
          />
        </div>
        <div className="col-12 mt-1">
          <label htmlFor="owner" className="form-label">
            Ticket owner
          </label>
          <select className="form-select" id="owner">
            {[contact?.owner].map((owner) => (
              <>
                {owner === null ? (
                  <option value="1">No owner</option>
                ) : (
                  <option value="1">
                    {owner?.first_name + " " + owner?.last_name ||
                      owner?.username ||
                      "No owner"}
                  </option>
                )}
              </>
            ))}
          </select>
        </div>
        <div className="col-12 mt-1">
          <label htmlFor="date" className="form-label">
            Date created
          </label>
          <input type="date" className="form-control" id="date" />
        </div>
        <div className="col-12 mt-1">
          <label htmlFor="priority" className="form-label">
            Priority
          </label>
          <select className="form-select" id="priority">
            <option value="1">Low</option>
            <option value="2">Medium</option>
            <option value="3">High</option>
          </select>
        </div>
      </form>
    </div>
  );
}
