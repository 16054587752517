import React from "react";

export default function Details({ order, statuses, setStatus }) {
  return (
    <div className="container p-2 bg-white">
      <div>
        <h5 className="h5">Order #{order.id} details</h5>
        <p className="text-muted">
          Payment via {order.payment_method}, Paid on {order.date_paid} @{" "}
          {order.date_paid}. Customer IP: {order.customer_ip_address}
        </p>
      </div>
      <div className="row">
        <div className="col-md-4 mb-2">
          <div className="p-2 bg-light rounded">
            <h6 className="h6 mb-2">General</h6>
            <div className="mb-2">
              <label className="form-label">Date created:</label>
              <input
                type="date"
                className="form-control"
                value={order.date_created}
                readOnly
              />
            </div>
            <div className="mb-2">
              <label className="form-label">Time:</label>
              <input
                type="time"
                className="form-control"
                value={order.date_created.split("T")[1]}
                readOnly
              />
            </div>
            <div className="mb-2">
              <label className="form-label">Status:</label>
              <select
                className="form-select"
                defaultValue={"wc-" + order.status}
                onChange={(e) =>
                  setStatus(
                    e.target.value
                      .toLowerCase()
                      .replace(" ", "-")
                      .replace("wc-", "")
                  )
                }
              >
                {/* {Object.keys(statuses).map((key) => (
                  <option key={key} value={key}>
                    {key.replace("wc-", "")}
                  </option>
                ))} */}
              </select>
            </div>
            <div className="mb-2">
              <label className="form-label">Customer:</label>
              <p className="text-primary cursor-pointer mb-1">
                Profile → View other orders →
              </p>
              <input
                type="text"
                className="form-control"
                value={`${order.billing.first_name} ${order.billing.last_name} (#${order.customer_id} – ${order.billing.email})`}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-2">
          <div className="p-2 bg-light rounded">
            <h6 className="h6 mb-2">Billing</h6>
            <p className="mb-2">
              {order.billing.first_name} {order.billing.last_name}
            </p>
            <p className="mb-2">{order.billing.address_1}</p>
            <p className="mb-2">{order.billing.address_2}</p>
            <p className="mb-2">{order.billing.city}</p>
            <p className="mb-2">{order.billing.postcode}</p>
            <p className="mb-2">
              Email address:{" "}
              <a
                href={`mailto:${order.billing.email}`}
                className="text-primary"
              >
                {order.billing.email}
              </a>
            </p>
            <p className="mb-2">
              Phone:{" "}
              <a href={`tel:${order.billing.phone}`} className="text-primary">
                {order.billing.phone}
              </a>
            </p>
          </div>
        </div>
        <div className="col-md-4 mb-2">
          <div className="p-2 bg-light rounded">
            <h6 className="h6 mb-2">Shipping</h6>
            <p className="mb-2">
              {order.shipping.first_name} {order.shipping.last_name}
            </p>
            <p className="mb-2">{order.shipping.address_1}</p>
            <p className="mb-2">{order.shipping.address_2}</p>
            <p className="mb-2">{order.shipping.city}</p>
            <p className="mb-2">{order.shipping.postcode}</p>
            <p className="mb-2">
              Phone:{" "}
              <a href={`tel:${order.shipping.phone}`} className="text-primary">
                {order.shipping.phone}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
