import React, { useState, useContext, useRef } from "react";
import { StoreContext } from "../../../../../context/StoreContext";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./datefilter.scss";

const DateFilter = () => {
  const { setDateRange } = useContext(StoreContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);

  const handleApply = () => {
    if (startDate && endDate) {
      setDateRange({
        start: startDate.format("YYYY-MM-DD"),
        end: endDate.format("YYYY-MM-DD"),
      });
      setShowPicker(false);
    }
  };

  const handleTogglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleClickOutside = (event) => {
    setShowPicker(false);
  };

  return (
    <div className="date-filter" ref={pickerRef}>
      <div className="position-relative">
        <button
          className="btn btn-outline-primary"
          onClick={handleTogglePicker}
        >
          Date range
        </button>
      </div>
      {showPicker && (
        <div
          className="date-filter-content bg-white p-2 mt-1"
          style={{
            position: "absolute",
            zIndex: 100,
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h6 className="date-filter-title">Select a date range</h6>
          <DateRangePicker
            startDate={startDate}
            startDateId="your_unique_start_date_id"
            endDate={endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            isOutsideRange={() => false}
            numberOfMonths={1} // Show one month at a time
          />
          <div className="d-flex justify-content-end mt-3">
            <button className="btn btn-primary" onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateFilter;
