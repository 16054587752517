import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function CorrespondenceActions() {
  const location = useLocation();
  const id = location.pathname.split("/")[4];
  console.log("id", id);
  return (
    <div>
      <Link
        to={`/crm/floowd/contacts/${id}#correspondence&compose=new`}
        className="btn btn-outline-info"
      >
        New Email
      </Link>
    </div>
  );
}
