import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { QuotationContext } from "../../../../../../context/QuotationContext";

export default function Questions({ question, index }) {
  console.log("question", question);
  const { id } = useParams();
  const { handleInputChange } = useContext(QuotationContext);

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.stopPropagation();
    }
  };

  return (
    <textarea
      className="form-control"
      defaultValue={question.title}
      onChange={(e) => handleInputChange(question.id, e, id)}
      placeholder={`Question #${index + 1}`}
      onKeyDown={handleKeyDown}
    />
  );
}
