import { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Accordion } from "react-bootstrap";
import { QuotationContext } from "../../../../../../context/QuotationContext";
import { StoreContext } from "../../../../../../context/StoreContext";

export default function ProductType({ question, questionnaireId }) {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const {
    questions,
    handleAddOptionToQuestion,
    handleOptionChange,
    removeOptionFromQuestion,
  } = useContext(QuotationContext);
  const { products } = useContext(StoreContext);

  const [filteredProducts, setFilteredProducts] = useState(products || []);
  const [selectedProduct, setSelectedProduct] = useState("");

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    // Ensure products is an array using Array.isArray and providing a default empty array if not
    const result = Array.isArray(products)
      ? products.filter((product) =>
          product.name.toLowerCase().includes(lowercasedSearch)
        )
      : [];
    setFilteredProducts(result);
  }, [search, products]);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = () => {
    setOpen(false);
    handleAddOptionToQuestion(
      question.id,
      questionnaireId,
      "product",
      selectedProduct
    );
  };

  return (
    <div>
      <Button variant="outline-warning" onClick={() => setOpen(true)}>
        Select a product
      </Button>

      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select a product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Search for a product"
            onChange={handleInputChange}
          />
          {filteredProducts.map((product, index) => (
            <Accordion defaultActiveKey="0" className="my-2" key={index}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <img
                    src={product?.images[0]?.src}
                    alt="Product"
                    className="img-thumbnail me-2"
                    style={{ width: "40px", height: "40px" }}
                  />
                  {product.name}
                </Accordion.Header>
                <Accordion.Body>
                  {product?.variations_detail?.length > 0 ? (
                    product.variations_detail.map((variation, vIndex) => (
                      <Form.Check
                        type="checkbox"
                        id={`variation-${variation.id}`}
                        label={`${product.name} - ${Object.entries(
                          variation.attributes
                        ).join(" ")}`}
                        key={vIndex}
                        defaultChecked
                      />
                    ))
                  ) : (
                    <p>No variations</p>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
