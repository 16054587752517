import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { StoreContext } from "../../../../../../context/StoreContext";

export default function CreateEntry({ attributeId, ...props }) {
  const { addTerm } = useContext(StoreContext);

  const handleAddTerm = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const term = formData.get("term");
    const response = await addTerm(term, term, attributeId);
    props.onTermAdded(response);
    form.reset();
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add new entry
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Enter a name for the new attribute term:
        <form onSubmit={handleAddTerm} className="mt-1">
          <input type="text" name="term" className="form-control" />
          <Button type="submit" className="mt-1">
            Add value
          </Button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
