import React from "react";
import moment from "moment";
import OrderActions from "./OrderActions";

export default function Sidebar({ order }) {
  return (
    <div className="sidebar">
      <div className="p-1 rounded-1 card">
        <div className="card-header">
          <p> Order actions </p>
        </div>
        <div className="card-body">
          <OrderActions />
        </div>
        <div className="card-footer">
          <dic className="d-flex justify-content-between">
            <button className="btn btn-outline-danger">Delete</button>
            <button className="btn btn-primary">Update</button>
          </dic>
        </div>
      </div>
    </div>
  );
}
