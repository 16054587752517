import React from "react";
import Table from "../components/dashboard/utils/listview/tables/Table";
import Actions from "../components/dashboard/utils/listview/actions/Actions.jsx";

export default function Orders() {
  return (
    <div>
      <h1>Orders</h1>
      <div className="mb-2">
        <Actions />
      </div>
      <Table />
    </div>
  );
}
