import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../../../../../../context/StoreContext.jsx";
import Select from "react-select";
import { useParams } from "react-router-dom";
import Loader from "../../../../../../components/utils/Loader.jsx";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import CreateEntry from "./CreateEntry.jsx";

export default function Attributes({ tab, product }) {
  const { id } = useParams();
  const { attributes, fetchTerms, updateProductAttributes } =
    useContext(StoreContext);
  const [usedForVariations, setUsedForVariations] = useState({});
  const [visibleProductPage, setVisibleProductPage] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [currentAttributeId, setCurrentAttributeId] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedTerms, setSelectedTerms] = useState({});
  const [terms, setTerms] = useState({});
  const [loading, setLoading] = useState(false);
  const [collapseStates, setCollapseStates] = useState({});

  const toggleCollapse = (attributeId) => {
    setCollapseStates((prev) => ({
      ...prev,
      [attributeId]: !prev[attributeId],
    }));
  };

  const fetchAttributeTerms = async (attributeId) => {
    const terms = await fetchTerms(attributeId);
    setTerms((prev) => ({ ...prev, [attributeId]: terms }));
  };

  useEffect(() => {
    if (selectedAttributes.length > 0) {
      selectedAttributes.forEach((attribute) => {
        fetchAttributeTerms(attribute.value);
      });
    }
  }, [selectedAttributes]);

  const handleUpdateProductWithVariations = async () => {
    setLoading(true);
    const attributesToUpdate = selectedAttributes.map((attribute) => ({
      id: attribute.value,
      options: selectedTerms[attribute.value]?.map((term) => term.value) || [],
      visible:
        visibleProductPage[attribute.value] ||
        product.attributes.some((attr) => attr.id === attribute.value),
      variation:
        usedForVariations[attribute.value] ||
        product.attributes.some((attr) => attr.id === attribute.value),
    }));

    try {
      await updateProductAttributes(id, { attributes: attributesToUpdate });
    } catch (error) {
      console.error("Error updating product variations:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInitialAttributeTerms = async () => {
    if (product?.attributes.length > 0) {
      const initialAttributes = product.attributes.map((attr) => ({
        value: attr.id,
        label: attr.name,
      }));
      setSelectedAttributes(initialAttributes);
      initialAttributes.forEach((attr) => fetchAttributeTerms(attr.value));
      // Set initial state for checkboxes and selected terms
      const initialVisible = {};
      const initialVariations = {};
      const initialTerms = {};
      product.attributes.forEach((attr) => {
        initialVisible[attr.id] = attr.visible;
        initialVariations[attr.id] = attr.variation;
        initialTerms[attr.id] = attr.options.map((option) => ({
          value: option,
          label: option,
        }));
      });
      setVisibleProductPage(initialVisible);
      setUsedForVariations(initialVariations);
      setSelectedTerms(initialTerms);
    }
  };

  useEffect(() => {
    fetchInitialAttributeTerms();
  }, [product]);

  const attributeOptions = attributes.map((attribute) => ({
    value: attribute.id,
    label: attribute.name,
    isDisabled: product.attributes.some(
      (productAttribute) => productAttribute.id === attribute.id
    ),
  }));

  const termOptions = (attributeId) =>
    terms[attributeId]?.map((term) => ({
      value: term.name,
      label: term.name,
    })) || [];

  const handleTermAdded = async (addedTerm) => {
    console.log("addedTerm", addedTerm);
    setTerms((prev) => ({
      ...prev,
      [currentAttributeId]: [...(prev[currentAttributeId] || []), addedTerm],
    }));
    setSelectedTerms((prev) => ({
      ...prev,
      [currentAttributeId]: [
        ...(prev[currentAttributeId] || []),
        { value: addedTerm.name, label: addedTerm.name },
      ],
    }));

    // add Term to attribute options and to api
    updateProductAttributes(id, {
      attributes: [
        ...product.attributes,
        {
          id: currentAttributeId,
          name: addedTerm.name,
          options: [
            ...product.attributes.find((attr) => attr.id === currentAttributeId)
              .options,
            addedTerm.name,
          ],
          visible: true,
          variation: true,
        },
      ],
    });
  };

  return (
    <div>
      <div className="form-group mb-2">
        <div className="row">
          <div className="col-3">
            <button className="btn btn-outline-primary w-100">Add new</button>
          </div>
          <div className="col-9">
            <Select
              isMulti
              value={selectedAttributes}
              onChange={(selectedOption) => {
                setSelectedAttributes(selectedOption);
              }}
              options={attributeOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select attributes"
            />
          </div>
        </div>
        {selectedAttributes.length > 0 && (
          <div className="mt-2">
            {selectedAttributes.map((attribute, index) => (
              <div key={index} className="">
                <div
                  className="d-flex justify-content-between align-items-center border-top border-1 p-1"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#attributeDetails${attribute.value}`}
                  aria-expanded="false"
                  aria-controls={`attributeDetails${attribute.value}`}
                  onClick={() => toggleCollapse(attribute.value)}
                >
                  <strong>{attribute.label}</strong>
                  <div className="d-flex gap-2 align-items-center">
                    <span
                      onClick={() =>
                        setSelectedAttributes((prev) =>
                          prev.filter((attr) => attr.value !== attribute.value)
                        )
                      }
                      className="text-danger"
                    >
                      Remove
                    </span>
                    {collapseStates[attribute.value] ? (
                      <FaAngleUp />
                    ) : (
                      <FaAngleDown />
                    )}
                  </div>
                </div>
                <div
                  className={`collapse mt-2 ${
                    collapseStates[attribute.value] ? "show" : ""
                  }`}
                  id={`attributeDetails${attribute.value}`}
                >
                  <div className="row pb-2">
                    <div className="col-6">
                      <div className="form-group mt-1">
                        <input
                          type="checkbox"
                          name={`visible_product_page_${attribute.value}`}
                          id={`visible_product_page_${attribute.value}`}
                          className="form-check-input"
                          onChange={(e) => {
                            setVisibleProductPage((prev) => ({
                              ...prev,
                              [attribute.value]: e.target.checked,
                            }));
                          }}
                          checked={visibleProductPage[attribute.value] || false}
                        />
                        <label
                          htmlFor={`visible_product_page_${attribute.value}`}
                          className="ms-1"
                        >
                          Visible on product page
                        </label>
                      </div>
                      <div className="form-group mt-1">
                        <input
                          type="checkbox"
                          name={`used_for_variations_${attribute.value}`}
                          id={`used_for_variations_${attribute.value}`}
                          className="form-check-input"
                          onChange={(e) => {
                            setUsedForVariations((prev) => ({
                              ...prev,
                              [attribute.value]: e.target.checked,
                            }));
                          }}
                          checked={usedForVariations[attribute.value] || false}
                        />
                        <label
                          htmlFor={`used_for_variations_${attribute.value}`}
                          className="ms-1"
                        >
                          Used for variations
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <Select
                        value={selectedTerms[attribute.value] || []}
                        options={termOptions(attribute.value)}
                        isMulti
                        onChange={(e) =>
                          setSelectedTerms((prev) => ({
                            ...prev,
                            [attribute.value]: e,
                          }))
                        }
                      />
                      <div className="d-flex justify-content-end">
                        <button
                          onClick={() => {
                            setCurrentAttributeId(attribute.value);
                            setModalShow(true);
                          }}
                          className="btn btn-outline-primary mt-2"
                        >
                          Create value
                        </button>
                        <CreateEntry
                          attributeId={currentAttributeId}
                          show={modalShow}
                          onTermAdded={handleTermAdded}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="form-group mb-2">
        <button
          onClick={handleUpdateProductWithVariations}
          className="btn btn-outline-primary w-100"
        >
          {loading ? (
            <div className="d-flex gap-1 align-items-center justify-content-center">
              <p> Saving attributes </p>
              <Loader />
            </div>
          ) : (
            "Save attributes"
          )}
        </button>
      </div>
    </div>
  );
}
