import React, { useContext, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { CrmContext } from "../../../../../../context/CrmContext";
import { Offcanvas } from "react-bootstrap";

import EntityPane from "../EntityPane/EntityPane.jsx";

export default function Deals() {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const { contact, company } = useContext(CrmContext);

  const isContact = location.pathname === `/contacts/${id}`;
  const isCompany = location.pathname.includes("companies");
  const isService = location.pathname.includes("service");
  const isDeals = location.pathname.includes("deals");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="row">
      <div className="col-12 bg-white p-2">
        <div className="d-flex justify-content-between">
          <h3 className="fs-5">Contacts</h3>
          <button onClick={handleShow} className="btn btn-outline-info">
            Add Contact
          </button>
          <Offcanvas placement={"end"} show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton className="bg-info text-white">
              <Offcanvas.Title>Add contact</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <EntityPane />
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <table className="table mt-1">
          <thead>
            <tr>
              <th scope="col">Name </th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
            </tr>
          </thead>
          {isContact && (
            <tbody>
              {contact?.contacts.map((contact) => (
                <tr key={contact.id}>
                  <td>{contact?.name}</td>
                  <td>{contact?.email}</td>
                  <td>{contact?.phone}</td>
                </tr>
              ))}
            </tbody>
          )}
          {isCompany && (
            <tbody>
              {company?.contacts.map((contact) => (
                <tr key={contact.id}>
                  <td>
                    <Link to={`/contacts/${contact?.id}`}>
                      {contact?.first_name + " " + contact?.last_name}
                    </Link>
                  </td>
                  <td>{contact?.email}</td>
                  <td>{contact?.phone}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
