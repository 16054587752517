import React, { useState, useContext } from "react";
import moment from "moment";
import { StoreContext } from "../../../../../context/StoreContext";
import MediaLibrary from "../../../../../components/dashboard/utils/media/MediaLibrary.jsx";
import { IoClose } from "react-icons/io5";

export default function Sidebar({ categories, product }) {
  const {
    repeaterFieldsAcf,
    handleUpdateProductAcf,
    isPending,
    handleUpdateProductImage,
    // handleUpdateProductGalleryImages,
  } = useContext(StoreContext);
  const [modalShow, setModalShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(product.images[0]);
  const [galleryImages, setGalleryImages] = useState(
    product?.images?.length > 1 ? product.images.slice(1) : []
  );
  const [multipleSelection, setMultipleSelection] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);

  console.log("repeaterFieldsAcf", repeaterFieldsAcf);

  const handleUpdate = async () => {
    try {
      await handleUpdateProductAcf(repeaterFieldsAcf);

      // Combine selected image and gallery images into one array
      const allImages = [selectedImage, ...galleryImages];

      await handleUpdateProductImage(product.id, allImages);
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  const handleAddGalleryImages = (mediaArray) => {
    setGalleryImages([...galleryImages, ...mediaArray]);
    setShowGalleryModal(false);
  };

  const handleRemoveGalleryImage = (index) => {
    setGalleryImages(galleryImages.filter((_, i) => i !== index));
  };

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        height: "100vh",
        overflowY: "auto",
      }}
      className="sidebar"
    >
      <div className="p-1 rounded-1 card">
        <div className="card-header">
          <p> Publish </p>
        </div>
        <div className="card-body">
          <p>Status: {product?.status}</p>
          <p>Published on: {moment(product?.date).format("MMMM Do YYYY")}</p>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-between">
            <button className="btn btn-outline-danger">Delete</button>
            <button onClick={handleUpdate} className="btn btn-primary">
              {isPending ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </div>
      <div className="p-1 rounded-1 card mt-2">
        <div className="card-header">
          <p> Product image </p>
        </div>
        <div className="card-body">
          <div className="d-flex flex-column">
            <img
              style={{
                width: "100%",
                height: "225px",
                objectFit: "contain",
              }}
              src={
                selectedImage?.src ||
                selectedImage?.source_url ||
                "https://via.placeholder.com/225"
              }
              alt={product?.name}
            />
            <button
              onClick={() => {
                setMultipleSelection(false);
                setModalShow(true);
              }}
              className="btn btn-outline-primary mt-1"
            >
              {selectedImage ? "Change image" : "Add image"}
            </button>
            <MediaLibrary
              show={modalShow}
              onHide={() => setModalShow(false)}
              onInsert={(media) => {
                setSelectedImage(media[0]);
                setModalShow(false);
              }}
              multiple={multipleSelection}
            />
          </div>
        </div>
      </div>
      <div className="p-1 rounded-1 card mt-2">
        <div className="card-header">
          <p> Product gallery </p>
        </div>
        <div className="card-body">
          <div className="row mt-1">
            {galleryImages.length > 0 ? (
              galleryImages.map((image, index) => (
                <div key={index} className="col-4 position-relative">
                  <img
                    style={{
                      width: "100%",
                      height: "100px",
                      objectFit: "contain",
                      backgroundColor: "#f8f9fa",
                      padding: "5px",
                    }}
                    src={image.src || image.source_url}
                    alt={`Gallery image ${index + 1}`}
                  />
                  <span
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: 0,
                    }}
                    onClick={() => handleRemoveGalleryImage(index)}
                    className="ms-2 bg-danger text-white rounded badge"
                  >
                    <IoClose />
                  </span>
                </div>
              ))
            ) : (
              <p>No gallery images</p>
            )}
          </div>
          <button
            onClick={() => {
              setMultipleSelection(true);
              setShowGalleryModal(true);
            }}
            className="btn btn-outline-primary mt-1"
          >
            Add gallery images
          </button>
          <MediaLibrary
            show={showGalleryModal}
            onHide={() => setShowGalleryModal(false)}
            onInsert={handleAddGalleryImages}
            multiple={multipleSelection}
          />
        </div>
      </div>
      <div className="p-1 rounded-1 card mt-2">
        <div className="card-header">
          <p> Categories </p>
        </div>
        <div className="card-body">
          {categories?.map((category, index) => (
            <div className={`d-flex gap-1 mb-1`} key={index}>
              <input
                defaultChecked={product.categories.some(
                  (productCategory) => productCategory.id === category.id
                )}
                type="checkbox"
                className="form-check-input"
                id={category.name}
                name={category.name}
              />
              <label htmlFor={category.name}>{category.name}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="p-1 rounded-1 card mt-2">
        <div className="card-header">
          <p> Merk </p>
        </div>
        <div className="card-body">
          {product?.custom_taxonomies?.merk?.map((taxonomy, index) => (
            <div className={`d-flex gap-1 mb-1`} key={index}>
              <input
                type="checkbox"
                className="form-check-input"
                id={taxonomy.name}
                name={taxonomy.name}
              />
              <label htmlFor={taxonomy.name}>{taxonomy.name}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
