import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { CrmContext } from "../../../../../../context/CrmContext";

export default function Deals() {
  const location = useLocation();
  const { id } = useParams();
  const { contact, company } = useContext(CrmContext);

  const isContact = location.pathname === `/contacts/${id}`;
  const isCompany = location.pathname.includes("companies");
  const isService = location.pathname.includes("service");
  const isDeals = location.pathname.includes("deals");

  return (
    <div className="row">
      <div className="col-12 bg-white p-2">
        <h3 className="fs-5">Deals</h3>
        <table className="table mt-1">
          <thead>
            <tr>
              <th scope="col">Deal</th>
              <th scope="col">Waarde</th>
              <th scope="col">Fase</th>
            </tr>
          </thead>
          {isContact && (
            <tbody>
              {contact?.deals?.map((deal) => (
                <tr key={deal?.id}>
                  <Link to={`/contacts/submenu/deals/${deal?.id}`}>
                    <td>{deal?.name}</td>
                  </Link>
                  <td>{deal?.amount}</td>
                  <td>{deal?.stage}</td>
                </tr>
              ))}
            </tbody>
          )}
          {isCompany && (
            <tbody>
              {company?.deals?.map((deal) => (
                <tr key={deal?.id}>
                  <Link to={`/contacts/submenu/deals/${deal?.id}`}>
                    <td>{deal?.name}</td>
                  </Link>
                  <td>{deal?.amount}</td>
                  <td>{deal?.stage}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
