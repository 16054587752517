import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MdDragIndicator } from "react-icons/md";

const SortableOptions = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    textAlign: "left",
    listStyle: "none",
    backgroundColor: isDragging ? "#e9ecef" : "white",
    boxShadow: isDragging ? "0px 0px 15px rgba(0,0,0,0.2)" : "none",
    transform: CSS.Transform.toString(transform),
    transition: transition || "transform 150ms cubic-bezier(0.25, 1, 0.5, 1)",
  };

  return (
    <li ref={setNodeRef} style={style} {...attributes}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "3px",
          paddingBottom: "3px",
        }}
      >
        <div
          style={{
            cursor: "grab",
            padding: "5px",
            backgroundColor: "#f8f9fa",
            borderRadius: "5px",
            marginRight: "5px",
            marginLeft: "10px",
          }}
          {...listeners}
        >
          <MdDragIndicator />
        </div>
        <div className="w-100">{children}</div>
      </div>
    </li>
  );
};

export default SortableOptions;
