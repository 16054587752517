import React from "react";
import TabContent from "./TabContent.jsx";
import "./tabview.scss";

export default function TabView({ tabs, product }) {
  // console.log("tabs", tabs);
  return (
    <>
      <nav>
        <div
          style={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            borderBottom: "1px solid #ddd",
          }}
          className="nav nav-tabs"
          id="nav-tab"
          role="tablist"
        >
          {tabs.tabs.map((tab, index) => (
            <button
              key={index}
              className={`nav-link ${index === 0 ? "active" : ""}`}
              id={`nav-tab-${index}`}
              data-bs-toggle="tab"
              data-bs-target={`#nav-tab-content-${index}`}
              type="button"
              role="tab"
              aria-controls={`nav-tab-content-${index}`}
              aria-selected={index === 0 ? "true" : "false"}
              style={{ display: "inline-block", marginRight: "0.5rem" }}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        {tabs.tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
            id={`nav-tab-content-${index}`}
            role="tabpanel"
            aria-labelledby={`nav-tab-${index}`}
            tabIndex="0"
          >
            <TabContent
              product={product}
              tab={tab}
              fields={tab.woocommercefield}
            />
          </div>
        ))}
      </div>
    </>
  );
}
