import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import LoadingIndicator from "../components/utils/LoadingIndicator";

const LoggedInRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingIndicator />;
  }

  return !user ? children : <Navigate to="/" />;
};

export default LoggedInRoute;
