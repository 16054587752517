import React, { useContext } from "react";
import { CrmContext } from "../../../../../../context/CrmContext";

export default function DealForm() {
  const { company } = useContext(CrmContext);

  return (
    <div className="row">
      <h3 className="fs-4">About this deal</h3>
      <form className="row">
        <div className="col-12">
          <div className="form-group mt-1">
            <label htmlFor="owner" className="form-label">
              Owner
            </label>
            <select className="form-select" id="owner">
              {[company?.owner].map((owner) => (
                <>
                  {owner === null ? (
                    <option value="1">No owner</option>
                  ) : (
                    <option value="1">
                      {owner?.first_name + " " + owner?.last_name ||
                        owner?.username ||
                        "No owner"}
                    </option>
                  )}
                </>
              ))}
            </select>
          </div>
          <div className="form-group mt-1">
            <label htmlFor="last-contact" className="form-label">
              Last contact
            </label>
            <input type="date" className="form-control" id="last-contact" />
          </div>
          <div className="form-group mt-1">
            <label htmlFor="type" className="form-label">
              Type deal
            </label>
            <select className="form-select" id="type">
              <option value="1">New sale</option>
              <option value="2">Existing company</option>
            </select>
          </div>
          <div className="form-group mt-1">
            <label htmlFor="priority" className="form-label">
              Priority
            </label>
            <select className="form-select" id="priority">
              <option value="1">Low</option>
              <option value="2">Medium</option>
              <option value="3">High</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  );
}
