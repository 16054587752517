import React, { useContext } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { StoreContext } from "../../../../../context/StoreContext.jsx";
import { useAuth } from "../../../../../context/AuthContext";
import { useLocation } from "react-router-dom";
import CollapsibleField from "./CollapsableField.jsx"; // Adjust the path as necessary

export default function EditView({ show, handleClose, ...props }) {
  const { woocommercefields, handleSelectedField, selectedFields } =
    useContext(StoreContext);
  const { user } = useAuth();
  const location = useLocation();

  // Extract title from pathname
  const pathnameTitle = location.pathname.replace("/", "");

  // Filter woocommercefields based on the extracted title
  const filteredFields = woocommercefields?.data?.filter(
    (field) =>
      field.attributes.title.toLowerCase() === pathnameTitle.toLowerCase()
  );

  const handleCheckboxChange = (field, isChecked) => {
    handleSelectedField(field, isChecked);
  };

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>View</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {filteredFields?.[0]?.attributes?.woostoremanager_woocommerce_fields?.data?.map(
            (field) => (
              <CollapsibleField
                key={field.id}
                field={field}
                selectedFields={selectedFields}
                handleCheckboxChange={handleCheckboxChange}
              />
            )
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
