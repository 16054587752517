import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { handleChangeAndUpdateAnswer } from "../../../../../../helpers/Questionnaires.jsx";

export default function CheckboxField({ index, item }) {
  const { register, setValue } = useFormContext();
  const [selectedPrices, setSelectedPrices] = useState({});
  const [optionPrices, setOptionPrices] = useState({});
  console.log("optionPrices", optionPrices);

  // When variables are initially loaded or changed, update the state
  useEffect(() => {
    const prices = {};
    const initialOptionPrices = {};
    Object.entries(item?.variables || {}).forEach(([key, option]) => {
      if (option.selected) {
        prices[key] = option?.product?.price || option?.price || 0;
        initialOptionPrices[key] = option?.product?.price || option?.price || 0; // Get initial price from option.product.price
      }
    });
    setSelectedPrices(prices);
    setOptionPrices(initialOptionPrices); // Set initial option prices
  }, [item.variables]);

  useEffect(() => {
    const totalPrice = Object.values(selectedPrices).reduce(
      (sum, price) => sum + parseFloat(price || 0),
      0
    );
    setValue(`answers.${index}.price`, totalPrice.toFixed(2)); // Using `setValue` from `useFormContext` to update form state
  }, [selectedPrices, index, setValue]);

  const handleCheckboxChange = (checked, key, option) => {
    const newPrices = { ...selectedPrices };
    const newOptionPrices = { ...optionPrices };
    if (checked) {
      newPrices[key] = option?.product?.price || option?.price || 0;
      newOptionPrices[key] = option?.product?.price || option?.price || 0;
    } else {
      delete newPrices[key];
      delete newOptionPrices[key];
    }
    setSelectedPrices(newPrices);
    setOptionPrices(newOptionPrices);

    handleChangeAndUpdateAnswer(
      {
        title: option.title || option.product?.name,
        selected: checked,
        price: option.price,
      },
      item.originalId,
      item,
      index,
      "checkbox",
      option.title,
      item.field_type,
      newPrices
    );
  };

  return (
    <>
      {Object.entries(item?.variables || {}).map(([key, option], optIndex) => (
        <div key={key} className="d-flex align-items-center">
          <InputGroup className="w-100">
            <InputGroup.Checkbox
              {...register(`answers.${index}.variables.${optIndex}`)}
              defaultChecked={option.selected}
              onChange={(e) =>
                handleCheckboxChange(e.target.checked, key, option)
              }
              id={key}
            />
            <Form.Label htmlFor={key} className="ms-2 mb-0">
              {option?.product?.name || option.title}
            </Form.Label>
            <Form.Control
              {...register(`answers.${index}.variables.${optIndex}.price`)}
              placeholder="Price"
              defaultValue={optionPrices[key]}
              type="number"
              onChange={(e) => {
                const newPrice = e.target.value;
                const newOptionPrices = {
                  ...optionPrices,
                  [key]: newPrice,
                };
                setOptionPrices(newOptionPrices);

                const totalPrice = Object.values(newOptionPrices).reduce(
                  (sum, price) => sum + parseFloat(price || 0),
                  0
                );

                handleChangeAndUpdateAnswer(
                  {
                    title: option.title || option.product?.name,
                    selected: !!newPrice,
                    price: parseInt(newPrice, 10),
                    totalPrice: parseInt(totalPrice.toFixed(2), 10), // Add total price
                  },
                  item.originalId,
                  item,
                  index,
                  "checkbox",
                  option.title,
                  item.field_type,
                  newOptionPrices
                );
              }}
              className="form-control ms-2"
            />
          </InputGroup>
        </div>
      ))}
      <Form.Control
        {...register(`answers.${index}.price`)}
        placeholder="Price"
        name="price"
        type="number"
        readOnly
        className="form-control mt-2"
      />
    </>
  );
}
