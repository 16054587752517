import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Deals from "./Deals";
import RecentActivities from "./RecentActivities";
import Orders from "./Orders";
import Service from "./Service";
import Contacts from "./Contacts";
import Companies from "./Companies";

export default function OverviewPane() {
  const location = useLocation();
  const { id } = useParams();
  const isContact = location.pathname === `/contacts/${id}`;
  const isCompany = location.pathname.includes("companies");
  const isService = location.pathname.includes("service");
  const isDeals = location.pathname.includes("deals");
  const isOrders = location.pathname.includes("orders");

  return (
    <div className="row">
      <div className="col-12">
        <RecentActivities />
      </div>
      {!isDeals && (
        <div className="col-12 mt-1">
          <Deals />
        </div>
      )}
      {!isContact && (
        <div className="col-12 mt-1">
          <Contacts />
        </div>
      )}
      {!isOrders && (
        <div className="col-12 mt-1">
          <Orders />
        </div>
      )}
      {!isCompany && (
        <div className="col-12 mt-1">
          <Companies />
        </div>
      )}
      {!isService && (
        <div className="col-12 mt-1">
          <Service />
        </div>
      )}
    </div>
  );
}
