import React, { useContext } from "react";
import { CrmContext } from "../../../../../../context/CrmContext";

export default function ContactForm() {
  const { contact } = useContext(CrmContext);

  return (
    <div className="row">
      <h3 className="fs-4">About this contact</h3>
      <form className="row">
        <div className="col-12">
          <label htmlFor="inputEmail" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="inputEmail"
            defaultValue={contact?.email}
          />
        </div>
        <div className="col-12 mt-1">
          <label htmlFor="inputPhone" className="form-label">
            Phone
          </label>
          <input
            type="tel"
            className="form-control"
            id="inputPhone"
            defaultValue={contact?.phone}
          />
        </div>
        <div className="col-12 mt-1">
          <label htmlFor="inputOwner" className="form-label">
            Owner
          </label>
          <select className="form-select" id="inputOwner">
            {[contact?.owner].map((owner) => (
              <>
                {owner === null ? (
                  <option value="1">No owner</option>
                ) : (
                  <option value="1">
                    {owner?.first_name + " " + owner?.last_name ||
                      owner?.username ||
                      "No owner"}
                  </option>
                )}
              </>
            ))}
          </select>
        </div>
        <div className="col-12 mt-1">
          <label htmlFor="inputLastContact" className="form-label">
            Last contact
          </label>
          <input type="date" className="form-control" id="inputLastContact" />
        </div>
        <div className="col-12 mt-1">
          <label htmlFor="phaseOfLifecycle" className="form-label">
            Phase of lifecycle
          </label>
          <select
            className="form-select"
            id="phaseOfLifecycle"
            aria-label="Default select example"
          >
            <option value="1">Lead</option>
            <option value="2">client</option>
            <option value="3">Ex-client</option>
          </select>
        </div>
      </form>
    </div>
  );
}
