import React from "react";
import Performance from "../components/dashboard/utils/singleview/admin/Performance.jsx";
import DateFilter from "../components/dashboard/utils/singleview/admin/DateFilter.jsx";
import Charts from "../components/dashboard/utils/singleview/admin/Charts.jsx";

export default function Admin() {
  return (
    <div>
      <div className="mb-3">
        <DateFilter />
      </div>
      <Performance />
      <div className="mt-3">
        <Charts />
      </div>
    </div>
  );
}
