import React, { useState, useContext } from "react";
import { FaSync } from "react-icons/fa";
import { StoreContext } from "../../context/StoreContext.jsx";
import "./settingspane.scss"; // Add a CSS file for rotating animation

export default function SettingsPane() {
  const {
    fetchProductFields,
    fetchCustomerFields,
    fetchOrderFields,
    productFieldsRes,
    customerFieldsRes,
    orderFieldsRes,
    productFieldsPending,
    customerFieldsPending,
    orderFieldsPending,
    productFieldsError,
    customerFieldsError,
    orderFieldsError,
  } = useContext(StoreContext);

  const getButtonColor = (isPending, isError, isSuccess) => {
    if (isPending) return "orange";
    if (isError) return "red";
    if (isSuccess) return "green";
    return "primary";
  };

  return (
    <div>
      <div className="settings-option">
        <h3 className="fs-5">Sync Woocommerce Fields</h3>
        <div className="row align-items-center">
          <div className="col-6">
            <p>Customer Fields</p>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <button
              className={`btn btn-outline-${getButtonColor(
                customerFieldsPending,
                customerFieldsError,
                customerFieldsRes
              )} mb-1`}
              onClick={fetchCustomerFields}
              disabled={customerFieldsPending}
            >
              <FaSync
                className={customerFieldsPending ? "sync-rotate" : ""}
                color={getButtonColor(
                  customerFieldsPending,
                  customerFieldsError,
                  customerFieldsRes
                )}
              />
            </button>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-6">
            <p>Product Fields</p>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <button
              className={`btn btn-outline-${getButtonColor(
                productFieldsPending,
                productFieldsError,
                productFieldsRes
              )} mb-1`}
              onClick={fetchProductFields}
              disabled={productFieldsPending}
            >
              <FaSync
                className={productFieldsPending ? "sync-rotate" : ""}
                color={getButtonColor(
                  productFieldsPending,
                  productFieldsError,
                  productFieldsRes
                )}
              />
            </button>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-6">
            <p>Order Fields</p>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <button
              className={`btn btn-outline-${getButtonColor(
                orderFieldsPending,
                orderFieldsError,
                orderFieldsRes
              )}`}
              onClick={fetchOrderFields}
              disabled={orderFieldsPending}
            >
              <FaSync
                className={orderFieldsPending ? "sync-rotate" : ""}
                color={getButtonColor(
                  orderFieldsPending,
                  orderFieldsError,
                  orderFieldsRes
                )}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
