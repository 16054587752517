"use client";
import { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
} from "react-bootstrap";
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  PointerSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import Sortable from "./dndkit/Sortable";
import { useParams } from "react-router-dom";

import Questions from "./fields/Questions";
import Options from "./fields/Options";

import { QuotationContext } from "../../../../../context/QuotationContext.jsx";
// import { AddAndBindQuestions } from "./UpdateQuestionnaire";

export default function QuestionRepeater() {
  const {
    handleTypeChange,
    questions,
    setQuestions,
    handleAddQuestion,
    handleRemoveQuestion,
    handleQuestionOrderChange,
    handleOptionChange,
    handleRequired,
    questionnaires,
    questionnaire,
    products,
  } = useContext(QuotationContext);
  console.log("questions", questions);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const params = useParams();
  const [search, setSearch] = useState("");
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState([]);
  const [filteredQuestionnaires, setFilteredQuestionnaires] = useState(
    questionnaires || []
  );
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    if (questionnaire?.woostoremanager_questions) {
      const questionsCopy = JSON.parse(
        JSON.stringify(questionnaire.woostoremanager_questions)
      );
      setQuestions(questionsCopy);
    }
  }, [questionnaire, setQuestions]);

  const handleCheckboxChange = (questionnaireId) => {
    setSelectedQuestionnaire((prevSelected) => {
      let newSelected;
      if (prevSelected.includes(questionnaireId)) {
        newSelected = prevSelected.filter((id) => id !== questionnaireId);
      } else {
        newSelected = [...prevSelected, questionnaireId];
      }
      return newSelected;
    });
  };

  const customActivationConstraint = (event) => {
    if (
      event.target.tagName === "INPUT" ||
      event.target.tagName === "TEXTAREA" ||
      event.key === " "
    ) {
      return false;
    }
    return { delay: 250, tolerance: 5 };
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: customActivationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    }),
    useSensor(PointerSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over && active && active.id !== over.id) {
      const oldIndex = questions.findIndex((item) => item.id === active.id);
      const newIndex = questions.findIndex((item) => item.id === over.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        const newQuestions = arrayMove(questions, oldIndex, newIndex).map(
          (item, index) => ({
            ...item,
            order: index,
          })
        );
        handleQuestionOrderChange(newQuestions);
      }
    }
    setActiveId(null);
  };

  const handleDragStart = (event) => {
    const { active } = event;
    if (active) {
      setActiveId(active.id);
    }
  };

  if (!Array.isArray(questions) || questions.some((q) => q == null)) {
    return null;
  }

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <div className="w-100">
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <h3>Add Questions</h3>
          <Button variant="outline-primary" onClick={() => setOpen(true)}>
            Import a questionnaire
          </Button>
        </div>
        <div
          className={`modal ${open ? "show" : ""}`}
          tabIndex="-1"
          style={{ display: open ? "block" : "none" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Select a questionnaire</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setOpen(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Form>
                  <FormControl
                    className="mt-4 mb-2"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search for a questionnaire"
                  />
                  {filteredQuestionnaires.map((questionnaire, index) => (
                    <FormGroup key={index} className="border rounded mb-2 p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <FormLabel className="mb-0">
                          {questionnaire?.title}
                        </FormLabel>
                        <Form.Check
                          type="checkbox"
                          value={questionnaire?.id}
                          id={questionnaire?.title}
                          onChange={() =>
                            handleCheckboxChange(questionnaire.id)
                          }
                        />
                      </div>
                    </FormGroup>
                  ))}
                  <Button
                    type="submit"
                    className="mt-4"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {questions.length === 0 && (
          <p className="mb-4">
            Start adding your first question to your questionnaire
          </p>
        )}
        <SortableContext
          items={questions.map((question) => question)}
          strategy={verticalListSortingStrategy}
        >
          {/* sort by order field */}
          {questions
            .sort((a, b) => a.order - b.order)
            .map((question, index) => (
              <Sortable key={question.id} id={question.id}>
                <div className="p-1 mb-2">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <FormGroup>
                      <FormControl
                        as="select"
                        className="form-control text-center"
                        onChange={(e) =>
                          handleTypeChange(e.target.value, question.id, id)
                        }
                        value={question.field_type}
                      >
                        <option value="text">Text</option>
                        <option value="textarea">Textarea</option>
                        <option value="number">Number</option>
                        <option value="select">Select</option>
                        <option value="checkbox">Checkbox</option>
                        <option value="radio">Radio</option>
                        <option value="boolean">Boolean</option>
                        <option value="datetime">Datetime</option>
                        {/* Add more options here for each type */}
                      </FormControl>
                    </FormGroup>
                    <div className="form-check form-switch d-flex align-items-center flex-row-reverse gap-1">
                      <FormLabel
                        className="m-0"
                        htmlFor={`switch-${question.id}`}
                      >
                        Required
                      </FormLabel>
                      <FormControl
                        type="checkbox"
                        style={{ width: "3rem", height: "1.5rem" }}
                        className="form-check-input"
                        id={`switch-${question.id}`}
                        checked={question.required}
                        onChange={(e) =>
                          handleRequired(question.id, e.target.checked, id)
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-baseline justify-content-end flex-column">
                    {(question.field_type === "text" ||
                      question.field_type === "textarea" ||
                      question.field_type === "number" ||
                      question.field_type === "boolean" ||
                      question.field_type === "datetime") && (
                      <Questions question={question} index={index} />
                    )}
                    {(question.field_type === "select" ||
                      question.field_type === "checkbox" ||
                      question.field_type === "radio") && (
                      <Options
                        question={question}
                        index={index}
                        products={products}
                        questionnaireId={id}
                      />
                    )}
                    {questions.length > 0 && (
                      <Button
                        variant="outline-danger mt-2"
                        onClick={() => handleRemoveQuestion(question.id, index)}
                      >
                        Remove this question
                      </Button>
                    )}
                  </div>
                </div>
              </Sortable>
            ))}
        </SortableContext>
        <Button
          className="mt-1"
          variant="primary"
          onClick={() => handleAddQuestion(parseInt(id), "newQuestion")}
        >
          Add a new question
        </Button>
      </div>
    </DndContext>
  );
}
