import React, { useContext, useEffect, useState } from "react";
import { QuotationContext } from "../../../../../context/QuotationContext.jsx";
import { FaRegEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthContext";
import { StoreContext } from "../../../../../context/StoreContext.jsx";
import { Link } from "react-router-dom";
import { CrmContext } from "../../../../../context/CrmContext.jsx";

export default function StrapiTable() {
  const { questionnaires, quotations } = useContext(QuotationContext);
  const { contacts, companies, deals, deleteEntry, serviceTickets } =
    useContext(CrmContext);
  const { handleEntryId } = useContext(StoreContext);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  // console.log("data", data);
  // console.log("serviceTickets", serviceTickets);

  useEffect(() => {
    if (location.pathname.includes("questionnaires")) {
      setData(questionnaires);
    } else if (location.pathname.includes("quotations")) {
      setData(quotations);
    } else if (location.pathname.includes("companies")) {
      setData(companies);
    } else if (location.pathname.includes("contacts")) {
      setData(contacts);
    } else if (location.pathname.includes("deals")) {
      setData(deals);
    } else if (location.pathname.includes("service")) {
      setData(serviceTickets?.serviceTickets);
    }
  }, [
    location,
    questionnaires,
    quotations,
    contacts,
    companies,
    deals,
    serviceTickets,
  ]);

  const isProduct = location.pathname.includes("products");
  const isQuestionnaire = location.pathname.includes("questionnaires");
  const isQuotation = location.pathname.includes("quotations");
  const isContact = location.pathname.includes("contacts");
  const isCompany = location.pathname.includes("/companies");
  const isDeal = location.pathname === "/deals";
  const isService = location.pathname.includes("service");

  const getItemName = (item) => {
    if (isProduct) {
      return item.product_name || item.name;
    } else if (isQuestionnaire) {
      return item.questionnaire_name || item.title;
    } else if (isQuotation) {
      return item.quotation_name || item.title;
    } else if (isContact) {
      return item.first_name + " " + item.last_name;
    } else if (isCompany) {
      return item.company_name || item.name;
    } else if (isDeal) {
      return item.deal_name || item.name;
    } else if (isService) {
      return item.service_name || item.subject;
    }
    return item.name;
  };

  const handleDeleteEntry = (type, id) => {
    deleteEntry(type, id);
  };

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th></th>
            <th>
              {isProduct
                ? "Product Name"
                : isQuestionnaire
                ? "Questionnaire Name"
                : isQuotation
                ? "Quotation Name"
                : isContact
                ? "Contact Name"
                : isCompany
                ? "Company Name"
                : isDeal
                ? "Deal Name"
                : isService
                ? "Service subject"
                : "Name"}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td className="align-middle">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={() => handleEntryId(item.id)}
                />
              </td>
              <td className="align-middle">{getItemName(item)}</td>
              <td className="align-middle d-flex gap-2 align-items-center">
                <Link
                  to={`${
                    location.pathname.includes("attributes")
                      ? location.pathname.split("/attributes")[0]
                      : location.pathname
                  }/${item.id}`}
                >
                  <FaRegEdit
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                  />
                </Link>
                <FaRegTrashAlt
                  onClick={() => {
                    handleDeleteEntry(
                      isProduct
                        ? "products"
                        : isQuestionnaire
                        ? "questionnaires"
                        : isQuotation
                        ? "quotations"
                        : isContact
                        ? "contacts"
                        : isCompany
                        ? "companies"
                        : isDeal
                        ? "deals"
                        : isService
                        ? "service-tickets"
                        : "",
                      item.id
                    );
                  }}
                  size={13}
                  className="m-0"
                  style={{ cursor: "pointer" }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
