import React from "react";
import StrapiTable from "../components/dashboard/utils/listview/tables/StrapiTable";
import StrapiActions from "../components/dashboard/utils/listview/actions/StrapiActions.jsx";
import { useLocation } from "react-router-dom";
import CompanySingle from "./CompanySingle";
import DealSingle from "./DealSingle";
import ContactSingle from "./ContactSingle";
import ServiceSingle from "./ServiceSingle";

export default function Contacts() {
  const location = useLocation();
  const id = location.pathname.split("/").pop();

  const getTitle = () => {
    const pathParts = location.pathname.split("/").filter((part) => part);
    if (pathParts.length > 1) {
      return (
        pathParts[pathParts.length - 1].charAt(0).toUpperCase() +
        pathParts[pathParts.length - 1].slice(1)
      );
    }
    return "Contacts";
  };

  const isContact = location.pathname === `/crm/floowd/contacts/${id}`;
  const isCompany = location.pathname === `/crm/floowd/companies/${id}`;
  const isDeals = location.pathname === `/crm/floowd/deals/${id}`;
  const isService = location.pathname === `/crm/floowd/service/${id}`;

  return (
    <>
      {isContact && <ContactSingle />}
      {isCompany && <CompanySingle />}
      {isDeals && <DealSingle />}
      {isService && <ServiceSingle />}
      {!isCompany && !isDeals && !isService && !isContact && (
        <div>
          <h1>{getTitle()}</h1>
          <div className="mb-2">
            <StrapiActions />
          </div>
          <StrapiTable />
        </div>
      )}
    </>
  );
}
