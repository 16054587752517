import React, { createContext, useState } from "react";
import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { Buffer } from "buffer";

export const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Wordpress API credentials
  const wpUsername = import.meta.env.VITE_APPLICATION_USERNAME;
  const wpPassword = import.meta.env.VITE_APPLICATION_PASSWORD;
  const wpAuthBase64 = Buffer.from(`${wpUsername}:${wpPassword}`).toString(
    "base64"
  );

  // Fetch media from Wordpress
  const fetchMedia = async ({ queryKey }) => {
    const [, page] = queryKey;
    const res = await fetch(
      `/api/wp-json/wp/v2/media?per_page=20&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${wpAuthBase64}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return res.json();
  };

  // Fetch media from Strapi
  const fetchStrapiMedia = async () => {
    const res = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/api/upload/files`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return res.json();
  };

  const mediaQuery = useQuery(["media", 1], fetchMedia);
  const strapiMediaQuery = useQuery("strapiMedia", fetchStrapiMedia);

  if (mediaQuery.isLoading || strapiMediaQuery.isLoading)
    return <div>Loading...</div>;
  if (mediaQuery.isError) return <div>Error: {mediaQuery.error.message}</div>;
  if (strapiMediaQuery.isError)
    return <div>Error: {strapiMediaQuery.error.message}</div>;

  const uploadMedia = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `/api/wp-json/wp/v2/media`, true);
      xhr.setRequestHeader("Authorization", `Basic ${wpAuthBase64}`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round(
            (event.loaded * 100) / event.total
          );
          setUploadProgress(percentCompleted);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          resolve(JSON.parse(xhr.response));
          queryClient.invalidateQueries("media"); // Refresh media library
        } else {
          reject(new Error("Upload failed"));
        }
      };

      xhr.onerror = () => reject(new Error("Upload failed"));
      xhr.send(formData);
    });
  };

  return (
    <MediaContext.Provider
      value={{
        mediaQuery,
        uploadMedia,
        uploadProgress,
        setUploadProgress,
        setUploadStatus,
        strapiMediaQuery,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};
