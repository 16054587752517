import React, { useContext, useState, useEffect } from "react";
import { MediaContext } from "../../../../context/MediaContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ProgressBar from "react-bootstrap/ProgressBar";
import MetaData from "./MetaData";
import "./media.scss"; // Import custom CSS

export default function MediaLibrary({ multiple, ...props }) {
  const { mediaQuery, uploadMedia, uploadProgress, strapiMediaQuery } =
    useContext(MediaContext);
  const [page, setPage] = useState(1);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [selectedMediaSource, setSelectedMediaSource] = useState(null);

  useEffect(() => {
    if (!multiple && selectedImages.length > 0) {
      setSelectedImageId(selectedImages[0].id);
    }
  }, [multiple, selectedImages]);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    mediaQuery.fetchNextPage(); // Assuming react-query's useInfiniteQuery is used
  };

  const handleImageClick = (id, source) => {
    setSelectedMediaSource(source);
    if (multiple) {
      setSelectedImages((prevSelectedImages) => {
        const isSelected = prevSelectedImages.some((image) => image.id === id);
        if (isSelected) {
          return prevSelectedImages.filter((image) => image.id !== id);
        } else {
          const newImage =
            source === "wordpress"
              ? mediaQuery.data.find((media) => media.id === id)
              : strapiMediaQuery.data.find((media) => media.id === id);
          return [...prevSelectedImages, newImage];
        }
      });
    } else {
      const newImage =
        source === "wordpress"
          ? mediaQuery.data.find((media) => media.id === id)
          : strapiMediaQuery.data.find((media) => media.id === id);
      setSelectedImages([newImage]);
      setSelectedImageId(id);
    }
  };

  const selectedMedia =
    selectedMediaSource === "wordpress"
      ? mediaQuery.data.find((media) => media.id === selectedImageId)
      : strapiMediaQuery.data.find((media) => media.id === selectedImageId);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="custom-modal-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Media library
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="custom-modal-content"
        style={{
          overflowY: "auto",
          height: "75vh",
        }}
      >
        <Tabs defaultActiveKey="upload" id="media-library-tabs">
          <Tab eventKey="upload" title="Upload files">
            <div className="mt-3 d-flex flex-column align-items-center">
              <h4>Upload files</h4>
              <label
                htmlFor="file-upload"
                className="btn btn-outline-primary mt-1 mb-1"
              >
                Upload
              </label>
              <input
                onChange={(e) => uploadMedia(e.target.files[0])}
                type="file"
                id="file-upload"
                hidden
              />
              <p>Upload your files here</p>
              {uploadProgress > 0 && (
                <ProgressBar
                  now={uploadProgress}
                  label={`${uploadProgress}%`}
                  className="w-100"
                />
              )}
            </div>
          </Tab>
          <Tab eventKey="libraryWordpress" title="Media library WordPress">
            <div className="row">
              <div
                className="col-md-9 d-flex flex-wrap gap-3"
                style={{
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                {mediaQuery.data.map((media) => (
                  <div
                    className={`p-0 ${
                      selectedImages.some((image) => image.id === media.id)
                        ? "border border-primary rounded"
                        : "border"
                    }`}
                    key={media.id}
                    onClick={() => handleImageClick(media.id, "wordpress")}
                    style={{
                      cursor: "pointer",
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    <img
                      src={media.source_url}
                      alt={media.alt_text}
                      className="bg-light p-1 rounded w-100 h-100"
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="col-md-3">
                {selectedMediaSource === "wordpress" && selectedMedia && (
                  <MetaData media={selectedMedia} />
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="libraryStrapi" title="Media library Strapi">
            <div className="row">
              <div
                className="col-md-9 d-flex flex-wrap gap-3"
                style={{
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                {strapiMediaQuery.data.map((media) => (
                  <div
                    className={`p-0 ${
                      selectedImages.some((image) => image.id === media.id)
                        ? "border border-primary rounded"
                        : "border"
                    }`}
                    key={media.id}
                    onClick={() => handleImageClick(media.id, "strapi")}
                    style={{
                      cursor: "pointer",
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    <img
                      src={media.url}
                      alt={media.alternativeText || media.caption}
                      className="bg-light p-1 rounded w-100 h-100"
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="col-md-3">
                {selectedMediaSource === "strapi" && selectedMedia && (
                  <MetaData media={selectedMedia} />
                )}
              </div>
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button variant={"outline-primary"} onClick={props.onHide}>
            Close
          </Button>
          <Button
            onClick={() => {
              props.onInsert(selectedImages);
              props.onHide();
            }}
          >
            Insert
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
