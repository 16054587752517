import React from "react";
import StrapiTable from "../components/dashboard/utils/listview/tables/StrapiTable";
import StrapiActions from "../components/dashboard/utils/listview/actions/StrapiActions.jsx";

export default function Questionnaires() {
  return (
    <div>
      <h1>Questionnaires</h1>
      <div className="mb-2">
        <StrapiActions />
      </div>
      <StrapiTable />
    </div>
  );
}
