import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { StoreContext } from "../../../../../context/StoreContext";

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const formatChartData = (data, label, borderColor, backgroundColor) => {
  return {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: label,
        data: data.map((item) => item.value),
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        fill: true,
      },
    ],
  };
};

const Charts = () => {
  const { reports } = useContext(StoreContext);
  console.log("reports", reports);

  // Extract data from the totals object
  const extractData = (field) => {
    return Object.keys(reports[0]?.totals || {}).map((date) => ({
      date: date,
      value: parseFloat(reports[0]?.totals[date][field] || 0),
    }));
  };

  const netSalesData = extractData("sales");
  const ordersData = extractData("orders");

  const netSalesChartData = formatChartData(
    netSalesData,
    "Net Sales",
    "rgba(75,192,192,1)",
    "rgba(75,192,192,0.2)"
  );
  const ordersChartData = formatChartData(
    ordersData,
    "Orders",
    "rgba(153,102,255,1)",
    "rgba(153,102,255,0.2)"
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h3>Net Sales</h3>
          <Line data={netSalesChartData} />
        </div>
        <div className="col-md-6">
          <h3>Orders</h3>
          <Line data={ordersChartData} />
        </div>
      </div>
    </div>
  );
};

export default Charts;
