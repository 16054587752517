import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import Actions from "../components/dashboard/utils/listview/actions/Actions.jsx";
import Table from "../components/dashboard/utils/listview/tables/Table";
import Sidebar from "../components/dashboard/utils/listview/sidebar/AddEntity";

export default function Products() {
  const location = useLocation();

  const getTitle = () => {
    const pathParts = location.pathname.split("/").filter((part) => part);
    if (pathParts.length > 1) {
      return (
        pathParts[pathParts.length - 1].charAt(0).toUpperCase() +
        pathParts[pathParts.length - 1].slice(1)
      );
    }
    return "Products";
  };

  return (
    <div>
      <h1>{getTitle()}</h1>
      {location.pathname === "/products" && (
        <div className="mb-2">
          <Actions />
        </div>
      )}
      <div className="row">
        {location.pathname !== "/products" ? (
          <div className="col-md-4">
            <Sidebar />
          </div>
        ) : null}
        <div
          className={`col-md-${location.pathname === "/products" ? "12" : "8"}`}
        >
          <Table />
        </div>
      </div>
    </div>
  );
}
