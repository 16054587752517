import React, { useContext, useState } from "react";
import { CrmContext } from "../../../../../../../context/CrmContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function ComposerForm() {
  const { contact, handleSendEmail } = useContext(CrmContext);
  const [editorContent, setEditorContent] = useState(""); // State to store CKEditor content
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData.entries());
      data.content = editorContent; // Add CKEditor content to the form data
      console.log(data);
      handleSendEmail(data);
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row align-items-center">
          <input type="hidden" name="contactId" value={contact?.id} />
          <div className="col-1">
            <label htmlFor="from">From</label>
          </div>
          <div className="col-11">
            <input
              type="text"
              name="from"
              className="form-control"
              placeholder="From"
              defaultValue="klantenservice@floowd.nl"
            />
          </div>
        </div>
        <div className="mt-1 row align-items-center">
          <div className="col-1">
            <label htmlFor="to">To</label>
          </div>
          <div className="col-11">
            <input
              type="text"
              name="to"
              className="form-control"
              placeholder="To"
              defaultValue={contact?.email}
            />
          </div>
        </div>
        <div className="mt-1 row align-items-center">
          <div className="col-1">
            <label htmlFor="subject">Subject</label>
          </div>
          <div className="col-11">
            <input
              type="text"
              name="subject"
              className="form-control"
              placeholder="Subject"
            />
          </div>
        </div>
        <div className="mt-1">
          <CKEditor
            editor={ClassicEditor}
            data=""
            onReady={(editor) => {
              // You can add additional logic here if needed when the editor is ready
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditorContent(data); // Store CKEditor content in state
            }}
            config={{
              toolbar: [
                "undo",
                "redo",
                "|",
                "bold",
                "italic",
                "blockQuote",
                "link",
              ],
            }}
          />
          <style>{`
        .ck-editor__editable_inline {
          min-height: 300px; /* Adjust the minimum height as needed */
          max-height: 300px; /* Adjust the maximum height as needed */
        }
      `}</style>
        </div>
        <button
          type="submit"
          className={`btn ${success ? "btn-outline-info" : "btn-primary"} mt-1`}
        >
          {success ? "Email sent" : "Send email"}
        </button>
      </form>
    </div>
  );
}
