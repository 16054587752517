import React, { useState, useEffect, useRef } from "react";
import MediaLibrary from "../../../../../../components/dashboard/utils/media/MediaLibrary.jsx";

export default function VariationForm({ variation, onVariationChange }) {
  const [formState, setFormState] = useState(variation);
  const isInitialMount = useRef(true);
  const [modalShow, setModalShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  console.log("selectedImage", selectedImage);

  useEffect(() => {
    setFormState(variation);
  }, [variation]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      onVariationChange(formState);
    }
  }, [formState, onVariationChange]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormState((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleImageChange = (media) => {
    setFormState((prev) => ({
      ...prev,
      image: media.source_url,
      image_id: media.id, // Ensure image_id is updated as well
    }));
    setSelectedImage(media);
    setModalShow(false);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="d-flex gap-1 align-items-start">
        <img
          style={{
            width: "70px",
            height: "70px",
            objectFit: "cover",
            cursor: "pointer",
            padding: "5px",
            border: "1px solid #ccc",
          }}
          src={
            selectedImage?.[0]?.source_url ||
            formState.image ||
            "https://via.placeholder.com/70"
          }
          alt="placeholder"
          onClick={() => setModalShow(true)}
        />
        <MediaLibrary
          show={modalShow}
          onHide={() => setModalShow(false)}
          onInsert={handleImageChange}
        />
        <input
          type="text"
          className="form-control"
          name="sku"
          placeholder="SKU"
          value={formState.sku}
          onChange={handleChange}
        />
      </div>
      <div className="d-flex gap-1 align-items-start">
        <div className="form-group mt-1">
          <input
            type="checkbox"
            name="downloadable"
            id="downloadable"
            checked={formState.downloadable}
            onChange={handleChange}
            className="form-check-input"
          />
          <label htmlFor="downloadable" className="ms-1">
            Downloadable
          </label>
        </div>
        <div className="form-group mt-1">
          <input
            type="checkbox"
            name="manage_stock"
            id="manage_stock"
            checked={formState.manage_stock}
            onChange={handleChange}
            className="form-check-input"
          />
          <label htmlFor="manage_stock" className="ms-1">
            Stock
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group mt-1">
            <label>Regular price</label>
            <input
              type="number"
              className="form-control"
              name="regular_price"
              value={formState.regular_price}
              onChange={handleChange}
              placeholder="Regular price"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group mt-1">
            <label>Sale price</label>
            <input
              type="number"
              className="form-control"
              name="sale_price"
              value={formState.sale_price}
              onChange={handleChange}
              placeholder="Sale price"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group mt-1">
            <label>Stock status</label>
            <select
              className="form-select"
              name="stock_status"
              value={formState.stock_status}
              onChange={handleChange}
            >
              <option value="instock">In stock</option>
              <option value="outofstock">Out of stock</option>
              <option value="onbackorder">On backorder</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group mt-1">
            <label>Weight (kg)</label>
            <input
              type="number"
              className="form-control"
              name="weight"
              value={formState.weight}
              onChange={handleChange}
              placeholder="Weight"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group mt-1">
            <label>Dimensions (cm)</label>
            <div className="row">
              <div className="col-4">
                <input
                  type="number"
                  className="form-control"
                  name="length"
                  value={formState.length}
                  onChange={handleChange}
                  placeholder="Length"
                />
              </div>
              <div className="col-4">
                <input
                  type="number"
                  className="form-control"
                  name="width"
                  value={formState.width}
                  onChange={handleChange}
                  placeholder="Width"
                />
              </div>
              <div className="col-4">
                <input
                  type="number"
                  className="form-control"
                  name="height"
                  value={formState.height}
                  onChange={handleChange}
                  placeholder="Height"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group mt-1">
            <label>Shipping class</label>
            <select
              className="form-select"
              name="shipping_class"
              value={formState.shipping_class}
              onChange={handleChange}
            >
              <option value="standard">Standard</option>
              <option value="express">Express</option>
              <option value="overnight">Overnight</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group mt-1">
            <label>Tax class</label>
            <select
              className="form-select"
              name="tax_class"
              value={formState.tax_class}
              onChange={handleChange}
            >
              <option value="standard">Standard</option>
              <option value="reduced">Reduced</option>
              <option value="zero">Zero</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group mt-1">
            <label>Description</label>
            <textarea
              className="form-control"
              name="description"
              value={formState.description}
              onChange={handleChange}
              placeholder="Description"
              rows="3"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  );
}
