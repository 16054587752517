import React, { useContext } from "react";
import { CrmContext } from "../../../../../../context/CrmContext";

export default function RecentActivities() {
  const { contact } = useContext(CrmContext);

  // const activities = [
  //   {
  //     id: 1,
  //     title: "Meeting with John Doe",
  //     date: "2021-08-01",
  //     time: "10:00",
  //     description: "Meeting with John Doe to discuss the new project.",
  //   },
  //   {
  //     id: 2,
  //     title: "Call with Jane Doe",
  //     date: "2021-08-02",
  //     time: "11:00",
  //     description: "Call with Jane Doe to discuss the new project.",
  //   },
  //   {
  //     id: 3,
  //     title: "Meeting with John Doe",
  //     date: "2021-08-03",
  //     time: "12:00",
  //     description: "Meeting with John Doe to discuss the new project.",
  //   },
  //   {
  //     id: 4,
  //     title: "Call with Jane Doe",
  //     date: "2021-08-04",
  //     time: "13:00",
  //     description: "Call with Jane Doe to discuss the new project.",
  //   },
  //   {
  //     id: 5,
  //     title: "Meeting with John Doe",
  //     date: "2021-08-05",
  //     time: "14:00",
  //     description: "Meeting with John Doe to discuss the new project.",
  //   },
  // ];

  return (
    <div className="row">
      <div className="col-12 bg-white p-2">
        <h3 className="fs-5">Recent Activities</h3>
        <table className="table mt-1">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Date</th>
              <th scope="col">Time</th>
              <th scope="col">Note</th>
            </tr>
          </thead>
          <tbody>
            {contact?.activities?.map((activity) => (
              <tr key={activity.id}>
                <td>{activity.type}</td>
                <td>{activity.date}</td>
                <td>{activity.time}</td>
                <td>{activity.note.substring(0, 50) + "..."}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
