import React, { useState } from "react";
import { FaRegLightbulb } from "react-icons/fa";
import { FaRegBell } from "react-icons/fa";
import { LuSettings } from "react-icons/lu";
import { useAuth } from "../../context/AuthContext";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import SettingsPane from "./SettingsPane";
import NotificationPane from "./NotificationPane";
import { RiDashboard3Line } from "react-icons/ri";
import { Link } from "react-router-dom";

export default function Nav() {
  const { user, logout } = useAuth();
  const [showNotifications, setShowNotifications] = useState(false);
  console.log("showNotifications", showNotifications);
  const [showSettings, setShowSettings] = useState(false);
  console.log("showSettings", showSettings);

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handleShowSettings = () => {
    setShowSettings(true);
  };

  const handleCloseNotifications = () => {
    setShowNotifications(false);
  };

  const handleShowNotifications = () => {
    setShowNotifications(true);
  };

  return (
    <div className="d-flex gap-2">
      <Link to="/">
        <div
          className="d-flex align-items-center justify-content-center rounded-circle bg-white"
          style={{
            width: "40px",
            height: "40px",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <RiDashboard3Line className="text-primary" />
        </div>
      </Link>
      <div
        className="d-flex align-items-center justify-content-center rounded-circle bg-white"
        style={{
          width: "40px",
          height: "40px",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <FaRegLightbulb className="text-primary" />
      </div>
      <div
        onClick={handleShowNotifications}
        className="d-flex align-items-center justify-content-center rounded-circle bg-white"
        style={{
          width: "40px",
          height: "40px",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
        }}
      >
        <div className="position-relative">
          <FaRegBell className="text-primary" />
          <span
            className="badge bg-primary rounded-pill"
            style={{
              position: "absolute",
              top: "-15px",
              right: "-20px",
            }}
          >
            5
          </span>
        </div>
      </div>
      <Offcanvas
        placement={"end"}
        show={showNotifications}
        onHide={handleCloseNotifications}
      >
        <Offcanvas.Header closeButton className="bg-info text-white">
          <Offcanvas.Title>Notifications</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <NotificationPane />
        </Offcanvas.Body>
      </Offcanvas>
      <div
        onClick={handleShowSettings}
        className="d-flex align-items-center justify-content-center rounded-circle bg-white"
        style={{
          width: "40px",
          height: "40px",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
        }}
      >
        <LuSettings className="text-primary" />
      </div>
      <Offcanvas
        placement={"end"}
        show={showSettings}
        onHide={handleCloseSettings}
      >
        <Offcanvas.Header closeButton className="bg-info text-white">
          <Offcanvas.Title>User Settings</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SettingsPane />
        </Offcanvas.Body>
      </Offcanvas>
      <div className="d-flex align-items-center gap-2">
        <div className="dropdown">
          <button
            className=" border-0 dropdown-toggle"
            style={{
              background: "transparent",
            }}
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={user?.avatar?.[0]?.url}
              alt="avatar"
              className="rounded-circle"
              style={{
                width: "40px",
                height: "40px",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                objectFit: "cover",
              }}
            />
          </button>
          <ul className="dropdown-menu">
            <li
              style={{
                cursor: "pointer",
              }}
              onClick={logout}
              className="dropdown-item"
              href="#"
            >
              My Profile
            </li>
            <li
              style={{
                cursor: "pointer",
              }}
              onClick={logout}
              className="dropdown-item"
              href="#"
            >
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
