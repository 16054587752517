const CreateCustomer = async (data, id) => {
  console.log("data", data);
  try {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/customers`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
        body: JSON.stringify({
          ...data,
          quotationId: parseInt(id),
        }),
      }
    );
    const customer = await response.json();
    return customer; // Return the customer response
  } catch (error) {
    console.log("error", error);
    throw error; // Throw the error to be handled by the caller
  }
};

const DeleteCustomer = async (userId, id) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/quotations/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
        body: JSON.stringify({
          user: [], // Send an empty array if you want to clear the relation
        }),
      }
    );

    const customer = await response.json();

    if (!response.ok) {
      console.error("Response Error:", customer);
      throw new Error(customer.error.message || "Something went wrong");
    }

    console.log("Customer updated", customer);
  } catch (error) {
    console.log("error", error);
  }
};

export { CreateCustomer, DeleteCustomer };
