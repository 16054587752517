import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaReply } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function CorrespondenceEmailActions({ email }) {
  return (
    <div className="row justify-content-between align-items-center mb-2 border-bottom border-1 pb-1">
      <div className="col">
        <FaRegTrashAlt />
      </div>
      <div className="col d-flex justify-content-end">
        <Link
          to={`mailto:${email.to}?subject=${encodeURIComponent(
            email.subject
          )}&body=${encodeURIComponent(email.html)}`}
        >
          <FaReply />
        </Link>
      </div>
    </div>
  );
}
