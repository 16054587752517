import React, { createContext, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Buffer } from "buffer";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import LoadingIndicator from "../components/utils/LoadingIndicator";
import {
  AddAndBindQuestions,
  UpdateQuestionnaire,
  removeQuestion,
  updatedOptions,
  updateQuestion,
  updateImages,
  updateAnswerOrder,
} from "../helpers/Questionnaires";

export const QuotationContext = createContext();

const queryClient = new QueryClient();

const fetchQuestionnaires = async () => {
  const response = await fetch(
    `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/questionnaires`,
    {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
      },
    }
  );
  return response.json();
};

const fetchQuestionnaire = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const username = import.meta.env.VITE_WOOCOMMERCE_USERNAME;
  const password = import.meta.env.VITE_WOOCOMMERCE_SECRET;
  const authBase64 = Buffer.from(`${username}:${password}`).toString("base64");
  const response = await fetch(
    `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/questionnaires/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${authBase64}`,
      },
    }
  );
  return response.json();
};

const fetchQuotations = async () => {
  const response = await fetch(
    `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/quotations`,
    {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
      },
    }
  );
  return response.json();
};

const fetchQuotation = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const response = await fetch(
    `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/quotations/${id}`,
    {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
      },
    }
  );
  return response.json();
};

const fetchCustomers = async () => {
  const response = await fetch(
    `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/customers/`,
    {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
      },
    }
  );
  return response.json();
};

export const QuotationProvider = ({ children }) => {
  const [questions, setQuestions] = useState([
    {
      id: null,
      title: "",
      required: false,
      field_type: "select",
      variables: {}, // Initialize with an empty object for variables
    },
  ]);
  const { id } = useParams();
  const location = useLocation();

  const { data: questionnaires, isLoading: isLoadingQuestionnaires } = useQuery(
    "questionnaires",
    fetchQuestionnaires,
    { enabled: location.pathname.includes("questionnaires") }
  );
  const { data: questionnaire, isLoading: isLoadingQuestionnaire } = useQuery(
    ["questionnaire", id],
    fetchQuestionnaire,
    { enabled: !!id || location.pathname.includes("questionnaires") }
  );
  const { data: quotations, isLoading: isLoadingQuotations } = useQuery(
    "quotations",
    fetchQuotations,
    { enabled: location.pathname.includes("quotations") }
  );
  const { data: quotation, isLoading: isLoadingQuotation } = useQuery(
    ["quotation", id],
    fetchQuotation,
    {
      enabled: !!id && location.pathname.includes("quotations"),
    }
  );
  const { data: customers, isLoading: isLoadingCustomers } = useQuery(
    "customers",
    fetchCustomers
  );

  const [answers, setAnswers] = useState([]);
  const [products, setProducts] = useState([]);

  const updateAnswerAtIndex = (index, newAnswerUpdater) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      if (index >= 0 && index < updatedAnswers.length) {
        updatedAnswers[index] = newAnswerUpdater(updatedAnswers[index]);
      }
      return updatedAnswers;
    });
  };

  const handleDefaultAnswers = (defaultAnswers) => {
    setAnswers(defaultAnswers);
  };

  if (
    isLoadingQuestionnaires ||
    isLoadingQuotations ||
    isLoadingQuotation ||
    isLoadingQuestionnaire ||
    isLoadingCustomers
  ) {
    return <LoadingIndicator />;
  }

  // Questionnaire functionalities

  const handleInputChange = (id, e) => {
    console.log("e", e);
    const newTitle = e.target.value;
    const newQuestions = questions.map((question) => {
      if (question.id === id) {
        return { ...question, title: newTitle };
      }
      return question;
    });
    setQuestions(newQuestions);
    updateQuestion(id, { title: newTitle });
  };

  const handleOptionChange = async (questionId, optionKey, key, value) => {
    let newValue = value;

    if (key === "file" && value instanceof Blob) {
      const formData = new FormData();
      formData.append("files", value);
      const images = await updateImages(formData);
      newValue = images[0];
    }

    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          variables: {
            ...question.variables,
            [optionKey]: { ...question.variables[optionKey], [key]: newValue },
          },
        };
      }
      return question;
    });

    setQuestions(updatedQuestions);
    updatedOptions(
      questionId,
      updatedQuestions.find((q) => q.id === questionId).variables
    );
  };

  const handleOptionOrderChange = (questionId, newOrder) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === questionId
          ? { ...question, variables: newOrder }
          : question
      )
    );
    updatedOptions(questionId, newOrder);
  };

  const handleQuestionOrderChange = (newOrder) => {
    setQuestions(newOrder);
    console.log("newOrder", newOrder);
    newOrder.forEach((question, index) => {
      updateQuestion(question.id, { order: question.order.toString() });
    });
  };

  const updateAnswerOrderChange = (newOrder) => {
    console.log("newOrder", newOrder);
    setAnswers(newOrder);
    updateAnswerOrder(newOrder);
  };

  const handleAddOptionToQuestion = (questionId, newOption) => {
    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        const newOptionKey = `option${
          Object.keys(question.variables).length + 1
        }`;
        return {
          ...question,
          variables: {
            ...question.variables,
            [newOptionKey]: newOption,
          },
        };
      }
      return question;
    });

    setQuestions(updatedQuestions);
    updatedOptions(
      questionId,
      updatedQuestions.find((q) => q.id === questionId).variables
    );
  };

  const handleRemoveOptionFromQuestion = (questionId, optionKey) => {
    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        const updatedOptions = { ...question.variables };
        delete updatedOptions[optionKey];
        return { ...question, variables: updatedOptions };
      }
      return question;
    });
    setQuestions(updatedQuestions);
    updatedOptions(
      questionId,
      updatedQuestions.find((q) => q.id === questionId).variables
    );
  };

  const handleAddQuestion = (id, newQuestion) => {
    const newId =
      questions.length > 0 ? Math.max(questions.map((q) => q.id)) + 1 : 1;
    setQuestions([
      ...questions,
      { id: newId, title: "", field_type: "text", variables: {} },
    ]);
    AddAndBindQuestions(id, newQuestion);
  };

  const handleRemoveQuestion = (id) => {
    setQuestions(questions.filter((question) => question.id !== id));
    removeQuestion(id);
  };

  const handleTypeChange = (field_type, id) => {
    const newQuestions = questions.map((question) => {
      if (question.id === id) {
        const updatedQuestion = { ...question, field_type: field_type };
        if (
          (field_type === "select" ||
            field_type === "checkbox" ||
            field_type === "radio") &&
          (!question.variables ||
            typeof question.variables !== "object" ||
            Object.keys(question.variables).length === 0)
        ) {
          updatedQuestion.variables = {
            option1: { title: "", price: "", file: {} },
          };
        }
        return updatedQuestion;
      }
      return question;
    });

    setQuestions(newQuestions);
    updateQuestion(id, { field_type: field_type });
  };

  const handleRequired = (id, required) => {
    console.log("required", required);
    console.log("id", id);
    const newQuestions = questions.map((question) => {
      if (question.id === id) {
        return { ...question, required };
      }
      return question;
    });

    console.log("newQuestions", newQuestions);

    setQuestions(newQuestions);
    updateQuestion(id, { required });
  };

  return (
    <QueryClientProvider client={queryClient}>
      <QuotationContext.Provider
        value={{
          questionnaires,
          quotations,
          quotation,
          questions,
          setQuestions,
          questionnaire,
          customers,
          answers,
          setAnswers,
          updateAnswerOrderChange,
          updateAnswerAtIndex,
          handleInputChange,
          handleOptionChange,
          handleOptionOrderChange,
          handleQuestionOrderChange,
          handleDefaultAnswers,
          handleAddQuestion,
          handleRemoveQuestion,
          handleTypeChange,
          handleRequired,
          handleAddOptionToQuestion,
          handleRemoveOptionFromQuestion,
        }}
      >
        {children}
      </QuotationContext.Provider>
    </QueryClientProvider>
  );
};
