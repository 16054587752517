import React, { useContext } from "react";
import { CrmContext } from "../../../../../../context/CrmContext";
import { useLocation } from "react-router-dom";

export default function ContactDetails() {
  const { contact, company, serviceTicket } = useContext(CrmContext);
  const location = useLocation();

  const isCompany = location.pathname.includes("companies");
  const isContact = location.pathname.includes("crm");
  const isService = location.pathname.includes("service");

  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();
    return initials.length > 2 ? initials.slice(0, 2) : initials;
  };

  const initials = isCompany
    ? getInitials(company?.name)
    : isContact
    ? getInitials(`${contact?.first_name} ${contact?.last_name}`)
    : "";

  return (
    <div>
      <div className="row align-items-center">
        {!isService && (
          <div className="col-3">
            <div
              style={{
                width: "75px",
                borderRadius: "50%",
                backgroundColor: "#ccc", // Placeholder background color
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "2rem",
                color: "white",
                height: "75px",
              }}
            >
              {isCompany || isContact ? (
                <span>{initials}</span>
              ) : (
                <img
                  style={{
                    width: "100%",
                    borderRadius: "50%",
                  }}
                  src="https://via.placeholder.com/150"
                  alt="Contact"
                />
              )}
            </div>
          </div>
        )}
        <div className="col-9">
          {isCompany && (
            <div>
              <h3 className="fs-4">{company?.name}</h3>
              <p>{company?.website}</p>
            </div>
          )}
          {isService && (
            <div>
              <h3 className="fs-4">{serviceTicket?.subject}</h3>
              <select
                className="form-select mt-1"
                defaultValue={serviceTicket?.status}
              >
                <option value="1">Open</option>
                <option value="2">Closed</option>
                <option value="3">In Progress</option>
              </select>
            </div>
          )}
          {isContact && (
            <>
              <h2 className="fs-3">
                {contact?.first_name} {contact?.last_name}
              </h2>
              <p>{contact?.email}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
