import React, { useContext } from "react";
import { StoreContext } from "../../../../../context/StoreContext.jsx";

export default function TypeSelection({ product }) {
  const { updateProduct } = useContext(StoreContext);

  return (
    <div>
      <select
        onChange={(e) => {
          updateProduct(product?.id, "type", e.target.value);
        }}
        className="form-select"
        defaultValue={product.type}
        aria-label="What's the product type"
      >
        <option value="simple">Simple</option>
        <option value="variable">Variable</option>
      </select>
    </div>
  );
}
