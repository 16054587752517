import React, { useState, useEffect, useContext } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { QuotationContext } from "../../../../../../../context/QuotationContext";

export default function CustomerSelect() {
  const [search, setSearch] = useState("");
  const { customers } = useContext(QuotationContext);
  console.log("customers", customers);
  const { control } = useFormContext();
  const [filteredCustomers, setFilteredCustomers] = useState(customers || []);

  useEffect(() => {
    if (!search) {
      setFilteredCustomers(customers);
      return;
    }
    const lowercasedSearch = search.toLowerCase();
    const result = customers.filter((customer) =>
      `${customer?.first_name} ${customer?.last_name}`
        .toLowerCase()
        .includes(lowercasedSearch)
    );
    setFilteredCustomers(result);
  }, [search, customers]);

  return (
    <div>
      <input
        type="text"
        className="form-control mb-1"
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search for a customer"
      />
      <Controller
        name="selectedCustomer"
        control={control}
        render={({ field }) => (
          <div {...field} className="btn-group-toggle" data-toggle="buttons">
            {filteredCustomers.map((customer, index) => (
              <label
                key={index}
                className="btn btn-outline-primary mb-1 d-flex justify-content-between w-100 rounded flex-row-reverse"
                htmlFor={`customer-${customer.id}`}
              >
                <input
                  type="radio"
                  name="selectedCustomer"
                  id={`customer-${customer.id}`}
                  value={customer.id.toString()}
                  autoComplete="off"
                  onChange={field.onChange}
                  checked={field.value === customer.id.toString()}
                />
                {customer?.first_name} {customer?.last_name || ""}
              </label>
            ))}
          </div>
        )}
      />
    </div>
  );
}
