import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import App from "./App.jsx";
import "./scss/styles.scss";
import * as bootstrap from "bootstrap";

// Pages
import Login from "./pages/Login.jsx";
import Admin from "./pages/Admin.jsx";

import Products from "./pages/Products.jsx";
import ProductChildren from "./pages/ProductChildren.jsx";
import ProductSingle from "./pages/ProductSingle.jsx";

import AttributeSingle from "./pages/AttributeSingle.jsx";

import Orders from "./pages/Orders.jsx";
import OrderSingle from "./pages/OrderSingle.jsx";

import Contacts from "./pages/Contacts.jsx";
import ContactSingle from "./pages/ContactSingle.jsx";
import ContactChildren from "./pages/ContactChildren.jsx";

import Quotations from "./pages/Quotations.jsx";
import QuotationSingle from "./pages/QuotationSingle.jsx";

import Questionnaires from "./pages/Questionnaires.jsx";
import QuestionnaireSingle from "./pages/QuestionnaireSingle.jsx";
import QuotationPreview from "./pages/QuotationPreview.jsx";

import ProtectedRoute from "./pages/ProtectedRoute.jsx";
import LoggedInRoute from "./pages/LoggedInRoute.jsx";

import ErrorBoundary from "./components/utils/ErrorBoundary.jsx";

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <LoggedInRoute>
        <Login />
      </LoggedInRoute>
    ),
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <Admin />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
      },
      {
        path: "products",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <Products />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
        children: [
          {
            children: [
              {
                path: "*",
                element: (
                  <ProtectedRoute>
                    <ErrorBoundary>
                      <ProductChildren />
                    </ErrorBoundary>
                  </ProtectedRoute>
                ),
              },
            ],
            path: "floowd",
            element: (
              <ProtectedRoute>
                <ErrorBoundary></ErrorBoundary>
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "products/:id",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <ProductSingle />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
      },
      {
        path: "attributes/terms/:id",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <AttributeSingle />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
      },
      {
        path: "orders",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <Orders />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
      },
      {
        path: "orders/:id",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <OrderSingle />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
      },
      {
        path: "crm",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <Contacts />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
        children: [
          {
            children: [
              {
                path: "*",
                element: (
                  <ProtectedRoute>
                    <ErrorBoundary>
                      <ContactChildren />
                    </ErrorBoundary>
                  </ProtectedRoute>
                ),
              },
            ],
            path: "floowd",
            element: (
              <ProtectedRoute>
                <ErrorBoundary></ErrorBoundary>
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "crm/:id",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <ContactSingle />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
      },

      {
        path: "quotations",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <Quotations />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
      },
      {
        path: "quotations/:id",
        children: [
          {
            path: "preview",
            element: (
              <ProtectedRoute>
                <ErrorBoundary>
                  <QuotationPreview />
                </ErrorBoundary>
              </ProtectedRoute>
            ),
          },
        ],
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <QuotationSingle />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
      },
      {
        path: "questionnaires",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <Questionnaires />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
      },
      {
        path: "questionnaires/:id",
        element: (
          <ProtectedRoute>
            <ErrorBoundary>
              <QuestionnaireSingle />
            </ErrorBoundary>
          </ProtectedRoute>
        ),
      },
      // Handle 404 Not Found for unmatched routes
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

const MainRouter = () => (
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <div className=" bg-qwerty">
      <MainRouter />
    </div>
  </React.StrictMode>
);
