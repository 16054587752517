import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar.jsx";
import Header from "./Header.jsx";

export default function Layout() {
  const sidebarRef = useRef(null);
  const minimizeSidebar = localStorage.getItem("minimizeSidebar");

  const handleMinimizeSidebar = () => {
    sidebarRef.current.classList.toggle("minimize-sidebar");
    sidebarRef.current.classList.toggle("maximize-sidebar");

    localStorage.setItem(
      "minimizeSidebar",
      sidebarRef.current.classList.contains("minimize-sidebar")
    );
  };

  return (
    <div className="row">
      <div
        ref={sidebarRef}
        className={`col-2 ${
          minimizeSidebar === "true" ? "minimize-sidebar" : "maximize-sidebar"
        }`}
      >
        <Sidebar handleMinimizeSidebar={handleMinimizeSidebar} />
      </div>
      <div className="col-10">
        <Header />
        <div
          style={{
            height: "90vh",
            overflow: "scroll",
          }}
          className="row"
        >
          <div className="col-12">
            <div className="p-3">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
