import React, { useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { CrmContext } from "../../../../../../context/CrmContext";

export default function EntityPane() {
  const { contacts, company, companies, deals, serviceTickets } =
    useContext(CrmContext);

  console.log("company", company);

  return (
    <Tabs defaultActiveKey="new" id="justify-tab-example" justify>
      <Tab eventKey="new" title="Create New">
        <form className="row g-1">
          <div className="form-group">
            <label htmlFor="inputEmail4" className="form-label">
              Email
            </label>
            <input type="email" className="form-control" id="firstName" />
          </div>
          <div className="form-group">
            <label htmlFor="firstName" className="form-label">
              First name
            </label>
            <input type="text" className="form-control" id="firstName" />
          </div>
          <div className="form-group">
            <label htmlFor="lastName" className="form-label">
              Last name
            </label>
            <input type="text" className="form-control" id="lastName" />
          </div>
        </form>
      </Tab>
      <Tab eventKey="existing" title="Create Existing">
        <input type="search" className="form-control" placeholder="Search" />
        <div className="col">
          <ul className="list-group mt-2">
            {contacts.map((contact) => (
              <li className="mb-1 gap-1 d-flex" key={contact.id}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    company?.contacts?.find((c) => c.id === contact.id)
                      ? true
                      : false
                  }
                  id={contact.id}
                />
                <label htmlFor={contact.id}>
                  {" "}
                  {contact.first_name} {contact.last_name}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </Tab>
    </Tabs>
  );
}
