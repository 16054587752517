// import {
//   AddAndBindAnswers,
//   removeAnswer,
//   UpdateAnswer,
// } from "../components/store/quotations/QuotationSingle/TemplateSection/ServerActions.js";

import { Buffer } from "buffer";
const handleChangeAndUpdateAnswer = async (
  eventOrValue,
  originalId,
  index,
  fieldName,
  fieldType,
  optionTitle = null,
  item
) => {
  if (!item || !item.field_type) {
    console.error("Invalid item or field_type:", item);
    throw new Error("Invalid item or field_type");
  }

  let newValue;
  console.log("item", item);
  console.log("fieldType", fieldType);

  if (fieldName === "variables" && item.field_type !== "boolean") {
    if (["select", "radio"].includes(item.field_type)) {
      newValue = Object.entries(item.variables).reduce(
        (acc, [key, variable]) => {
          acc[key] = {
            ...variable,
            selected: variable.title === optionTitle,
            type: item.field_type,
          };
          return acc;
        },
        {}
      );
      console.log("newValue", newValue);
    } else if (item.field_type === "checkbox") {
      newValue = Object.entries(item.variables).reduce(
        (acc, [key, variable]) => {
          if (variable.title === optionTitle) {
            acc[key] = { ...variable, selected: !variable.selected };
          } else {
            acc[key] = variable;
          }
          return acc;
        },
        {}
      );
    }
  } else {
    newValue = eventOrValue.target ? eventOrValue.target.value : eventOrValue;
  }

  try {
    const updatedAnswer = await UpdateAnswer(
      { [fieldName]: newValue, fieldType: item.field_type },
      originalId
    );
    console.log("updatedAnswer", updatedAnswer);
    return updatedAnswer;
  } catch (error) {
    console.error("Update failed", error);
    throw error;
  }
};

const AddAndBindQuestions = async (selectedQuestionnaireId, newQuestion) => {
  console.log("selectedQuestionnaireId", selectedQuestionnaireId);
  try {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/questions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
        body: JSON.stringify({
          questionnaires: selectedQuestionnaireId,
          field_type: "text",
          new: newQuestion,
        }),
      }
    );
    const questions = await response.json();
    return questions; // Add this line
  } catch (error) {
    console.error("Error creating bulk answers:", error);
  }
};

const AddAndBindAnswers = async (selectedQuestionnaireId, id) => {
  console.log("selectedQuestionnaireId", selectedQuestionnaireId);
  try {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/answers`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
        body: JSON.stringify({
          questionnaireId: selectedQuestionnaireId,
          quotationId: id,
        }),
      }
    );
    const answers = await response.json();
    return answers; // Add this line
  } catch (error) {
    console.error("Error creating bulk answers:", error);
  }
};

const UpdateAnswer = async (valueObj, id) => {
  console.log("value", valueObj);
  console.log("id", id);
  try {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/answers/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
        body: JSON.stringify(valueObj),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Error: ${response.status} ${response.statusText} - ${errorData.message}`
      );
    }

    const answers = await response.json();
    console.log("Update response", answers);
    return answers;
  } catch (error) {
    console.error("Error updating answer:", error);
    throw error; // Ensure the error can be caught by the caller
  }
};

const updateAnswerOrder = async (sortedIds) => {
  console.log("Sending sorted IDs for update:", sortedIds); // Confirm the structure again

  try {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/answers/updateorder`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
        body: JSON.stringify(sortedIds),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update order on the server");
    }

    const updatedAnswers = await response.json();
    return updatedAnswers;
  } catch (error) {
    console.error("Error updating order:", error);
  }
};

const removeAnswer = async (answerId) => {
  console.log("answerId", answerId);
  try {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/answers/${answerId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to delete the answer");
    }
    return response;
  } catch (error) {
    console.error(`Error removing answer with ID ${answerId}:`, error);
  }
};

const removeQuestion = async (id) => {
  console.log("id", id);
  try {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/questions/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to delete the answer");
    }

    // return succes
  } catch (error) {
    console.error(`Error removing answer with ID ${id}:`, error);
  }
};

async function UpdateQuestionnaire(
  id,
  questions,
  questionnaireId,
  title,
  isRequired,
  field_type
) {
  let required;
  if (title === "required") {
    required = questions;
  }

  try {
    const questionnaireResponse = await fetch(
      `${
        import.meta.env.VITE_STRAPI_URL
      }/woostoremanager/questionnaires/${questionnaireId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
      }
    );

    console.log("questionnaireResponse", questionnaireResponse);

    if (!questionnaireResponse.ok) {
      console.error(
        "Failed to fetch the questionnaire:",
        questionnaireResponse.statusText
      );
      throw new Error("Failed to fetch the questionnaire");
    }

    const questionnaire = await questionnaireResponse.json();
    const existingQuestions = questionnaire.woostoremanager_questions || [];

    // if (title !== undefined && title !== questionnaire.title) {
    //   const updateTitleResponse = await fetch(
    //     `${
    //       import.meta.env.VITE_STRAPI_URL
    //     }/woostoremanager/questionnaires/${id}`,
    //     {
    //       method: "PUT",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
    //       },
    //       body: JSON.stringify({ title }),
    //     }
    //   );

    //   if (!updateTitleResponse.ok) {
    //     console.error(
    //       "Failed to update the questionnaire title:",
    //       updateTitleResponse.statusText
    //     );
    //     throw new Error("Failed to update the questionnaire title");
    //   }
    // }

    // console.log("updateTitleResponse", updateTitleResponse);

    for (const question of questions) {
      const existingQuestion = existingQuestions.find(
        (q) => q.id === question.id
      );

      if (existingQuestion) {
        const transformedOptions = question.options.map((option) => ({
          title: option.title,
          type: option.type,
          price: option.price,
          product: option.product,
          file: option.file,
        }));

        const updateQuestionResponse = await fetch(
          `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/questions/${
            question.id
          }`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
            },
            body: JSON.stringify({
              title: question.title,
              field_type: question.field_type,
              required: question.required,
              options: transformedOptions,
            }),
          }
        );

        if (!updateQuestionResponse.ok) {
          console.error(
            "Failed to update the question:",
            updateQuestionResponse.statusText
          );
          throw new Error("Failed to update the question");
        }
      }
    }
  } catch (error) {
    console.error("An unexpected error happened:", error);
  }
}

const updateImages = async (formData) => {
  try {
    // Get the file from formData
    const file = formData.get("files");
    console.log("file", file);

    // Ensure file is of type Blob
    if (!(file instanceof Blob)) {
      throw new Error("File is not a Blob");
    }

    // Create a new FormData instance for upload
    const uploadData = new FormData();
    uploadData.append("files", file, file.name);

    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/api/upload`,
      {
        method: "POST",
        body: uploadData,
        Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Upload error:", errorData);
      throw new Error("Failed to upload images");
    }

    const images = await response.json();
    console.log("images", images);

    if (!images.length) {
      throw new Error("No images uploaded");
    }

    return images; // Return the images array to be used in the questions state update
  } catch (error) {
    console.error("Error uploading images:", error);
    throw error;
  }
};

const UpdateQuestionnaireTitle = async (id, title) => {
  console.log("id", id);
  console.log("title", title);
  try {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/questionnaires/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
        body: JSON.stringify({ title }),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to update the questionnaire title");
    }
    return response;
  } catch (error) {
    console.error("Error updating the questionnaire title:", error);
  }
};

const updatedOptions = async (questionId, updatedOptions) => {
  try {
    const response = await fetch(
      `${
        import.meta.env.VITE_STRAPI_URL
      }/woostoremanager/questions/${questionId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
        body: JSON.stringify({
          options: updatedOptions,
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to update the question");
    }
    return response.json();
  } catch (error) {
    console.error("Error updating the question:", error);
  }
};

const updateQuestion = async (questionId, updates) => {
  console.log("updates", updates);
  try {
    const response = await fetch(
      `${
        import.meta.env.VITE_STRAPI_URL
      }/woostoremanager/questions/${questionId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
        body: JSON.stringify(updates),
      }
    );
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to update the question: ${errorData.message}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error updating the question:", error);
    throw error; // Ensure the error is thrown so it can be handled by the caller
  }
};

export {
  handleChangeAndUpdateAnswer,
  AddAndBindQuestions,
  UpdateQuestionnaire,
  removeQuestion,
  AddAndBindAnswers,
  removeAnswer,
  UpdateAnswer,
  updateAnswerOrder,
  updateImages,
  updatedOptions,
  updateQuestion,
  UpdateQuestionnaireTitle,
};
