import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { handleChangeAndUpdateAnswer } from "../../../../../../helpers/Questionnaires.jsx";

export default function Number({ index, item }) {
  const { register, control } = useFormContext();
  console.log("YOOOO", item);

  return (
    <div>
      <Form.Control
        {...register(`answers.${index}.number`)}
        placeholder="Answer"
        name="answer"
        type="number"
        onChange={(e) =>
          handleChangeAndUpdateAnswer(
            e,
            item.originalId,
            index,
            item.field_type,
            "answer"
          )
        }
        defaultValue={item.answer || ""} // Provide an empty string as a fallback
        className="form-control w-full"
      />
      <Form.Control
        {...register(`answers.${index}.price`)}
        placeholder="Price"
        name="price"
        type="number"
        onChange={(e) => {
          const newPrice = e.target.value;
          handleChangeAndUpdateAnswer(
            { price: parseInt(newPrice) }, // Pass an object with the property `price`
            item.originalId,
            item,
            index,
            item.field_type,
            "price"
          );
        }}
        defaultValue={item.price || ""} // Provide an empty string as a fallback
        className="form-control w-full"
      />
    </div>
  );
}
