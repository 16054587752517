import React from "react";
import { FaSearch } from "react-icons/fa";

export default function Search() {
  return (
    <div className="d-flex gap-1">
      <input type="text" className="form-control" placeholder="Search..." />
      <button className="btn btn-secondary p-1 text-white">
        <FaSearch />
      </button>
    </div>
  );
}
