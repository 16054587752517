import React, { useContext, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import { CrmContext } from "../../../../../../../context/CrmContext.jsx";
import CorrenspondenceEmailActions from "./CorrespondenceEmailActions.jsx";
import CorrenspondenceActions from "./CorrespondenceActions.jsx";
import Composer from "./Composer.jsx";
import LinkEntities from "./LinkEntities.jsx";
import "./correspondence.scss";

export default function Correspondence() {
  const { emails } = useContext(CrmContext);
  console.log(emails);
  const location = useLocation();
  const { id: contactId } = useParams();
  // get id from url /contacts/submenu/companies/1#correspondence
  const singleId = location.pathname.split("/").pop();

  const [modalShow, setModalShow] = useState(false);

  const isCompanyPage = location.pathname.includes("companies");
  const isContactPage = location.pathname === `/contacts/${contactId}`;

  // Filter emails based on the current contact or company ID
  const filteredEmails =
    emails?.filter((email) => {
      if (isContactPage) {
        return email?.contacts?.some(
          (contact) => contact?.id === parseInt(contactId)
        );
      } else if (isCompanyPage) {
        return email?.companies?.some(
          (company) => company?.id === parseInt(singleId)
        );
      }
      return false;
    }) || [];

  // Build email threads by linking emails with their parent inReplyTo and uuid
  const emailThreads = filteredEmails.reduce((acc, email) => {
    if (email?.inReplyTo) {
      const parentEmail = filteredEmails.find(
        (e) => e?.uuid === email?.inReplyTo || e?.uuid === email?.parentUuid
      );

      if (parentEmail) {
        acc[parentEmail.uuid] = acc[parentEmail.uuid] ?? [parentEmail];
        acc[parentEmail.uuid].push(email);
      }
    } else {
      acc[email?.uuid] = acc[email?.uuid] ?? [];
      acc[email?.uuid].push(email);
    }

    return acc;
  }, {});

  const [selectedThreadId, setSelectedThreadId] = useState(() => {
    const threadKeys = Object.keys(emailThreads || {});
    return threadKeys.length > 0 ? threadKeys[0] : null;
  });

  const handleEmailClick = (threadId) => {
    setSelectedThreadId(threadId);
  };

  // Function to determine the active tab
  const getActiveTab = () => {
    if (location.hash.includes("correspondence&all-mail")) return "all-mail";
    if (location.hash.includes("correspondence&unread")) return "unread";
    if (location.hash.includes("correspondence&send")) return "send";
    return "all-mail"; // Default to "all-mail"
  };

  const activeTab = getActiveTab();

  return (
    <div className="row">
      <div className="col-12 bg-white p-2">
        <div className="row">
          <div className="col-5">
            <div className="mb-2">
              <CorrenspondenceActions />
            </div>
            <h2
              style={{
                fontWeight: "bold",
              }}
              className="fs-5"
            >
              Inbox
            </h2>
            <div className="d-flex justify-content-between align-items-center mb-2 border-bottom border-1">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      activeTab === "all-mail" ? "active" : ""
                    }`}
                    aria-current="page"
                    to="#correspondence&all-mail"
                  >
                    All mail
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      activeTab === "unread" ? "active" : ""
                    }`}
                    to="#correspondence&unread"
                  >
                    Unread
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      activeTab === "send" ? "active" : ""
                    }`}
                    to="#correspondence&send"
                  >
                    Send
                  </Link>
                </li>
              </ul>
            </div>
            <input
              type="text"
              className="form-control mb-1"
              placeholder="Search"
            />
            {Object.keys(emailThreads)
              ?.sort((a, b) => {
                const latestA = emailThreads[a][0].date;
                const latestB = emailThreads[b][0].date;
                return new Date(latestB) - new Date(latestA);
              })
              ?.filter((threadId) => {
                const emailsInThread = emailThreads[threadId];
                if (activeTab === "unread") {
                  return emailsInThread.some((email) => email.isRead === false);
                } else if (activeTab === "send") {
                  return emailsInThread.some((email) => email.type === "send");
                } else {
                  return true;
                }
              })
              ?.map((threadId) => {
                const emailsInThread = emailThreads[threadId];
                const latestEmail = emailsInThread[0];
                return (
                  <div
                    onClick={() => handleEmailClick(threadId)}
                    className={
                      `mb-1 border rounded-2 p-1 email-card` +
                      (selectedThreadId === threadId ? " selected" : "")
                    }
                    key={threadId}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <h4 className="fs-5">Xander van Dijck</h4>
                      </div>

                      {emailsInThread.length > 1 && (
                        <span
                          className="badge bg-secondary ms-2"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {emailsInThread.length}
                        </span>
                      )}
                    </div>
                    <p
                      style={{
                        fontSize: "0.8rem",
                      }}
                    >
                      {latestEmail.date}
                    </p>
                    <p>{latestEmail.subject}</p>
                    {parse(latestEmail?.html?.slice(0, 30) + "...")}
                    <div className="d-flex justify-content-end align-items-center">
                      <div
                        className="d-flex linkentities"
                        onClick={() => setModalShow(true)}
                      >
                        <p style={{ paddingRight: "5px" }}>
                          {" "}
                          {latestEmail.contacts.length +
                            latestEmail.companies.length +
                            latestEmail.deals.length +
                            latestEmail.orders.length +
                            latestEmail.services.length}{" "}
                        </p>
                        <p>Koppelingen</p>
                      </div>
                    </div>
                    <LinkEntities
                      email={latestEmail}
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                );
              })}
          </div>
          <div className="col-7">
            {selectedThreadId && (
              <div
                className="border rounded-2 ps-2 pe-2 pb-2 pt-1"
                style={{ overflow: "auto", height: "80vh" }}
              >
                {emailThreads[selectedThreadId]?.map((email, index) => (
                  <div key={index} className="mb-4 border-bottom border-1 pb-2">
                    <CorrenspondenceEmailActions email={email} />
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="fs-5">Xander van Dijck</h4>
                      <p
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        {email.date}
                      </p>
                    </div>
                    <p>{email.subject}</p>
                    <div
                      style={{
                        overflow: "auto",
                      }}
                    >
                      {parse(email.html)}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {location.hash.includes("compose=new") && <Composer />}
    </div>
  );
}
