import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { StoreContext } from "../../../../../context/StoreContext.jsx";
import { CrmContext } from "../../../../../context/CrmContext.jsx";

import { useLocation } from "react-router-dom";

export default function CreateEntry(props) {
  const { addProduct, strapiProducts, addQuestionnaire, addQuotation } =
    useContext(StoreContext);
  const { createContact, createCompany, createDeal } = useContext(CrmContext);

  console.log("strapiProducts", strapiProducts);
  const location = useLocation();

  // trigger addProduct in context and close the modal just send form data
  const handleAddProduct = (e) => {
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    addProduct(e);
    props.onHide();
  };

  const handleAddQuestionnaire = (e) => {
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    addQuestionnaire(e);
    props.onHide();
  };

  const handleAddQuotation = (e) => {
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    addQuotation(e);
    props.onHide();
  };

  const handleAddContact = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    console.log("data", data);
    createContact(data);
    props.onHide();
  };

  const handleAddCompany = (e) => {
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    createCompany(data);
    props.onHide();
  };

  const handleAddDeal = (e) => {
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    createDeal(data);
    props.onHide();
  };

  const isProduct = location.pathname.includes("products");
  const isQuestionnaire = location.pathname.includes("questionnaires");
  const isQuotation = location.pathname.includes("quotations");
  const isContact = location.pathname === "/contacts";
  const isCompany = location.pathname === "/contacts/submenu/companies";
  const isDeal = location.pathname === "/contacts/submenu/deals";

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add new entry
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={
            isProduct
              ? handleAddProduct
              : isQuestionnaire
              ? handleAddQuestionnaire
              : isQuotation
              ? handleAddQuotation
              : isContact
              ? handleAddContact
              : isCompany
              ? handleAddCompany
              : isDeal
              ? handleAddDeal
              : null
          }
        >
          {/* Form with a title, description, and submit button */}
          <div className="mb-2">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="name"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              name="description"
              className="form-control"
              id="description"
              rows="3"
            ></textarea>
          </div>

          {isQuestionnaire || isQuotation ? (
            <div className="mb-2">
              <label htmlFor="products" className="form-label">
                Select a product
              </label>
              <select className="form-select" id="products" name="products">
                {strapiProducts?.products?.map((product) => (
                  <option value={product.id}>
                    {product?.productInformation?.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
