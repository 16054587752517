import React from "react";
import { FaRegPenToSquare } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { GoTasklist } from "react-icons/go";
import { FaRegCalendar } from "react-icons/fa";

export default function ContactActions() {
  const actions = [
    {
      icon: <FaRegPenToSquare />,
      title: "Note",
    },
    {
      icon: <MdOutlineMailOutline />,
      title: "Email",
    },
    {
      icon: <FaPhoneAlt />,
      title: "Call",
    },
    {
      icon: <GoTasklist />,
      title: "Task",
    },
    {
      icon: <FaRegCalendar />,
      title: "Meeting",
    },
  ];

  return (
    <div className="row">
      {actions.map((action, index) => (
        <div className="col d-flex flex-column align-items-center" key={index}>
          <div
            className="d-flex justify-content-center bg-qwerty rounded-circle align-items-center"
            style={{ width: "30px", height: "30px" }}
          >
            {action.icon}
          </div>
          <p
            style={{
              fontSize: "0.8rem",
            }}
            className="d-flex justify-content-center"
          >
            {action.title}
          </p>
        </div>
      ))}
    </div>
  );
}
