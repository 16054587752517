import React, { useContext, useState } from "react";
import { CrmContext } from "../../../../../../../../context/CrmContext.jsx";

export default function Deals({ checkedEntities, handleCheckboxChange }) {
  const { deals } = useContext(CrmContext);
  const [search, setSearch] = useState("");

  return (
    <div
      className="tab-pane fade show active"
      id="deals"
      role="tabpanel"
      aria-labelledby="deals-tab"
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <p className="card-text">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Deals"
                  onChange={(e) => setSearch(e.target.value)}
                  style={{
                    marginBottom: "5px",
                  }}
                />
                <ul className="list-group">
                  {deals
                    .filter((deal) => {
                      return deal.name
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    })
                    .map((deal) => (
                      <li className="border p-1 rounded d-flex gap-1 align-items-center">
                        <input
                          type="checkbox"
                          id={deal.id}
                          className="form-check-input"
                          checked={checkedEntities.deals.includes(deal.id)}
                          onChange={() => handleCheckboxChange("deals", deal)}
                        />
                        <label htmlFor={deal.id} className="m-0">
                          {deal.name}
                        </label>
                      </li>
                    ))}
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
