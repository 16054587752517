import React, { createContext, useContext } from "react";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { useAuth } from "./AuthContext";
import LoadingIndicator from "../components/utils/LoadingIndicator";
import { useLocation, useParams } from "react-router-dom";

// Create a QueryClient instance
const queryClient = new QueryClient();

const CrmContext = createContext();

export const useCrm = () => useContext(CrmContext);

export const CrmProvider = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();
  const { id } = useParams();
  // get id from /contacts/submenu/companies/1
  const singleId = location.pathname.split("/")[4];
  // get id on correspondence page
  ///contacts/submenu/companies/1#correspondence
  const singleIdCorrespondence = location.pathname.split("/")[4];
  console.log("singleId", singleId);

  // Define the contact page path
  const contactPagePath = `/crm`;

  // Fetch customer emails from the API with react-query
  const fetchEmails = async () => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/customercenter/contact-emails/${
          id || singleIdCorrespondence
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      const result = await response.json();
      return result.emailsFromDB; // Assuming the response structure contains emails
    }
    return []; // Return empty array if the condition is not met
  };

  // Fetch contact from the API
  const fetchContact = async () => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${
          import.meta.env.VITE_STRAPI_URL
        }/customercenter/contacts/${singleId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      const result = await response.json();
      return result.contact; // Assuming the response structure contains contact
    }
    return {}; // Return empty object if the condition is not met
  };

  // Create a new contact
  const createContact = async (data) => {
    console.log("data", data);
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/customercenter/contacts`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      return result;
    }
  };

  // Fetch all contacts from the API
  const fetchContacts = async () => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/customercenter/contacts`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      const result = await response.json();
      return result.contacts; // Assuming the response structure contains contacts
    }
    return []; // Return empty array if the condition is not met
  };

  // Fetch companies from the API
  const fetchCompanies = async () => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/customercenter/companies`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      const result = await response.json();
      return result;
    }
  };

  // fetch a single company from the API
  const fetchCompany = async () => {
    try {
      if (user?.email && location.pathname.includes(contactPagePath)) {
        const response = await fetch(
          `${
            import.meta.env.VITE_STRAPI_URL
          }/customercenter/companies/${singleId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
            },
          }
        );
        const result = await response.json();
        return result || {};
        console.log("result", result);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Create a new company
  const createCompany = async (data) => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/customercenter/companies`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      return result;
    }
  };

  // fetch deals from the API
  const fetchDeals = async () => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/customercenter/deals`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      const result = await response.json();
      return result;
    }
  };

  // fetch a single deal from the API
  const fetchDeal = async () => {
    try {
      if (user?.email && location.pathname.includes(contactPagePath)) {
        const response = await fetch(
          `${import.meta.env.VITE_STRAPI_URL}/customercenter/deals/${singleId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
            },
          }
        );
        const result = await response.json();
        return result || {};
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Create a new deal
  const createDeal = async (data) => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/customercenter/deals`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      return result;
    }
  };

  // fetch service-tickets from the API
  const fetchServiceTickets = async () => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/customercenter/service-tickets`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      const result = await response.json();
      return result;
    }
  };

  // fetch a single service-ticket from the API
  const fetchServiceTicket = async () => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${
          import.meta.env.VITE_STRAPI_URL
        }/customercenter/service-tickets/${singleId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      const result = await response.json();
      return result;
    }
  };

  // Send email to the contact
  const handleSendEmail = async (data, content) => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/customercenter/send-email`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data, content),
        }
      );
      const result = await response.json();
      return result;
    }
  };

  // Delete an entry
  const deleteEntry = async (type, id) => {
    if (user?.email && location.pathname.includes(contactPagePath)) {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/customercenter/${type}/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      const result = await response.json();
      return result;
    }
  };

  const {
    data: emails,
    isLoading: emailsIsLoading,
    error: emailsError,
  } = useQuery(
    ["contact-emails", user?.email, id || singleIdCorrespondence],
    fetchEmails,
    {
      enabled: (!!user?.email && !!id) || !!singleIdCorrespondence,
    }
  );

  const {
    data: contact,
    isLoading: contactIsLoading,
    error: contactError,
  } = useQuery(["contact", user?.email, singleId], fetchContact, {
    enabled: !!user?.email && !!singleId,
  });

  const {
    data: contacts,
    isLoading: contactsIsLoading,
    error: contactsError,
  } = useQuery(["contacts", user?.email], fetchContacts, {
    enabled: !!user?.email,
  });

  const {
    data: companies,
    isLoading: companiesIsLoading,
    error: companiesError,
  } = useQuery(["companies", user?.email], fetchCompanies, {
    enabled: !!user?.email,
  });

  const {
    data: company,
    isLoading: companyIsLoading,
    error: companyError,
  } = useQuery(["company", user?.email, singleId], fetchCompany, {
    enabled: !!user?.email && !!singleId,
  });

  const {
    data: deals,
    isLoading: dealsIsLoading,
    error: dealsError,
  } = useQuery(["deals", user?.email], fetchDeals, {
    enabled: !!user?.email,
  });

  const {
    data: deal,
    isLoading: dealIsLoading,
    error: dealError,
  } = useQuery(["deal", user?.email, singleId], fetchDeal, {
    enabled: !!user?.email && !!singleId,
  });

  const {
    data: serviceTickets,
    isLoading: serviceTicketsIsLoading,
    error: serviceTicketsError,
  } = useQuery(["service-tickets", user?.email], fetchServiceTickets, {
    enabled: !!user?.email,
  });

  const {
    data: serviceTicket,
    isLoading: serviceTicketIsLoading,
    error: serviceTicketError,
  } = useQuery(["service-ticket", user?.email, singleId], fetchServiceTicket, {
    enabled: !!user?.email && !!singleId,
  });

  // Show loading indicator while fetching data
  if (
    emailsIsLoading ||
    contactIsLoading ||
    contactsIsLoading ||
    companiesIsLoading ||
    companyIsLoading ||
    dealsIsLoading ||
    dealIsLoading ||
    serviceTicketsIsLoading ||
    serviceTicketIsLoading
  ) {
    return <LoadingIndicator />;
  }

  // Show error if any query fails
  if (
    emailsError ||
    contactError ||
    contactsError ||
    companiesError ||
    companyError ||
    dealsError ||
    dealError ||
    serviceTicketsError ||
    serviceTicketError
  ) {
    return <div>Error fetching data</div>;
  }

  console.log("emails", emails);
  console.log("contact", contact);
  console.log("contacts", contacts);
  console.log("companies", companies);
  console.log("company", company);
  console.log("deals", deals);
  console.log("deal", deal);
  console.log("serviceTickets", serviceTickets);

  return (
    <CrmContext.Provider
      value={{
        emails,
        contact,
        contacts,
        companies,
        company,
        deals,
        deal,
        createContact,
        createCompany,
        createDeal,
        serviceTickets,
        serviceTicket,
        handleSendEmail,
        deleteEntry,
      }}
    >
      {children}
    </CrmContext.Provider>
  );
};

const App = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <CrmProvider>{children}</CrmProvider>
  </QueryClientProvider>
);

export default App;
export { CrmContext };
