import React, { useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { CrmContext } from "../../../../../../context/CrmContext";

export default function Service() {
  const { contact } = useContext(CrmContext);

  return (
    <div className="row">
      <div className="col-12 bg-white p-2">
        <h3 className="fs-5">Service</h3>
        <table className="table mt-1">
          <thead>
            <tr>
              <th scope="col">Service Name</th>
              <th scope="col">Status</th>
              <th scope="col">Owner</th>
              <th scope="col">Created at</th>
            </tr>
          </thead>
          <tbody>
            {contact?.service_tickets?.map((ticket) => (
              <tr key={ticket?.id}>
                <td>
                  <Link to={`/contacts/submenu/service/${ticket?.id}`}>
                    {ticket?.subject}
                  </Link>
                </td>
                <td>{ticket?.status}</td>
                <td>
                  {ticket?.user?.first_name + " " + ticket?.user?.last_name ||
                    ticket?.user?.username}
                </td>
                <td>{moment(ticket?.created_at).format("YYYY-MM-DD")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
