"use client";
import { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Form, Button, Dropdown, InputGroup } from "react-bootstrap";
import AllCountries from "./AllCountries"; // Importing the AllCountries array

export default function AddressSection() {
  const [countrySelect, setCountrySelect] = useState("");
  const { register, control } = useFormContext();

  return (
    <div>
      <h5 className="fw-bold">Address</h5>
      <div className="mt-2">
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <Dropdown onSelect={(eventKey) => field.onChange(eventKey)}>
              <Dropdown.Toggle variant="outline-secondary" className="w-100">
                {field.value || "Select a country"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="w-100"
                style={{ maxHeight: "200px", overflowY: "scroll" }}
              >
                {AllCountries.map((country, index) => (
                  <Dropdown.Item
                    eventKey={country.name}
                    key={index}
                    onSelect={(e) => {
                      field.onChange(e);
                      setCountrySelect(country.name);
                    }}
                  >
                    {country.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        />
      </div>
      <div className="mt-2">
        <Form.Control {...register("billingcompany")} placeholder="Company" />
      </div>
      <div className="mt-2">
        <Form.Control {...register("billingaddress")} placeholder="Address" />
      </div>
      <div className="mt-2">
        <Form.Control
          {...register("billingstreetnumber")}
          placeholder="Street number"
        />
      </div>
      <div className="d-flex gap-2 mt-2">
        <Form.Control {...register("billingzipcode")} placeholder="Zipcode" />
        <Form.Control {...register("billingcity")} placeholder="City" />
      </div>
      <div className="mt-2">
        <Form.Control {...register("billingphone")} placeholder="Phonenumber" />
      </div>
    </div>
  );
}
