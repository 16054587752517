import React, { useContext } from "react";
import { CrmContext } from "../../../../../../context/CrmContext";

export default function CompanyForm() {
  const { company } = useContext(CrmContext);

  return (
    <div className="row">
      <h3 className="fs-4">About this company</h3>
      <form className="row">
        <div className="col-12">
          <div className="form-group mt-1">
            <label htmlFor="website" className="form-label">
              Domain name
            </label>
            <input
              type="text"
              className="form-control"
              id="website"
              defaultValue={company?.website}
            />
          </div>
          <div className="form-group mt-1">
            <label htmlFor="industry" className="form-label">
              Sector
            </label>
            <input
              type="text"
              className="form-control"
              id="industry"
              defaultValue={company?.industry}
            />
          </div>
          <div className="form-group mt-1">
            <label htmlFor="owner" className="form-label">
              Owner
            </label>
            <select className="form-select" id="owner">
              {[company?.owner].map((owner) => (
                <>
                  {owner === null ? (
                    <option value="1">No owner</option>
                  ) : (
                    <option value="1">
                      {owner?.first_name + " " + owner?.last_name ||
                        owner?.username ||
                        "Geen eigenaar"}
                    </option>
                  )}
                </>
              ))}
            </select>
          </div>
          <div className="form-group mt-1">
            <label htmlForm="type" className="form-label">
              Type
            </label>
            <select className="form-select" id="type">
              <option value="1">Klant</option>
              <option value="2">Prospect</option>
              <option value="3">Leads</option>
            </select>
          </div>
          <div className="form-group mt-1">
            <label htmlForm="city" className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              defaultValue={company?.city}
            />
          </div>
          <div className="form-group mt-1">
            <label htmlFor="region" className="form-label">
              Region
            </label>
            <input
              type="text"
              className="form-control"
              id="region"
              defaultValue={company?.region}
            />
          </div>
          <div className="form-group mt-1">
            <label htmlFor="zip" className="form-label">
              Zip code
            </label>
            <input
              type="text"
              className="form-control"
              id="zip"
              defaultValue={company?.zip}
            />
          </div>
          <div className="form-group mt-1">
            <label htmlFor="company-size" className="form-label">
              Company size
            </label>
            <input
              type="text"
              className="form-control"
              id="company-size"
              defaultValue={company?.company_size}
            />
          </div>
          <div className="form-group mt-1">
            <label htmlFor="turnover" className="form-label">
              Turnover
            </label>
            <input
              type="text"
              className="form-control"
              id="turnover"
              defaultValue={company?.turnover}
            />
          </div>
          <div className="form-group mt-1">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              className="form-control"
              id="description"
              rows="3"
              defaultValue={company?.description}
            ></textarea>
          </div>
        </div>
      </form>
    </div>
  );
}
