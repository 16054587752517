import React from "react";
import { useFormContext } from "react-hook-form";
import { handleChangeAndUpdateAnswer } from "../../../../../../helpers/Questionnaires.jsx";

export default function Text({ index, item }) {
  const { register } = useFormContext();

  console.log("Text.jsx", item);

  return (
    <>
      <input
        {...register(`answers.${index}.text`)}
        placeholder="Answer"
        name="answer"
        type="text"
        onChange={(e) =>
          handleChangeAndUpdateAnswer(
            e,
            item.originalId,
            index,
            "text",
            item.field_type,
            null,
            item
          )
        }
        defaultValue={item.answer || ""}
        className="form-control w-full mt-2"
      />
      <input
        {...register(`answers.${index}.price`)}
        placeholder="Price"
        name="price"
        type="number"
        onChange={(e) => {
          const newPrice = e.target.value;
          handleChangeAndUpdateAnswer(
            { target: { value: parseInt(newPrice) } }, // Pass an object with the property `price`
            item.originalId,
            index,
            "price",
            item.field_type,
            null,
            item
          );
        }}
        defaultValue={item.price || ""} // Provide an empty string as a fallback
        className="form-control w-full mt-2"
      />
    </>
  );
}
