import React from "react";
import Search from "./Search";
import Nav from "./Nav";

export default function Header() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between px-3 py-2 text-white">
            <Search />
            <Nav />
          </div>
        </div>
      </div>
    </div>
  );
}
