import React, { useContext, useState } from "react";
import { QuotationContext } from "../../../../../../context/QuotationContext";
import Product from "./Product";
import { CiImageOn } from "react-icons/ci";
import { FaWindowMinimize } from "react-icons/fa";
import MediaLibrary from "../../../../../../components/dashboard/utils/media/MediaLibrary.jsx";
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import SortableOptions from "../dndkit/SortableOptions"; // Ensure this import path is correct

export default function Options({ question, index, products }) {
  const {
    handleRemoveOptionFromQuestion,
    handleAddOptionToQuestion,
    handleOptionChange,
    handleInputChange,
    handleOptionOrderChange,
  } = useContext(QuotationContext);

  const [modalShow, setModalShow] = useState(false);
  const [currentOptionKey, setCurrentOptionKey] = useState(null);
  const [newOption, setNewOption] = useState({
    title: "",
    price: "",
    file: null,
    order: Object.keys(question.variables).length, // Set initial order
  });

  const handleNewOptionChange = (key, value) => {
    setNewOption((prev) => ({ ...prev, [key]: value }));
  };

  const handleAddNewOption = () => {
    handleAddOptionToQuestion(question.id, newOption);
    setNewOption({
      title: "",
      price: "",
      file: null,
      order: Object.keys(question.variables).length, // Update order
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.stopPropagation();
    }
  };

  const handleImageClick = (optionKey) => {
    setCurrentOptionKey(optionKey);
    setModalShow(true);
  };

  const customActivationConstraint = (event) => {
    if (
      event.target.tagName === "INPUT" ||
      event.target.tagName === "TEXTAREA" ||
      event.key === " "
    ) {
      return false;
    }
    return { delay: 250, tolerance: 5 };
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: customActivationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    }),
    useSensor(PointerSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over && active && active.id !== over.id) {
      const oldIndex = Object.keys(question.variables).indexOf(active.id);
      const newIndex = Object.keys(question.variables).indexOf(over.id);
      console.log("oldIndex", oldIndex);
      console.log("newIndex", newIndex);
      if (oldIndex !== -1 && newIndex !== -1) {
        const newVariables = arrayMove(
          Object.entries(question.variables),
          oldIndex,
          newIndex
        ).map((item, index) => ({
          ...item[1],
          order: index,
        }));
        const reorderedVariables = Object.fromEntries(
          newVariables.map((item) => [item.order, item])
        );
        handleOptionOrderChange(question.id, reorderedVariables);
      }
    }
  };

  return (
    <div className="w-100">
      <textarea
        className="form-control mb-1"
        rows={5}
        value={question.title}
        onChange={(e) => handleInputChange(e, question.id)}
        placeholder={`Question #${index + 1}`}
        onKeyDown={handleKeyDown}
      />
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext
          items={Object.keys(question.variables)}
          strategy={verticalListSortingStrategy}
        >
          {/* sort by order field */}
          {Object.keys(question?.variables || {})
            .sort(
              (a, b) =>
                question.variables[a].order - question.variables[b].order
            )
            .map((optionKey) => {
              const option = question.variables[optionKey];
              return (
                <SortableOptions key={optionKey} id={optionKey}>
                  <div className="d-flex align-items-center gap-2">
                    <input
                      className="form-control"
                      value={option.title}
                      onKeyDown={handleKeyDown}
                      onChange={(e) =>
                        handleOptionChange(
                          question.id,
                          optionKey,
                          "title",
                          e.target.value
                        )
                      }
                      placeholder="Option"
                    />
                    <input
                      type="number"
                      className="form-control"
                      value={option.price}
                      onKeyDown={handleKeyDown}
                      onChange={(e) =>
                        handleOptionChange(
                          question.id,
                          optionKey,
                          "price",
                          e.target.value
                        )
                      }
                      placeholder="Price"
                    />
                    <label
                      htmlFor={`picture-${question.id}-${optionKey}`}
                      className="cursor-pointer"
                    >
                      {option.file ? (
                        <div className="w-10 h-10 border rounded">
                          <img
                            onClick={() => handleImageClick(optionKey)}
                            src={option.file.url || option.file.source_url}
                            alt={option.title}
                            width={40}
                            height={40}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      ) : (
                        <div
                          className="w-10 h-10 border rounded d-flex align-items-center justify-content-center"
                          onClick={() => handleImageClick(optionKey)}
                        >
                          <CiImageOn size={40} />
                        </div>
                      )}
                    </label>
                    <button
                      className="btn"
                      onClick={() =>
                        handleRemoveOptionFromQuestion(question.id, optionKey)
                      }
                    >
                      <FaWindowMinimize size={10} />
                    </button>
                  </div>
                </SortableOptions>
              );
            })}
        </SortableContext>
      </DndContext>
      <div className="d-flex align-items-center gap-2 mt-1 mb-1">
        <input
          className="form-control"
          value={newOption.title}
          onChange={(e) => handleNewOptionChange("title", e.target.value)}
          placeholder="Title"
          onKeyDown={handleKeyDown}
        />
        <input
          type="number"
          className="form-control"
          value={newOption.price}
          onChange={(e) => handleNewOptionChange("price", e.target.value)}
          placeholder="Price"
          onKeyDown={handleKeyDown}
        />
        <button
          className="btn btn-outline-primary"
          onClick={handleAddNewOption}
        >
          +
        </button>
      </div>
      <Product question={question} products={products} />
      <MediaLibrary
        show={modalShow}
        onHide={() => setModalShow(false)}
        onInsert={(media) => {
          handleOptionChange(question.id, currentOptionKey, "file", media);
          setModalShow(false);
        }}
      />
    </div>
  );
}
