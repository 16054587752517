import React, { createContext, useState, useEffect } from "react";
import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { Buffer } from "buffer";
import { useAuth } from "./AuthContext";
import { useLocation, useParams } from "react-router-dom";
import LoadingIndicator from "../components/utils/LoadingIndicator";
import { getDataKeyFromPathname } from "../helpers/DeterminePath.jsx";

// Create a QueryClient instance
const queryClient = new QueryClient();

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [value, setValue] = useState("Default Value");
  const [isPending, setIsPending] = useState(false);
  const [repeaterFieldsAcf, setRepeaterFieldsAcf] = useState([]);
  const [reportsData, setReportsData] = useState([]); // Rename this state variable
  const [dateRange, setDateRange] = useState({ dateMin: "", dateMax: "" }); // Date range state
  const location = useLocation();
  const { user } = useAuth();
  const [entryIds, setEntryIds] = useState([]);
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [productFieldsRes, setProductFields] = useState(false);
  const [customerFieldsRes, setCustomerFields] = useState(false);
  const [orderFieldsRes, setOrderFields] = useState(false);

  const [productFieldsPending, setProductFieldsPending] = useState(false);
  const [customerFieldsPending, setCustomerFieldsPending] = useState(false);
  const [orderFieldsPending, setOrderFieldsPending] = useState(false);

  const [productFieldsError, setProductFieldsError] = useState(false);
  const [customerFieldsError, setCustomerFieldsError] = useState(false);
  const [orderFieldsError, setOrderFieldsError] = useState(false);

  const determineStorageKey = (pathname) => {
    if (pathname.includes("products")) return "selectedFieldsProducts";
    if (pathname.includes("orders")) return "selectedFieldsOrders";
    if (pathname.includes("customers")) return "selectedFieldsCustomers";
    return "selectedFields";
  };

  const [selectedFields, setSelectedFields] = useState(() => {
    const storageKey = determineStorageKey(location.pathname);
    const savedFields = localStorage.getItem(storageKey);
    return savedFields ? JSON.parse(savedFields) : [];
  });

  useEffect(() => {
    const storageKey = determineStorageKey(location.pathname);
    const savedFields = localStorage.getItem(storageKey);
    setSelectedFields(savedFields ? JSON.parse(savedFields) : []);
  }, [location.pathname]);

  useEffect(() => {
    const storageKey = determineStorageKey(location.pathname);
    localStorage.setItem(storageKey, JSON.stringify(selectedFields));
  }, [selectedFields, location.pathname]);

  // WooCommerce API credentials
  const username = import.meta.env.VITE_WOOCOMMERCE_USERNAME;
  const password = import.meta.env.VITE_WOOCOMMERCE_SECRET;
  const authBase64 = Buffer.from(`${username}:${password}`).toString("base64");

  // Wordpress API credentials
  const wpUsername = import.meta.env.VITE_APPLICATION_USERNAME;
  const wpPassword = import.meta.env.VITE_APPLICATION_PASSWORD;
  const wpAuthBase64 = Buffer.from(`${wpUsername}:${wpPassword}`).toString(
    "base64"
  );

  const fetchWoocommerceData = async (authBase64, pathname) => {
    const dataKey = getDataKeyFromPathname(pathname);

    let endpoint;
    if (dataKey === "products") {
      endpoint = `/api/wp-json/wc/v3/products`;
    } else if (dataKey?.includes("attributes/terms")) {
      const termId = pathname.split("/").pop();
      endpoint = `/api/wp-json/wc/v3/products/attributes/${termId}/terms`;
    } else if (pathname === "/orders") {
      endpoint = `/api/wp-json/wc/v3/orders`;
    } else {
      endpoint = `/api/wp-json/wc/v3/products/${dataKey}`;
    }

    console.log("endpoint", endpoint);
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Basic ${authBase64}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  };
  const addEntity = async (pathname, payload) => {
    const dataKey = getDataKeyFromPathname(pathname);
    let endpoint;

    if (dataKey === "products") {
      endpoint = `/api/wp-json/wc/v3/products`;
    } else if (dataKey?.includes("attributes/terms")) {
      const termId = pathname.split("/").pop();
      endpoint = `/api/wp-json/wc/v3/products/attributes/${termId}/terms`;
    } else {
      endpoint = `/api/wp-json/wc/v3/products/${dataKey}`;
    }

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${authBase64}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    await queryClient.invalidateQueries("data");
    return response.json();
  };

  const removeEntity = async (pathname, id) => {
    console.log("pathname", pathname);
    const dataKey = getDataKeyFromPathname(pathname);
    let endpoint;

    if (dataKey === "products") {
      endpoint = `/api/wp-json/wc/v3/products/${id}`;
    } else if (dataKey?.includes("attributes/terms")) {
      const termId = pathname.split("/").pop();
      endpoint = `/api/wp-json/wc/v3/products/attributes/${termId}/terms/${id}?force=true`;
    } else {
      endpoint = `/api/wp-json/wc/v3/products/${dataKey}/${id}?force=true`;
    }

    const response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${authBase64}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    await queryClient.invalidateQueries("data");
    return response.json();
  };

  const productAcfFields = async (id) => {
    try {
      setIsPending(true);
      const response = await fetch(`/api/wp-json/wp/v2/acf-fields/${id}`, {
        headers: {
          Authorization: `Basic ${wpAuthBase64}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setIsPending(false);
      return response.json();
    } catch (error) {
      console.error("Error fetching product ACF fields:", error);
      setIsPending(false);
      throw error;
    }
  };

  function handleAcfField(acfFields) {
    // console.log("acfFields", acfFields);
    setRepeaterFieldsAcf(acfFields);
  }

  const fetchProducts = async () => {
    const response = await fetch(`/api/wp-json/wc/v3/products`, {
      headers: {
        Authorization: `Basic ${authBase64}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const fetchWooCommerceFields = async () => {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/api/woo-types?populate=*`,
      {
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  // const addProduct = async (event) => {
  //   event.preventDefault();
  //   const formData = new FormData(event.target);
  //   const payload = Object.fromEntries(formData.entries());
  //   const response = await fetch(`/api/wp-json/wc/v3/products`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Basic ${authBase64}`,
  //     },
  //     body: JSON.stringify(payload),
  //   });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   await queryClient.invalidateQueries("products");
  //   return response.json();
  // };

  // const removeProduct = async (eventIds) => {
  //   for (const entryId of eventIds) {
  //     const response = await fetch(`/api/wp-json/wc/v3/products/${entryId}`, {
  //       method: "DELETE",
  //       headers: {
  //         Authorization: `Basic ${authBase64}`,
  //       },
  //     });
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     await queryClient.invalidateQueries("products");
  //   }
  // };

  const updateProduct = async (productId, key, value) => {
    const response = await fetch(`/api/wp-json/wc/v3/products/${productId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${authBase64}`,
      },
      body: JSON.stringify({ [key]: value }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    await queryClient.invalidateQueries("products");
    return response.json();
  };

  const updateProductAttributes = async (productId, updateData) => {
    const response = await fetch(`/api/wp-json/wc/v3/products/${productId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${authBase64}`,
      },
      body: JSON.stringify(updateData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    await queryClient.invalidateQueries("products");
    return response.json();
  };

  const updateProductVariations = async (productId, data) => {
    console.log("data", data);
    const response = await fetch(
      `/api/wp-json/wc/v3/products/${productId}/variations/batch`,
      {
        method: "POST", // Use POST for batch update
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authBase64}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    return response.json();
  };

  const handleUpdateProductAcf = async (repeaterFieldsAcf) => {
    console.log("REPEATERACF", repeaterFieldsAcf);
    const response = await fetch(`/api/wp-json/wp/v2/acf-fields/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${wpAuthBase64}`,
      },
      body: JSON.stringify(repeaterFieldsAcf),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    setIsPending(false);
    return response.json();
  };

  // Update product image function
  const handleUpdateProductImage = async (productId, images) => {
    const response = await fetch(`/api/wp-json/wc/v3/products/${productId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${authBase64}`,
      },
      body: JSON.stringify({
        images: images.map((image) => ({
          id: image.id,
          src: image.src || image.source_url,
          name: image.name,
          alt: image.alt,
        })),
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    await queryClient.invalidateQueries("products");
    return response.json();
  };

  const handleUpdateProductGalleryImages = async (productId, galleryImages) => {
    // This function is no longer needed as we are handling the images update in handleUpdateProductImage
  };

  const addQuotation = async (e) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const payload = Object.fromEntries(formData.entries());
    console.log("payload", payload);
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/quotations`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
        body: JSON.stringify(payload),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    await queryClient.invalidateQueries("quotations");
    return response.json();
  };

  const removeQuotation = async (eventIds) => {
    for (const entryId of eventIds) {
      const response = await fetch(
        `${
          import.meta.env.VITE_STRAPI_URL
        }/woostoremanager/quotations/${entryId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      await queryClient.invalidateQueries("quotations");
    }
  };

  const fetchOrders = async () => {
    const response = await fetch(`/api/wp-json/wc/v3/orders`, {
      headers: {
        Authorization: `Basic ${authBase64}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const fetchCustomers = async () => {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/customers`,
      {
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const fetchCustomer = async (id) => {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/customers/${id}`,
      {
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  // const fetchCategories = async () => {
  //   const response = await fetch(`/api/wp-json/wc/v3/products/categories`, {
  //     headers: {
  //       Authorization: `Basic ${authBase64}`,
  //     },
  //   });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   return response.json();
  // };

  // const addCategory = async (name, description, slug) => {
  //   const response = await fetch(`/api/wp-json/wc/v3/products/categories`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Basic ${authBase64}`,
  //     },
  //     body: JSON.stringify({ name, description }),
  //   });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   await queryClient.invalidateQueries("categories");
  //   return response.json();
  // };

  const fetchTaxonomies = async () => {
    try {
      const response = await fetch(`/api/wp-json/wp/v2/taxonomies/`, {
        headers: {
          Authorization: `Basic ${Buffer.from(
            `${wpUsername}:${wpPassword}`
          ).toString("base64")}`,
        },
      });

      // Log the response status and headers for debugging
      // console.log("Response status:", response.status);
      // console.log("Response headers:", Array.from(response.headers.entries()));

      // Get the raw response text
      const responseText = await response.text();
      // console.log("Raw response:", responseText);

      if (!response.ok) {
        // Handle non-200 responses
        throw new Error(
          `Network response was not ok, status: ${response.status}`
        );
      }

      // Attempt to parse the response as JSON
      try {
        return JSON.parse(responseText);
      } catch (jsonParseError) {
        // Handle JSON parsing errors
        console.error("Failed to parse JSON:", jsonParseError);
        console.warn(
          "Returning an empty array to prevent the page from crashing."
        );
        return []; // Return a safe fallback (empty array or an object)
      }
    } catch (error) {
      // Catch network or unexpected errors
      console.error("Fetch taxonomies failed:", error);
      console.warn(
        "Returning an empty array to prevent the page from crashing."
      );
      return []; // Return a safe fallback (empty array or an object)
    }
  };

  // const fetchTags = async () => {
  //   const response = await fetch(`/api/wp-json/wc/v3/products/tags`, {
  //     headers: {
  //       Authorization: `Basic ${authBase64}`,
  //     },
  //   });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   return response.json();
  // };

  // const addTag = async (name, description) => {
  //   const response = await fetch(`/api/wp-json/wc/v3/products/tags`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Basic ${authBase64}`,
  //     },
  //     body: JSON.stringify({ name, description }),
  //   });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   await queryClient.invalidateQueries("tags");
  //   return response.json();
  // };

  const fetchAttributes = async () => {
    const response = await fetch(
      `/api/wp-json/wc/v3/products/attributes?context=edit`,
      {
        headers: {
          Authorization: `Basic ${authBase64}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  };

  // const addAttribute = async (name, description) => {
  //   const response = await fetch(`/api/wp-json/wc/v3/products/attributes`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Basic ${authBase64}`,
  //     },
  //     body: JSON.stringify({ name, description }),
  //   });
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   await queryClient.invalidateQueries("attributes");
  //   return response.json();
  // };

  // const removeAttribute = async (attributeId) => {
  //   try {
  //     const response = await fetch(
  //       `/api/wp-json/wc/v3/products/attributes/${attributeId}?force=true`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           Authorization: `Basic ${authBase64}`,
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       throw new Error(errorData.message || "Network response was not ok");
  //     }
  //     await queryClient.invalidateQueries("attributes");
  //     return response.json();
  //   } catch (error) {
  //     console.error("Error deleting attribute:", error);
  //     throw error;
  //   }
  // };

  const fetchTerms = async (id) => {
    const response = await fetch(
      `/api/wp-json/wc/v3/products/attributes/${id}/terms`,
      {
        headers: {
          Authorization: `Basic ${authBase64}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  // useEffect(() => {
  //   fetchTerms(id);
  // }, [id]);

  // const addTerm = async (name, description, id) => {
  //   const response = await fetch(
  //     `/api/wp-json/wc/v3/products/attributes/${id}/terms`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Basic ${authBase64}`,
  //       },
  //       body: JSON.stringify({ name, description }),
  //     }
  //   );
  //   if (!response.ok) {
  //     throw new Error("Network response was not ok");
  //   }
  //   await queryClient.invalidateQueries("terms");
  //   return response.json();
  // };

  // const removeTerm = async (termId) => {
  //   try {
  //     const response = await fetch(
  //       `/api/wp-json/wc/v3/products/attributes/${id}/terms/${termId}?force=true`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           Authorization: `Basic ${authBase64}`,
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       throw new Error(errorData.message || "Network response was not ok");
  //     }

  //     await queryClient.invalidateQueries("terms");
  //     return response.json();
  //   } catch (error) {
  //     console.error("Error deleting term:", error);
  //     throw error;
  //   }
  // };

  const fetchReports = async ({ queryKey }) => {
    const dateMin = dateRange.start || "2025-01-01";
    const dateMax = dateRange.end || "2025-01-01";
    const response = await fetch(
      `/api/wp-json/wc/v3/reports/sales?date_min=${dateMin}&date_max=${dateMax}`,
      {
        headers: {
          Authorization: `Basic ${authBase64}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  // Sync Fields with WooCommerce and Strapi
  const fetchCustomerFields = async () => {
    setCustomerFieldsPending(true);
    setCustomerFieldsError(false);
    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_STRAPI_URL
        }/woostoremanager/get-customer-fields`,
        {
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch customer fields");
      }
      const data = await response.json();
      setCustomerFields(true);
    } catch (error) {
      console.error("Error fetching customer fields:", error);
      setCustomerFields(false);
      setCustomerFieldsError(true);
    } finally {
      setCustomerFieldsPending(false);
    }
  };

  const fetchProductFields = async () => {
    setProductFieldsPending(true);
    setProductFieldsError(false);
    try {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/get-product-fields`,
        {
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch product fields");
      }
      const data = await response.json();
      setProductFields(true);
    } catch (error) {
      console.error("Error fetching product fields:", error);
      setProductFields(false);
      setProductFieldsError(true);
    } finally {
      setProductFieldsPending(false);
    }
  };

  const fetchOrderFields = async () => {
    setOrderFieldsPending(true);
    setOrderFieldsError(false);
    try {
      const response = await fetch(
        `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/get-order-fields`,
        {
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch order fields");
      }
      const data = await response.json();
      setOrderFields(true);
    } catch (error) {
      console.error("Error fetching order fields:", error);
      setOrderFields(false);
      setOrderFieldsError(true);
    } finally {
      setOrderFieldsPending(false);
    }
  };

  const fetchStrapiProducts = async () => {
    const response = await fetch(
      `${import.meta.env.VITE_STRAPI_URL}/woostoremanager/products`,
      {
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const {
    data: data,
    error: error,
    isLoading: isLoading,
  } = useQuery({
    queryKey: ["data", location.pathname],
    queryFn: () => fetchWoocommerceData(authBase64, location.pathname),
    enabled:
      // also run query when the pathname is includes orders
      (location.pathname.includes("products") &&
        !/^\/products\/\d+$/.test(location.pathname)) ||
      location.pathname.includes("orders"),
  });

  const {
    data: orders,
    error: ordersError,
    isLoading: ordersLoading,
  } = useQuery({
    queryKey: ["orders"],
    queryFn: fetchOrders,
  });

  const {
    data: products,
    error: productsError,
    isLoading: productsLoading,
  } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
  });

  const {
    data: customer,
    error: customerError,
    isLoading: customerLoading,
  } = useQuery({
    queryKey: ["customer", id],
    queryFn: () => fetchCustomer(id),
    enabled: location.pathname.includes("customers"),
  });

  const {
    //acf fields
    data: acfFields,
    error: acfFieldsError,
    isLoading: acfFieldsLoading,
  } = useQuery({
    queryKey: ["acfFields"],
    queryFn: () => productAcfFields(id),
    enabled:
      location.pathname.includes("products") &&
      /^\/products\/\d+$/.test(location.pathname),
  });

  const {
    data: strapiProducts,
    error: strapiProductsError,
    isLoading: strapiProductsLoading,
  } = useQuery({
    queryKey: ["strapiProducts"],
    queryFn: fetchStrapiProducts,
  });

  const {
    data: customers,
    error: customersError,
    isLoading: customersLoading,
  } = useQuery({
    queryKey: ["customers"],
    queryFn: fetchCustomers,
  });

  // const {
  //   data: categories,
  //   error: categoriesError,
  //   isLoading: categoriesLoading,
  // } = useQuery({
  //   queryKey: ["categories"],
  //   queryFn: fetchCategories,
  // });

  const {
    data: taxonomies,
    error: taxonomiesError,
    isLoading: taxonomiesLoading,
  } = useQuery({
    queryKey: ["taxonomies"],
    queryFn: fetchTaxonomies,
  });

  // const {
  //   data: tags,
  //   error: tagsError,
  //   isLoading: tagsLoading,
  // } = useQuery({
  //   queryKey: ["tags"],
  //   queryFn: fetchTags,
  // });

  const {
    data: attributes,
    error: attributesError,
    isLoading: attributesLoading,
  } = useQuery({
    queryKey: ["attributes"],
    queryFn: fetchAttributes,
  });

  useEffect(() => {
    if (attributes) {
      console.log("Fetched attributes:", attributes);
    }
  }, [attributes]);

  const {
    data: terms,
    error: termsError,
    isLoading: termsLoading,
  } = useQuery({
    queryKey: ["terms", id],
    queryFn: () => fetchTerms(id),
    enabled:
      location.pathname.includes("terms") &&
      /^\/products\/\d+\/terms$/.test(location.pathname),
  });

  const {
    data: reports,
    error: reportsError,
    isLoading: reportsLoading,
  } = useQuery({
    queryKey: ["reports", dateRange], // Pass date range to the query key
    queryFn: fetchReports,
  });

  const {
    data: woocommercefields,
    error: woocommercefieldsError,
    isLoading: woocommercefieldsLoading,
  } = useQuery({
    queryKey: ["woocommercefields"],
    queryFn: fetchWooCommerceFields,
  });

  if (
    productsLoading ||
    strapiProductsLoading ||
    woocommercefieldsLoading ||
    ordersLoading ||
    reportsLoading ||
    customersLoading
  ) {
    return <LoadingIndicator />;
  }

  if (
    error ||
    productsError ||
    acfFieldsError ||
    strapiProductsError ||
    woocommercefieldsError ||
    ordersError ||
    // categoriesError ||
    taxonomiesError ||
    // tagsError ||
    attributesError ||
    termsError ||
    reportsError ||
    customersError
  ) {
    return (
      <div>
        Error fetching data:{" "}
        {error?.message ||
          productsError?.message ||
          acfFieldsError?.message ||
          strapiProductsError?.message ||
          woocommercefieldsError?.message ||
          ordersError?.message ||
          // categoriesError?.message ||
          taxonomiesError?.message ||
          // tagsError?.message ||
          attributesError?.message ||
          termsError?.message ||
          reportsError?.message ||
          customersError?.message}
      </div>
    );
  }

  const handleEntryId = (id) => {
    setEntryIds((prevEntryIds) => {
      if (prevEntryIds.includes(id)) {
        return prevEntryIds.filter((entryId) => entryId !== id);
      } else {
        return [...prevEntryIds, id];
      }
    });
  };

  const handleSelectedField = async (field, isChecked) => {
    let newSelectedFields = [...selectedFields];
    if (isChecked) {
      if (!newSelectedFields.find((f) => f.id === field.id)) {
        newSelectedFields.push(field);
      }
    } else {
      newSelectedFields = newSelectedFields.filter((f) => f.id !== field.id);
    }
    setSelectedFields(newSelectedFields);
  };

  return (
    <StoreContext.Provider
      value={{
        isPending,
        value,
        setValue,
        addEntity,
        removeEntity,
        data,
        acfFields,
        handleAcfField,
        repeaterFieldsAcf,
        handleUpdateProductAcf,
        handleUpdateProductImage,
        // products,
        orders,
        customers,
        customer,
        // categories,
        taxonomies,
        // addCategory,
        // tags,
        // addTag,
        attributes,
        // addAttribute,
        // removeAttribute,
        terms,
        // addTerm,
        // removeTerm,
        fetchTerms,
        reports,
        woocommercefields,
        // addProduct,
        // removeProduct,
        setIsPending,
        updateProduct,
        updateProductAttributes,
        updateProductVariations,
        entryIds,
        handleEntryId,
        handleSelectedField,
        selectedFields,
        setDateRange,
        fetchProductFields,
        fetchCustomerFields,
        fetchOrderFields,
        productFieldsRes,
        customerFieldsRes,
        orderFieldsRes,
        productFieldsPending,
        customerFieldsPending,
        orderFieldsPending,
        productFieldsError,
        customerFieldsError,
        orderFieldsError,
        strapiProducts,
        // addQuestionnaire,
        // removeQuestionnaire,
        // addQuotation,
        // removeQuotation,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

const App = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <StoreProvider>{children}</StoreProvider>
  </QueryClientProvider>
);

export default App;
