import React, { useContext } from "react";
import Table from "../components/dashboard/utils/listview/tables/Table";
import Sidebar from "../components/dashboard/utils/listview/sidebar/AddEntity";

export default function Attributes() {
  return (
    <div>
      <h1>Attributes terms</h1>
      <div className="row">
        <div className="col-md-4">
          <Sidebar />
        </div>
        <div className="col-md-8">
          <Table />
        </div>
      </div>
    </div>
  );
}
