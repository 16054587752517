import React, { useContext } from "react";
import QuestionRepeater from "../components/dashboard/utils/singleview/questionnaires/QuestionRepeater";
import { QuotationContext } from "../context/QuotationContext";
import { UpdateQuestionnaireTitle } from "../helpers/Questionnaires";
import { useParams } from "react-router-dom";

export default function QuestionnaireSingle() {
  const { questionnaire } = useContext(QuotationContext);
  console.log("questionnaire", questionnaire);
  const { id } = useParams();

  const handleUpdateQuestionnaire = (e) => {
    e.preventDefault();
    const title = e.target[0].value;
    UpdateQuestionnaireTitle(id, title);
  };

  return (
    <div>
      <div className="mb-2">
        <form className=" d-flex gap-1" onSubmit={handleUpdateQuestionnaire}>
          <input
            type="text"
            placeholder="Change title"
            className="form-control"
            defaultValue={questionnaire?.title}
          />
          <button className="btn btn-primary" type="submit">
            Update
          </button>
        </form>
      </div>
      <QuestionRepeater />
    </div>
  );
}
