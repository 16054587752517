import React, { useEffect, useContext } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { QuotationContext } from "../../../../../../context/QuotationContext";

export default function Answers() {
  const { quotation } = useContext(QuotationContext);
  console.log("quotation", quotation);

  const fadeUpVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <div className="mb-4">
      {quotation?.answers?.length === 0 && <p>No answers found</p>}
      {quotation?.answers?.map((answer, index) => (
        <AnswerItem
          key={index}
          answer={answer}
          index={index}
          variants={fadeUpVariants}
        />
      ))}
    </div>
  );
}

const AnswerItem = ({ answer, index, variants }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const hasFile = Object.values(answer.variables).some(
    (variable) => variable.selected && variable.file
  );

  return (
    <motion.div
      ref={ref}
      className={`d-flex mt-1 flex-column ${
        index % 2 === 0 ? "align-items-start" : "align-items-end"
      }`}
      initial="hidden"
      animate={controls}
      variants={variants}
    >
      <div
        className="card"
        style={{
          maxWidth: "100%",
          width: "100%",
          borderRadius: 0,
          marginBottom: "5px",
        }}
      >
        <div className="card-body p-2">
          <div className="row align-items-center">
            {hasFile && (
              <div className="col-2">
                {Object.entries(answer.variables).map(
                  ([key, variable], variableIndex) => (
                    <React.Fragment key={variableIndex}>
                      {variable.selected && variable.file && (
                        <img
                          src={variable?.file?.url}
                          alt={variable.title}
                          className="bg-light p-1"
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </React.Fragment>
                  )
                )}
              </div>
            )}
            <div className={hasFile ? "col-10" : "col-12"}>
              <p
                className="card-title fs-5"
                style={{
                  borderBottom: "1px solid #e9ecef",
                }}
              >
                {answer.title}
              </p>
              <div className="row align-items-center">
                {answer?.answer && (
                  <p className="font-weight-bold m-0">{answer?.answer}</p>
                )}
                {Object.entries(answer.variables).map(
                  ([key, variable], variableIndex) => (
                    <React.Fragment key={variableIndex}>
                      {variable.selected && (
                        <div className="col-12">
                          <p className="mb-0 font-weight-medium">
                            {variable.title}
                          </p>
                        </div>
                      )}
                    </React.Fragment>
                  )
                )}
                <div className="col-12 mt-1 d-flex justify-content-end">
                  {answer.boolean !== null && answer.boolean !== undefined && (
                    <p className="font-weight-medium">
                      {answer.boolean ? "Yes" : "No"}
                    </p>
                  )}
                  <p className="font-weight-bold m-0">€ {answer?.price || 0}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
