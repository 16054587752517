import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "./components/layout/Layout.jsx";
import { QueryClient, QueryClientProvider } from "react-query";
import { StoreProvider } from "./context/StoreContext.jsx";
import { CrmProvider } from "./context/CrmContext.jsx";
import { QuotationProvider } from "./context/QuotationContext";
import { MediaProvider } from "./context/MediaContext";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <CrmProvider>
          <QuotationProvider>
            <MediaProvider>
              <Layout>
                <Outlet />
              </Layout>
            </MediaProvider>
          </QuotationProvider>
        </CrmProvider>
      </StoreProvider>
    </QueryClientProvider>
  );
}

export default App;
