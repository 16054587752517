import React from "react";
import BulkActions from "./BulkActions";
import Crud from "./Crud";
import Views from "./Views";

export default function Actions() {
  return (
    <div className="d-flex gap-2">
      <BulkActions />
      <Crud />
      <Views />
    </div>
  );
}
