import React from "react";
import "./sidebar.scss";
import Logo from "../../assets/logo/logostoremanagerwhite.png";
import Menu from "./Menu";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FiMinimize2 } from "react-icons/fi";
import MinimizedLogo from "../../assets/logo/minimized-logo.png";

export default function Sidebar({ handleMinimizeSidebar }) {
  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div className="col-md-12 col-xl-12 pe-0 bg-primary" style={{}}>
          <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
            <Link
              to="/"
              className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
            >
              <div className="px-2">
                <span className="fs-5 d-none d-sm-inline">
                  <img src={Logo} alt="Floowd" style={{ width: "175px" }} />
                </span>
              </div>
            </Link>
            <div className=" mobile-logo">
              <Link
                to="/"
                className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
              >
                <div className="px-2">
                  <img
                    src={MinimizedLogo}
                    alt="Floowd"
                    style={{ width: "25px" }}
                  />
                </div>
              </Link>
            </div>
            <Menu />
            <hr />

            <div className=" pb-4">
              <ul className="nav flex-column">
                <li
                  onClick={handleMinimizeSidebar}
                  className="nav-link px-0 d-flex gap-1 align-items-center ps-2"
                  style={{ cursor: "pointer" }}
                >
                  <FiMinimize2 size={20} className="text-white" />
                  <span className="text-white"> Minimize sidebar </span>
                </li>
                <li>
                  <a
                    href="#"
                    className="nav-link px-0 d-flex gap-1 align-items-center ps-2"
                  >
                    {" "}
                    <IoDocumentTextOutline size={20} className="text-white" />
                    <span className="d-none d-sm-inline">Documentation</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
