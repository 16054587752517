import React, { useState, useContext } from "react";
import ConfirmationForm from "./Confirmation";
import { QuotationContext } from "../../../../../../context/QuotationContext.jsx";

export default function Confirmation({ onConfirm }) {
  const [agree, setAgree] = useState(false);
  const { quotation } = useContext(QuotationContext);

  const data = quotation.answers.map((answer) => {
    return {
      id: answer.id,
      name: answer.title,
      cost: answer.price,
    };
  });

  const shippingCost = 20;
  const taxRate = 0.21; // 21% tax
  const subtotal = data.reduce((sum, item) => sum + item.cost, 0);
  const tax = subtotal * taxRate;
  const total = subtotal + tax + shippingCost;

  const handleConfirm = () => {
    if (agree) {
      onConfirm();
    } else {
      alert("You must agree to the terms and conditions to confirm.");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="bg-white p-2 max-w-2xl w-100">
        <h2 className="mb-2 fs-4">Overzicht</h2>
        <table className="table">
          <tbody>
            <tr>
              <td className="text-start fw-semibold">Subtotaal</td>
              <td className="text-end">€{subtotal.toFixed(2)}</td>
            </tr>
            <tr>
              <td className="text-start fw-semibold">BTW (21%)</td>
              <td className="text-end">€{tax.toFixed(2)}</td>
            </tr>
            <tr>
              <td className="text-start fw-semibold">Verzendkosten</td>
              <td className="text-end">€{shippingCost.toFixed(2)}</td>
            </tr>
            <tr>
              <td className="text-start fw-semibold">Totaal</td>
              <td className="text-end">€{total.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <ConfirmationForm
          agree={agree}
          setAgree={setAgree}
          handleConfirm={handleConfirm}
        />
      </div>
    </div>
  );
}
