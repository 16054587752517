import React from "react";
import Form from "../components/authentication/login/Form";
import Logo from "../assets/logo/logostoremanagerblue.png";

export default function Login() {
  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{
        height: "100vh",
      }}
    >
      <div className="row">
        <div className="col-12 justify-content-center d-flex flex-column align-items-center">
          <img src={Logo} alt="Floowd" style={{ width: "175px" }} />
          <div className="bg-white p-3 rounded mt-2">
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
}
