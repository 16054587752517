import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { Buffer } from "buffer";

import Details from "../components/dashboard/utils/singleview/orders/Details";
import Sidebar from "../components/dashboard/utils/singleview/orders/Sidebar";
import LineItems from "../components/dashboard/utils/singleview/orders/LineItems";
import Summary from "../components/dashboard/utils/singleview/orders/Summary";

export default function OrderSingle() {
  const params = useParams();

  // WooCommerce API credentials
  const username = import.meta.env.VITE_WOOCOMMERCE_USERNAME;
  const password = import.meta.env.VITE_WOOCOMMERCE_SECRET;
  const authBase64 = Buffer.from(`${username}:${password}`).toString("base64");

  // Strapi API credentials
  const authBase64WP = Buffer.from(`${username}:${password}`).toString(
    "base64"
  );

  const queryClient = useQueryClient();

  const orderQuery = useQuery(["orders", params.id], () =>
    fetch(`/api/orders/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Basic ${authBase64}`,
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      return res.json();
    })
  );

  if (orderQuery.isLoading) return <div>Loading...</div>;
  if (orderQuery.isError) return <div>Error: {orderQuery.error.message}</div>;

  console.log("order", orderQuery.data);

  return (
    <div className="row">
      <div className="col-8">
        <Details order={orderQuery.data} />
        <div className="mt-3">
          <LineItems order={orderQuery.data} />
        </div>
        <div className="mt-3">
          <Summary order={orderQuery.data} />
        </div>
      </div>
      <div className="col-4">
        <Sidebar order={orderQuery.data} />
      </div>
    </div>
  );
}
