import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { handleChangeAndUpdateAnswer } from "../../../../../../helpers/Questionnaires.jsx";

export default function Radio({ index, item }) {
  const { register, setValue } = useFormContext();
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedTitle, setSelectedTitle] = useState("");

  useEffect(() => {
    if (item.variables) {
      const initialOption =
        Object.values(item.variables).find((o) => o.selected) || {};
      setSelectedPrice(initialOption.price || 0);
      setSelectedTitle(initialOption.title || "");
    }
  }, [item]);

  const handleRadioChange = (selectedTitle, optionIndex) => {
    const selectedOption = Object.values(item.variables).find(
      (option) => option.title === selectedTitle
    );

    if (selectedOption) {
      setSelectedPrice(selectedOption.price);
      setSelectedTitle(selectedOption.title);
      setValue(`answers.${index}.price`, selectedOption.price);

      handleChangeAndUpdateAnswer(
        {
          title: selectedOption.title,
          selected: true,
          price: selectedOption.price,
        },
        item.originalId,
        index,
        `${optionIndex}`,
        "radio"
      );
    }
  };

  return (
    <div className="mt-4 mb-4">
      <Form.Group>
        <Row>
          {Object.entries(item.variables).map(([key, option], optionIndex) => (
            <Col key={key} xs={12} className="mb-2">
              <Form.Check
                {...register(`answers.${index}.variables.${optionIndex}`)}
                type="radio"
                id={key}
                label={option.product?.name || option.title}
                value={option.title}
                defaultChecked={option.selected}
                onChange={() => handleRadioChange(option.title, optionIndex)}
              />
            </Col>
          ))}
        </Row>
      </Form.Group>
      <Form.Control
        {...register(`answers.${index}.price`)}
        placeholder="Price"
        name="price"
        type="number"
        onChange={(e) => {
          const newPrice = parseInt(e.target.value, 10);
          handleChangeAndUpdateAnswer(
            {
              title: selectedTitle,
              selected: true,
              price: newPrice,
            },
            item.originalId,
            item,
            index,
            item.field_type,
            "price"
          );
        }}
        defaultValue={selectedPrice || ""} // Provide an empty string as a fallback
        className="mt-2"
      />
    </div>
  );
}
