import React, { useContext } from "react";
import { CrmContext } from "../../../../../../context/CrmContext";
import { Link } from "react-router-dom";

export default function CompanyDetails() {
  const { contact } = useContext(CrmContext);

  return (
    <div>
      <h3 className="fs-4">Company Details</h3>
      <div className="mt-1">
        <p className="fs-6">Company: {contact?.company?.name}</p>
        <p className="fs-6">Address: {contact?.company?.address}</p>
        <p className="fs-6">Industry: {contact?.company?.industry}</p>
        <p className="fs-6">Website: {contact?.company?.website}</p>
        <Link
          to={`/contacts/submenu/companies/${contact?.company?.id}`}
          className="btn btn-outline-info mt-1"
        >
          View
        </Link>
      </div>
    </div>
  );
}
