import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "./menu.scss";

export default function Menu() {
  const location = useLocation();
  const [expandedItems, setExpandedItems] = useState([]);

  useEffect(() => {
    // Function to expand items based on the current active route
    const expandItemsForActiveRoute = (items) => {
      let expanded = [];
      items.forEach((item) => {
        console.log(`Checking item: ${item.title}, Path: ${item.path}`);
        if (
          location.pathname === item.path || // exact match
          location.pathname.startsWith(`${item.path}/`) || // matches a deeper path (for children)
          (item.children && expandItemsForActiveRoute(item.children).length > 0) // if any child is active
        ) {
          console.log(`Expanding item: ${item.title}, Path: ${item.path}`);
          expanded.push(item.id);
        }
      });
      return expanded;
    };

    const activeItems = expandItemsForActiveRoute(menuItems);
    console.log("Active items to expand:", activeItems);
    setExpandedItems((prevExpandedItems) => [
      ...new Set([...prevExpandedItems, ...activeItems]),
    ]);
  }, [location.pathname]);

  const toggleExpand = (itemId) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(itemId)
        ? prevExpandedItems.filter((id) => id !== itemId)
        : [...prevExpandedItems, itemId]
    );
  };

  const menuItems = [
    {
      id: 1,
      title: "Products",
      path: "/products",
      icon: "https://img.icons8.com/?size=100&id=100547&format=png&color=000000",
      children: [
        {
          id: 2,
          title: "Categories",
          path: "/products/floowd/categories",
          icon: "https://img.icons8.com/?size=100&id=vxOr7mumH0q9&format=png&color=000000",
        },
        {
          id: 3,
          title: "Tags",
          path: "/products/floowd/tags",
          icon: "https://img.icons8.com/?size=100&id=85079&format=png&color=000000",
        },
        {
          id: 4,
          title: "Attributes",
          path: "/products/floowd/attributes",
          icon: "https://img.icons8.com/?size=100&id=Xg3Q3ygB6CqG&format=png&color=000000",
        },
      ],
    },
    {
      id: 5,
      title: "Orders",
      path: "/orders",
      icon: "https://img.icons8.com/?size=100&id=82758&format=png&color=000000",
    },
    {
      id: 6,
      title: "CRM",
      path: "/crm/floowd/contacts",
      icon: "https://img.icons8.com/?size=100&id=12438&format=png&color=000000",
      children: [
        {
          id: 7,
          title: "Contacts",
          path: "/crm/floowd/contacts",
          icon: "https://img.icons8.com/?size=100&id=84020&format=png&color=000000",
        },
        {
          id: 8,
          title: "Companies",
          path: "/crm/floowd/companies",
          icon: "https://img.icons8.com/?size=100&id=1BmXkGOOwJdB&format=png&color=000000",
        },
        {
          id: 9,
          title: "Deals",
          path: "/crm/floowd/deals",
          icon: "https://img.icons8.com/?size=100&id=oEFC8Dw5xx9o&format=png&color=000000",
        },
        {
          id: 10,
          title: "Service",
          path: "/crm/floowd/service",
          icon: "https://img.icons8.com/?size=100&id=59851&format=png&color=000000",
        },
      ],
    },
    {
      id: 13,
      title: "Quotation Manager",
      path: "/quotation-manager",
      icon: "https://img.icons8.com/?size=100&id=14515&format=png&color=000000",
      children: [
        {
          id: 11,
          title: "Quotations",
          path: "/quotations",
          icon: "https://img.icons8.com/?size=100&id=14515&format=png&color=000000",
        },
        {
          id: 12,
          title: "Questionnaires",
          path: "/questionnaires",
          icon: "https://img.icons8.com/?size=100&id=83283&format=png&color=000000",
        },
      ],
    },
  ];

  const renderMenuItems = (items, parentId = null) => {
    return items.map((item) => {
      const hasChildren = item.children && item.children.length > 0;
      const isActive = location.pathname === item.path;
      const isActiveChild =
        !isActive &&
        location.pathname.startsWith(item.path) &&
        parentId !== null;

      const isExpanded = expandedItems.includes(item.id);

      return (
        <li key={item.id} className="nav-item mb-1 w-100">
          <Link
            to={item.path}
            className={`nav-link align-middle px-2 d-flex align-items-center ${
              isActive ? "active" : isActiveChild ? "active-child" : ""
            }`}
            onClick={(e) => {
              if (hasChildren) {
                e.preventDefault();
                toggleExpand(item.id);
              }
            }}
          >
            <img
              className="me-2"
              style={{ filter: "invert(1)" }}
              src={item.icon}
              alt={item.title}
              width="20"
              height="20"
            />
            <span className="d-none d-sm-inline">{item.title}</span>
            {hasChildren && (
              <span className="ms-auto pe-2" style={{ cursor: "pointer" }}>
                {isExpanded ? <FaAngleUp /> : <FaAngleDown />}
              </span>
            )}
          </Link>
          {hasChildren && (
            <ul
              className={`collapse ${isExpanded ? "show" : ""}`}
              id={`collapse-${item.id}`}
            >
              {renderMenuItems(item.children, item.id)}
            </ul>
          )}
        </li>
      );
    });
  };

  return (
    <ul
      className="nav w-100 nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start menu"
      id="menu"
    >
      {renderMenuItems(menuItems)}
    </ul>
  );
}
