import React from "react";

export default function NotificationPane() {
  const notifications = [
    {
      id: 1,
      title: "New Order",
      message: "You have a new order from John Doe",
      date: "2021-09-01",
    },
    {
      id: 2,
      title: "New Order",
      message: "You have a new order from John Doe",
      date: "2021-09-01",
    },
    {
      id: 3,
      title: "New Order",
      message: "You have a new order from John Doe",
      date: "2021-09-01",
    },
    {
      id: 4,
      title: "New Order",
      message: "You have a new order from John Doe",
      date: "2021-09-01",
    },
    {
      id: 5,
      title: "New Order",
      message: "You have a new order from John Doe",
      date: "2021-09-01",
    },
  ];

  return (
    <div>
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className="d-flex align-items-center gap-2 p-2 border-bottom"
        >
          <div
            className="bg-light p-2 rounded-circle d-flex align-items-center justify-content-center"
            style={{ width: "50px", height: "50px" }}
          >
            <span>{notification.title.charAt(0)}</span>
          </div>
          <div>
            <h6 className="fs-6 m-0">{notification.title}</h6>
            <p className="m-0">{notification.message}</p>
            <small>{notification.date}</small>
          </div>
        </div>
      ))}
    </div>
  );
}
