import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";

export default function OrderActions() {
  const { id } = useParams();

  return (
    <div className="container rounded">
      <select
        className="form-control"
        value={""}
        onChange={(e) => console.log(e.target.value)}
      >
        <option value="">Choose an action...</option>
        <option value="send-order-details">
          Send order details to customer
        </option>
      </select>
    </div>
  );
}
