"use client";
import React, { useState, useEffect, useContext } from "react";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { QueryClient, useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  PointerSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import {
  AddAndBindAnswers,
  removeAnswer,
} from "../../../../../../helpers/Questionnaires";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { Droppable } from "../../../../utils/singleview/dndkit/Droppable.jsx";
import Sortable from "../../../../utils/singleview/questionnaires/dndkit/Sortable.jsx";
import { QuotationContext } from "../../../../../../context/QuotationContext.jsx";
import Text from "../../../../utils/singleview/quotations/fields/Text.jsx";
import Textarea from "../../../../utils/singleview/quotations/fields/Textarea";
import Number from "../../../../utils/singleview/quotations/fields/Number";
import Select from "../../../../utils/singleview/quotations/fields/Select";
import CheckboxField from "../../../../utils/singleview/quotations/fields/Checkbox";
import Radio from "../../../../utils/singleview/quotations/fields/Radio";
import Boolean from "../../../../utils/singleview/quotations/fields/Boolean";
import Datetime from "../../../../utils/singleview/quotations/fields/Datetime";
import { Modal, Button, Form, ListGroup, InputGroup } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const questionnaireSelectionSchema = z.object({
  selectedQuestionnaire: z.string(),
});

export default function QuestionnaireSelection() {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [overId, setOverId] = useState(null);
  const params = useParams();

  const {
    quotation,
    answers,
    updateAnswerAtIndex,
    handleDefaultAnswers,
    setAnswers,
    updateAnswerOrderChange,
  } = useContext(QuotationContext);

  const questionnaires = quotation?.woostoremanager_products[0]?.questionnaires;
  const [filteredQuestionnaires, setFilteredQuestionnaires] = useState(
    questionnaires || []
  );

  const [defaultAnswers, setDefaultAnswers] = useState(
    quotation.answers.map((answer) => ({
      originalId: parseInt(answer.id),
      title: answer.question,
      answer: answer.answer || "",
      field_type: answer?.field_type,
      variables: answer?.variables,
      boolean: answer.boolean,
      date: answer.date_time ? new Date(answer.date_time) : null,
      price: answer.price,
      order: answer.order,
    }))
  );

  useEffect(() => {
    if (!search) {
      setFilteredQuestionnaires(questionnaires);
      return;
    }
    const lowercasedSearch = search.toLowerCase();
    const result = questionnaires.filter((questionnaire) =>
      questionnaire?.title?.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredQuestionnaires(result);
  }, [search, questionnaires]);

  useEffect(() => {
    handleDefaultAnswers(defaultAnswers);
  }, [defaultAnswers, handleDefaultAnswers]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(questionnaireSelectionSchema),
    defaultValues: {
      answers: defaultAnswers,
    },
  });

  const methods = useForm({
    resolver: zodResolver(questionnaireSelectionSchema),
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "answers",
  });

  const onSubmit = async (data) => {
    console.log("Form submitted:", data); // Debugging log
    const questionnaires = {
      selectedQuestionnaire: `${selectedQuestionnaire}`,
    };
    try {
      const newAnswers = await AddAndBindAnswers(
        selectedQuestionnaire,
        params.id
      );

      // location.reload(true);
    } catch (error) {
      console.error("Failed to add/bind answers:", error);
    }
    setOpen(false); // Close the dialog or modal after operation
    setSelectedQuestionnaire([]);
  };

  const handleCheckboxChange = (questionnaireId) => {
    setSelectedQuestionnaire((prevSelected) => {
      let newSelected;
      if (prevSelected.includes(questionnaireId)) {
        newSelected = prevSelected.filter((id) => id !== questionnaireId);
      } else {
        newSelected = [...prevSelected, questionnaireId];
      }
      return newSelected;
    });
  };

  const mouseSensorOptions = {
    activationConstraint: {
      delay: 250, // Delay before initiating the drag, allows for quicker clicks to be registered as non-drag actions
      tolerance: 15, // Increase tolerance to require more deliberate drag motion
    },
  };

  const touchSensorOptions = {
    activationConstraint: {
      delay: 300,
      tolerance: 15,
    },
  };

  const sensors = useSensors(
    useSensor(MouseSensor, mouseSensorOptions),
    useSensor(TouchSensor, touchSensorOptions),
    useSensor(PointerSensor, {
      activationConstraint: { delay: 250, tolerance: 15 },
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over && active && active.id !== over.id) {
      const oldIndex = answers.findIndex((item) => item.id === active.id);
      const newIndex = answers.findIndex((item) => item.id === over.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        const newAnswers = arrayMove(answers, oldIndex, newIndex).map(
          (item, index) => ({
            ...item,
            order: index,
          })
        );
        updateAnswerOrderChange(newAnswers);
      }
    }
  };

  // useEffect(() => {
  //   const updatedOrder = fields.map((field, index) => ({
  //     id: field.originalId,
  //     order: index,
  //   }));

  //   updateAnswerOrder(updatedOrder);
  // }, [fields]);

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  function FieldRenderer({ item }) {
    switch (item.field_type) {
      case "text":
        return <Text item={item} />;
      case "textarea":
        return <Textarea item={item} />;
      case "number":
        return <Number item={item} />;
      case "select":
        return <Select item={item} />;
      case "checkbox":
        return <CheckboxField item={item} />;
      case "radio":
        return <Radio item={item} />;
      case "boolean":
        return <Boolean item={item} />;
      case "datetime":
        return <Datetime item={item} />;
      default:
        return <div>Unsupported field type</div>;
    }
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      sensors={sensors}
      onDragEnd={handleDragEnd}
    >
      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="text-lg">Select a questionnaire</h3>
          <Button variant="outline-primary" onClick={() => setOpen(true)}>
            Select a questionnaire
          </Button>
        </div>
        <Modal show={open} onHide={() => setOpen(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Select a questionnaire</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <Form.Label>Search for a questionnaire</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Search for a questionnaire"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
                {filteredQuestionnaires?.map((questionnaire, index) => (
                  <Form.Group key={index} className="mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register("selectedQuestionnaire")}
                      value={questionnaire?.id}
                      id={questionnaire?.title}
                      onChange={() => handleCheckboxChange(questionnaire.id)}
                    />
                    <label htmlFor={questionnaire?.title} className="ps-2">
                      {questionnaire?.title}
                    </label>
                  </Form.Group>
                ))}
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </FormProvider>
          </Modal.Body>
        </Modal>
        <div className="mt-2">
          {quotation.answers.length > 0 ? (
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <SortableContext
                  items={fields.map((field) => field.originalId)}
                  strategy={verticalListSortingStrategy}
                >
                  <ListGroup>
                    {fields.map((item, index) => (
                      <Sortable key={item.originalId} id={item.originalId}>
                        <ListGroup.Item className="d-flex align-items-end gap-2">
                          <div className="answers w-100">
                            <Form.Group>
                              <Form.Label>{item.title}</Form.Label>
                              {item.field_type === "text" && (
                                <Text index={index} item={item} />
                              )}
                              {item.field_type === "textarea" && (
                                <Textarea index={index} item={item} />
                              )}
                              {item.field_type === "number" && (
                                <Number index={index} item={item} />
                              )}
                              {item?.field_type === "select" && (
                                <Select index={index} item={item} />
                              )}
                              {item?.field_type === "checkbox" && (
                                <CheckboxField index={index} item={item} />
                              )}
                              {item?.field_type === "radio" && (
                                <Radio index={index} item={item} />
                              )}
                              {item.field_type === "boolean" && (
                                <Boolean index={index} item={item} />
                              )}
                              {item.field_type === "datetime" && (
                                <Datetime index={index} item={item} />
                              )}
                            </Form.Group>
                          </div>
                          <div className="remove">
                            <Button
                              variant="outline-danger"
                              onClick={async () => {
                                const answer = fields[index];
                                await removeAnswer(answer.originalId);
                                remove(index);
                              }}
                            >
                              -
                            </Button>
                          </div>
                        </ListGroup.Item>
                      </Sortable>
                    ))}
                  </ListGroup>
                </SortableContext>
                <Button
                  variant="outline-primary"
                  onClick={() => append({ title: "", description: "" })}
                >
                  + Add Answer
                </Button>
              </Form>
            </FormProvider>
          ) : (
            <p className="mt-2">No questions available</p>
          )}
        </div>
      </div>
    </DndContext>
  );
}
